.tag-bar {
    display: flex;
    justify-content: space-between;
    gap: 25px;

    @media (min-width: 576px) {
        gap: 15px;
    }

    .tags-container {
        position: relative;
        min-width: 0;

        &::before, &::after {
            position: absolute;
            content: "";
            top: 0;
            bottom: 0;
            z-index: 2;
            width: 10px;
            pointer-events: none;
        }

        &::before {
            left: -11px;
        }

        &::after {
            right: -10px;
        }

        .tags {
            display: flex;
            gap: 5px;
            flex-wrap: nowrap;
            margin: 0 -10px;
            padding: 2px 10px;
            overflow-x: auto;

            @media (min-width: 576px) {
                flex-wrap: wrap;
            }

            &::-webkit-scrollbar {
                height: 4px;
            }

            &::-webkit-scrollbar-track {
                background: transparent;
            }

            &::-webkit-scrollbar-thumb {
                background: $deep-blue-20;
            }

            .tag {
                max-width: 140px;
            }
        }
    }

    &.deep-blue .tags-container {
        &::before {
            background: linear-gradient(to right, $deep-blue, rgba($deep-blue, 0));
        }

        &::after {
            background: linear-gradient(to left, $deep-blue, rgba($deep-blue, 0));
        }

        .tags {
            &::-webkit-scrollbar-thumb {
                background: $deep-blue-70;
            }
        }
    }

    &.light-sand .tags-container {
        &::before {
            background: linear-gradient(to right, $light-sand, rgba($light-sand, 0));
        }

        &::after {
            background: linear-gradient(to left, $light-sand, rgba($light-sand, 0));
        }
    }

    &.white .tags-container {
        &::before {
            background: linear-gradient(to right, white, rgba(white, 0));
        }

        &::after {
            background: linear-gradient(to left, white, rgba(white, 0));
        }
    }
}
