﻿.text-area {
    .text-area-field {
        position: relative;
        overflow: hidden;

        textarea {
            width: calc(100% - 28px);
            margin: 0;
            padding: 16px 14px 6px;
            scroll-padding-top: 16px;
            font: inherit;
            color: $deep-blue-70;
            background-color: $interactive-cyan-8;
            border: none;
            border-bottom: 1px solid $deep-blue-70;
            border-radius: 0;
            outline: none;
            resize: none;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            transition: color 0.3s ease-in-out, background 0.3s ease-in-out, border 0.3s ease-in-out;

            &::placeholder {
                color: $deep-blue-70;
                user-select: none;
                transition: color 0.3s ease-in-out;
            }

            &.error:not(:disabled):not(:focus) {
                color: $deep-blue;
                background-color: $light-red;
                border-color: $feedback-red-dark;
            }

            &:focus, &:hover {
                color: $deep-blue;
                background-color: $bold-green-18;
                border-color: $green;

                &::placeholder {
                    color: $deep-blue;
                }
            }

            &:disabled {
                color: $deep-blue-50 !important;
                -webkit-text-fill-color: $deep-blue-50;
                background-color: $interactive-cyan-8 !important;
                border-color: transparent;
                cursor: not-allowed;
            }

            &:read-only {
                color: $deep-blue-70;
                background-color: transparent;
                border-color: transparent;
                cursor: not-allowed;
            }

            &.error:not(:disabled):not(:focus) + .text-area-label {
                color: $feedback-red-dark !important;
                background-color: $light-red;
                box-shadow: -2px -4px 3px 6px $light-red;
            }

            &:focus:not(:read-only) + .text-area-label {
                top: 2px;
                font-size: 10px;
                color: $deep-blue;
                background-color: $bold-green-18;
                box-shadow: -2px -4px 3px 6px $bold-green-18;
            }

            &:hover + .text-area-label {
                color: $deep-blue;
                background-color: $bold-green-18;
                box-shadow: -2px -4px 3px 6px $bold-green-18;
            }

            &:disabled + .text-area-label {
                color: $deep-blue-50 !important;
                background-color: $interactive-cyan-8 !important;
                box-shadow: -2px -4px 3px 6px $interactive-cyan-8 !important;
            }

            &:read-only + .text-area-label {
                color: $deep-blue-70;
                background-color: white;
                box-shadow: -2px -4px 3px 6px white;
            }

            &:required + .text-area-label::before {
                position: absolute;
                content: "*";
                right: calc(100% + 2px);
            }
        }

        .text-area-label {
            position: absolute;
            top: 16px;
            display: flex;
            align-items: center;
            margin-left: 14px;
            padding-right: 2px;
            font-size: 16px;
            color: $deep-blue-70;
            background-color: $interactive-cyan-8;
            box-shadow: -2px -4px 3px 6px $interactive-cyan-8;
            user-select: none;
            pointer-events: none;
            transition: color 0.3s ease-in-out, font-size 0.15s ease-in-out, top 0.15s ease-in-out, background 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

            &.filled {
                top: 2px;
                font-size: 10px;
            }
        }
    }

    .text-area-message {
        margin: 3px 14px 0;
        font-size: 10px;
        color: $deep-blue;
        user-select: none;

        &.error {
            color: $feedback-red-dark;
        }
    }

    &.dark {
        .text-area-field {
            textarea {
                color: $deep-blue-8;
                background-color: $interactive-coral-12-db;
                border-color: $deep-blue-50;

                &::placeholder {
                    color: $deep-blue-8;
                }

                &.error:not(:disabled):not(:focus) {
                    color: $deep-blue-8;
                    background-color: $dark-red;
                    border-color: $medium-red;
                }

                &:focus, &:hover {
                    color: $deep-blue-8;
                    background-color: $bold-green-12-db;
                    border-color: $bold-green;
                }

                &:disabled {
                    color: $deep-blue-60 !important;
                    -webkit-text-fill-color: $deep-blue-60;
                    background-color: $interactive-coral-12-db !important;
                    border-color: transparent;
                }

                &:read-only {
                    color: $deep-blue-50;
                    background-color: transparent;
                    border-color: transparent;
                }

                &.error:not(:disabled):not(:focus) + .text-area-label {
                    color: $medium-red !important;
                    background-color: $dark-red !important;
                    box-shadow: -2px -4px 3px 6px $dark-red !important;
                }

                &:hover + .text-area-label {
                    color: $deep-blue-8;
                    background-color: $bold-green-12-db;
                    box-shadow: -2px -4px 3px 6px $bold-green-12-db;
                }

                &:hover + .text-area-label.filled, &:focus + .text-area-label, &:focus:hover + .text-area-label {
                    color: $bold-green;
                    background-color: $bold-green-12-db;
                    box-shadow: -2px -4px 3px 6px $bold-green-12-db;
                }

                &:disabled + .text-area-label, &:disabled:hover + .text-area-label {
                    color: $deep-blue-60 !important;
                    background-color: $interactive-coral-12-db !important;
                    box-shadow: -2px -4px 3px 6px $interactive-coral-12-db !important;
                }

                &:read-only + .text-area-label, &:read-only:hover + .text-area-label, &:read-only:focus + .text-area-label {
                    color: $deep-blue-50;
                    background-color: $deep-blue;
                    box-shadow: -2px -4px 3px 6px $deep-blue;
                }
            }

            .text-area-label {
                color: $deep-blue-50;
                background-color: $interactive-coral-12-db;
                box-shadow: -2px -4px 3px 6px $interactive-coral-12-db;
            }
        }

        .text-area-message {
            color: $deep-blue-8;

            &.error {
                color: $deep-blue-50;
            }
        }
    }
}
