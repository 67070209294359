$bold-blue: #00E6DC;
$bold-green: #00FFB9;
$bold-green-12-db: #001F39;
$bold-green-18: #D1FFF2;
$bold-green-20: #C2FFEE;
$bright-sand: #DFDFD9;
$dark-red: #331131;
$dark-red-92: #4D1A4A;
$deep-blue: #000028;
$deep-blue-8: #EBEBEE;
$deep-blue-20: #CCCCD4;
$deep-blue-30: #B3B3BE;
$deep-blue-50: #7D8099;
$deep-blue-55: #737389;
$deep-blue-60: #66667E;
$deep-blue-70: #4C4C68;
$deep-blue-80: #333353;
$deep-blue-85: #262648;
$deep-blue-90: #23233C;
$feedback-green-dark: #01D65A;
$feedback-green-light: #01893A;
$feedback-red-dark: #FF2640;
$feedback-red-dark-40: #FFA8B3;
$feedback-red-light: #D72339;
$feedback-yellow-dark: #FFD732;
$feedback-yellow-light: #E9C32A;
$focus-blue: #36ABFF;
$green: #00D7A0;
$interactive-blue: #007993;
$interactive-blue-12: #E0F1F4;
$interactive-coral: #00CCCC;
$interactive-coral-12-db: #00183B;
$interactive-cyan: #0098A6;
$interactive-cyan-2: #FAFDFD;
$interactive-cyan-8: #EBF7F8;
$interactive-cyan-10: #D9EFF1;
$interactive-cyan-20: #C8E7EA;
$input-yellow-light: #FFF0B3;
$input-yellow-medium: #FAE79A;
$input-yellow-dark: #4b4015;
$light-green: #62EEC7;
$light-green-40: #C5FFEF;
$light-green-50: #B0F6E3;
$light-green-70: #91F3D7;
$light-grey: #D9D9D6;
$light-red: #FCCCD7;
$light-sand: #F3F3F0;
$medium-red: #FF5454;
$siemens-petrol: #009999;
$soft-sand: #C5C5B8;
$teal: #005159;
$teal-90: #00646E;
$teal-95: #006662;
