.section {
    padding: 30px 0;

    &.deep-blue {
        color: $deep-blue-8;
        background-color: $deep-blue;
    }

    &.light-sand {
        background-color: $light-sand;
    }

    &.white {
        background-color: white;
    }
}
