.tabs {
    .tab-switcher-container {
        position: relative;
        margin-bottom: 10px;
        outline: none;

        &::before, &::after {
            position: absolute;
            content: "";
            top: 0;
            bottom: 0;
            z-index: 1;
            width: 10px;
            pointer-events: none;
        }

        &::before {
            left: -11px;
            background: linear-gradient(to right, white, #FFFFFF00);
        }

        &::after {
            right: -10px;
            background: linear-gradient(to left, white, #FFFFFF00);
        }

        &:focus-visible .tab-switcher .tab.active {
            background-color: $light-green-40;
        }

        .tab-switcher {
            position: relative;
            display: flex;
            margin: 0 -10px;
            padding: 0 10px;
            overflow-x: auto;

            &::-webkit-scrollbar {
                height: 4px;
            }

            &::-webkit-scrollbar-track {
                background: transparent;
            }

            &::-webkit-scrollbar-thumb {
                background: $deep-blue-30;
            }

            .tab {
                display: flex;
                flex-grow: 1;
                justify-content: center;
                align-items: center;
                padding: 25px;
                font-size: 18px;
                font-weight: 900;
                color: $deep-blue;
                border-bottom: 2px solid $deep-blue-20;
                white-space: nowrap;
                cursor: pointer;
                user-select: none;
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

                &:hover, &:active {
                    background-color: $light-green-40;
                }

                &.required::before {
                    content: "*";
                    margin-right: 2px;
                }
            }

            .tab-switcher-underline {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 0;
                border-bottom: 2px solid $siemens-petrol;
                transition: left 0.5s ease-in-out, width 0.5s ease-in-out;
            }
        }
    }

    .tab-content-container {
        display: grid;
        margin: 0 -20px;
        padding: 0 20px;
        overflow: hidden;

        .tab-content {
            grid-column: 1 / span 1;
            grid-row: 1 / span 1;

            &.open-from-left {
                animation: open-from-left 0.5s ease-in-out forwards;

                @keyframes open-from-left {
                    0% {
                        opacity: 0;
                        transform: translateX(-20px);
                        visibility: hidden;
                    }

                    50% {
                        opacity: 0;
                        transform: translateX(-20px);
                        visibility: visible;
                    }

                    100% {
                        opacity: 1;
                        transform: translateX(0);
                        visibility: visible;
                    }
                }
            }

            &.open-from-right {
                animation: open-from-right 0.5s ease-in-out forwards;

                @keyframes open-from-right {
                    0% {
                        opacity: 0;
                        transform: translateX(20px);
                        visibility: hidden;
                    }

                    50% {
                        opacity: 0;
                        transform: translateX(20px);
                        visibility: visible;
                    }

                    100% {
                        opacity: 1;
                        transform: translateX(0);
                        visibility: visible;
                    }
                }
            }

            &.close-to-left {
                animation: close-to-left 0.5s ease-in-out forwards;

                @keyframes close-to-left {
                    0% {
                        opacity: 1;
                        transform: translateX(0);
                        visibility: visible;
                    }

                    50% {
                        opacity: 0;
                        transform: translateX(20px);
                        visibility: visible;
                    }

                    100% {
                        opacity: 0;
                        transform: translateX(20px);
                        visibility: hidden;
                    }
                }
            }

            &.close-to-right {
                animation: close-to-right 0.5s ease-in-out forwards;

                @keyframes close-to-right {
                    0% {
                        opacity: 1;
                        transform: translateX(0);
                        visibility: visible;
                    }

                    50% {
                        opacity: 0;
                        transform: translateX(-20px);
                        visibility: visible;
                    }

                    100% {
                        opacity: 0;
                        transform: translateX(-20px);
                        visibility: hidden;
                    }
                }
            }
        }
    }

    &.dark .tab-switcher-container {
        &::before {
            background: linear-gradient(to right, $deep-blue, rgba($deep-blue, 0.0));
        }

        &::after {
            background: linear-gradient(to left, $deep-blue, rgba($deep-blue, 0.0));
        }

        &:focus-visible .tab-switcher .tab.active {
            background-color: $bold-green-12-db;
        }

        .tab-switcher {
            &::-webkit-scrollbar-thumb {
                background: $deep-blue-70;
            }

            .tab {
                color: white;
                border-color: $deep-blue-60;

                &:hover, &:active {
                    background-color: $bold-green-12-db;
                }
            }

            .tab-switcher-underline {
                border-color: $bold-green;
            }
        }
    }
}
