.radio-button-container {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 5px;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    .radio-button {
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 16px;
        width: 16px;
        border: 2px solid $deep-blue-70;
        background-color: $interactive-blue-12;
        border-radius: 100%;
        outline: none;
        transition: background 0.3s ease-in-out, border 0.3s ease-in-out;

        &::before {
            content: "";
            height: 8px;
            width: 8px;
            border-radius: 100%;
            background-color: $deep-blue-70;
            transition: background 0.3s ease-in-out;
            transform: scale(0.05);
            animation: radio-button-close 0.2s ease-in forwards;

            @keyframes radio-button-close {
                0% {
                    transform: scale(1);
                }

                100% {
                    transform: scale(0);
                }
            }
        }

        &.selected::before {
            animation: radio-button-open 0.4s linear forwards;

            @keyframes radio-button-open {
                0% {
                    transform: scale(0);
                }

                40% {
                    transform: scale(1.2);
                }

                100% {
                    transform: scale(1);
                }
            }
        }
    }

    .radio-button-label {
        font-size: 16px;
        color: $deep-blue-70;
        user-select: none;
        transition: color 0.3s ease-in-out;
    }

    &:not(.disabled):hover, &:not(.disabled):active {
        .radio-button {
            background-color: $bold-green-20;
            border-color: $teal;

            &::before {
                background-color: $teal;
            }
        }

        .radio-button-label {
            color: $deep-blue;
        }
    }

    &:not(:disabled):focus-visible .radio-button::after {
        position: absolute;
        content: "";
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        outline: 1px solid $focus-blue;
        outline-offset: 3px;
    }

    &:not(.disabled) .radio-button.selected + .radio-button-label {
        color: $deep-blue;
    }

    &.disabled {
        cursor: not-allowed;

        .radio-button {
            background-color: transparent;
            border-color: $deep-blue-50;

            &::before {
                background-color: $deep-blue-50;
            }
        }

        .radio-button-label {
            color: $deep-blue-50;
        }
    }

    &.dark {
        .radio-button {
            background-color: $deep-blue-85;
            border-color: $deep-blue-30;

            &::before {
                background-color: $deep-blue-30;
            }
        }

        .radio-button-label {
            color: $deep-blue-30;
        }

        &:not(.disabled):hover, &:not(.disabled):active {
            .radio-button {
                background-color: $bold-green-12-db;
                border-color: $bold-green;

                &::before {
                    background-color: $bold-green;
                }
            }

            .radio-button-label {
                color: $deep-blue-8;
            }
        }

        &:not(.disabled) .radio-button.selected + .radio-button-label {
            color: $deep-blue-8;
        }

        &.disabled {
            .radio-button {
                background-color: transparent;
                border-color: $deep-blue-60;

                &::before {
                    background-color: $deep-blue-60;
                }
            }

            .radio-button-label {
                color: $deep-blue-60;
            }
        }
    }
}
