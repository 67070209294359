.pagination {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;

    .pagination-button {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 20px;
        padding: 5px;
        color: $interactive-blue;
        outline: none;
        cursor: pointer;
        user-select: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

        svg {
            width: 20px;
            fill: $interactive-blue;
        }

        &.active {
            color: white;
            background-color: $teal;
            cursor: default;

            svg {
                fill: white;
            }

            &.disabled {
                background-color: $deep-blue-70;
                cursor: not-allowed;
            }
        }

        &:not(.active).disabled {
            color: $deep-blue-50;
            cursor: not-allowed;

            svg {
                fill: $deep-blue-50;
            }
        }

        &.divider, &.divider.disabled {
            cursor: default;
        }

        &:not(.active):not(.disabled):not(.divider):hover {
            color: $teal;
            background-color: $light-green-40;

            svg {
                fill: $teal;
            }
        }

        &:not(.disabled):focus-visible {
            outline: 1px solid $focus-blue;
        }
    }

    &.dark {
        .pagination-button {
            color: $interactive-coral;

            svg {
                fill: $interactive-coral;
            }

            &.active {
                color: $deep-blue;
                background-color: $interactive-coral;

                svg {
                    fill: $deep-blue;
                }

                &.disabled {
                    background-color: $deep-blue-30;
                }
            }

            &:not(.active).disabled {
                color: $deep-blue-30;

                svg {
                    fill: $deep-blue-30;
                }
            }

            &:not(.active):not(.disabled):not(.divider):hover {
                color: $deep-blue;
                background-color: $light-green-40;

                svg {
                    fill: $deep-blue;
                }
            }
        }
    }
}
