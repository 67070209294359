﻿.link {
    display: inline-flex;
    gap: 8px;
    align-items: center;
    font-weight: 700;
    color: $interactive-blue;
    text-decoration: underline;
    text-decoration-color: transparent;
    text-underline-position: under;
    text-underline-offset: 1px;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transition: color 0.3s ease-in-out, text-decoration-color 0.3s ease-in-out;

    svg {
        width: 20px;
        flex-shrink: 0;
        fill: $interactive-blue;
        transition: fill 0.3s ease-in-out;
    }

    &:hover, &:active {
        color: $teal-90;
        text-decoration-color: $teal-90;

        svg {
            fill: $teal-90;
        }
    }

    &:focus-visible {
        outline: 1px solid $focus-blue;
        outline-offset: 2px
    }

    &.dark {
        color: $interactive-coral;

        svg {
            fill: $interactive-coral;
        }

        &:hover, &:active {
            color: $bold-green;
            text-decoration-color: $bold-green;

            svg {
                fill: $bold-green;
            }
        }
    }
}
