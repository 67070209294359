.tag-input {
    .tag-input-field {
        min-width: 205px;
        box-sizing: border-box;
        padding: 4px 14px 6px 14px;
        color: $deep-blue-70;
        background-color: $interactive-cyan-8;
        border-bottom: 1px solid $deep-blue-70;
        outline: none;
        user-select: none;
        cursor: text;
        transition: color 0.3s ease-in-out, background 0.3s ease-in-out, border 0.3s ease-in-out;

        .tag-input-label {
            position: relative;
            font-size: 10px;
            user-select: none;
            pointer-events: none;
            transition: color 0.3s ease-in-out;

            &.required::before {
                position: absolute;
                content: "*";
                right: calc(100% + 2px);
            }
        }

        .tag-input-container {
            display: flex;
            flex-wrap: wrap;
            gap: 5px;
            padding-top: 5px;

            .tag {
                padding: 4px 12px;
                gap: 6px;
            }

            input {
                height: 30px;
                box-sizing: border-box;
                padding: 0 2px;
                flex-grow: 1;
                font-size: 16px;
                color: $deep-blue;
                background: none;
                border: none;
                outline: none;
            }
        }

        &:hover, &:focus-within {
            color: $deep-blue;
            background-color: $bold-green-18;
            border-color: $green;

            .tag-input-label {
                color: $deep-blue;
            }
        }
    }

    .tag-input-message {
        margin: 3px 14px 0;
        font-size: 10px;
        color: $deep-blue;
        user-select: none;
    }

    &.error:not(.disabled) {
        .tag-input-field:not(:focus-within) {
            color: $deep-blue;
            background-color: $light-red;
            border-color: $feedback-red-dark;

            .tag-input-label {
                color: $feedback-red-dark !important;
            }
        }

        .tag-input-message {
            color: $feedback-red-dark;
        }
    }

    &.disabled .tag-input-field {
        color: $deep-blue-50;
        background-color: $interactive-cyan-8;
        border-color: transparent;
        cursor: not-allowed;

        .tag-input-label {
            color: $deep-blue-50;
        }

        .tag-input-container {
            .tag {
                cursor: not-allowed;
            }

            input {
                cursor: not-allowed;
            }
        }
    }

    &.dark {
        .tag-input-field {
            color: $deep-blue-8;
            background-color: $interactive-coral-12-db;
            border-color: $deep-blue-50;

            .tag-input-label {
                color: $deep-blue-50;
            }

            .tag-input-container input {
                color: white;
            }

            &:hover, &:focus-within {
                color: $deep-blue-8;
                background-color: $bold-green-12-db;
                border-color: $bold-green;

                .tag-input-label {
                    color: $bold-green;
                }
            }
        }

        .tag-input-message {
            color: $deep-blue-8;
        }

        &.error:not(.disabled) {
            .tag-input-field:not(:focus-within) {
                color: $deep-blue-8;
                background-color: $dark-red;
                border-color: $medium-red;

                .tag-input-label {
                    color: $medium-red !important;
                }
            }

            .tag-input-message {
                color: $deep-blue-50;
            }
        }

        &.disabled .tag-input-field, &.disabled:hover .tag-input-field {
            color: $deep-blue-60;
            background-color: $interactive-coral-12-db;
            border-color: transparent;

            .tag-input-label {
                color: $deep-blue-60;
            }
        }
    }
}
