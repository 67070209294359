﻿.mutable-list {
    background-color: $interactive-cyan-8;
    border-bottom: 1px solid $deep-blue-70;
    display: flex;
    flex-direction: column;
    height: 400px;
    padding: 0 11px 6px;

    .label {
        color: $deep-blue-70;
        flex-shrink: 0;
        font-size: 10px;
        overflow: hidden;
        padding: 4px 3px;
        text-overflow: ellipsis;
        user-select: none;
        white-space: nowrap;
    }

    .items {
        flex-grow: 1;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 0 3px;

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-track {
            background-color: inherit;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $interactive-cyan-10;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: $interactive-cyan-20;
        }

        .item {
            align-items: center;
            background-color: $interactive-cyan-8;
            border: 1px solid $interactive-blue;
            border-radius: 17px;
            color: $interactive-blue;
            display: flex;
            gap: 8px;
            margin-top: 6px;
            padding: 6px 16px;
            transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, color 0.3s ease-in-out;
            user-select: none;

            svg {
                fill: $interactive-blue;
                flex-shrink: 0;
                width: 20px;
            }

            .name {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            &:hover:not(.disabled), &.dragging {
                background-color: $bold-green-20;
                border-color: $teal;
                color: $teal;

                svg {
                    fill: $teal;
                }
            }

            &:focus-visible {
                outline: $focus-blue solid 1px;
                outline-offset: 2px;
            }

            &.disabled {
                border-color: $deep-blue-50;
                color: $deep-blue-50;
                cursor: not-allowed;

                svg {
                    fill: $deep-blue-50
                }

            }
        }
    }
}
