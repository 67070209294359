.header-action {
    display: flex;
    align-items: center;

    .header-action-button {
        position: relative;
        margin: 0 5px;
        padding: 5px;
        display: flex;
        align-items: center;
        gap: 5px;
        outline: none;
        cursor: pointer;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

        &:focus-visible {
            outline: 1px solid $focus-blue;
        }

        svg {
            width: 20px;
        }

        .header-action-dropdown {
            position: absolute;
            top: calc(100% + 10px);
            right: -5px;
            z-index: 1;
            min-width: 200px;
            color: white;
            background-color: $bold-green-12-db;
            box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
            cursor: default;

            &.opening {
                animation: header-action-dropdown-open 0.4s cubic-bezier(.75,-.02,.2,.97) forwards;

                @keyframes header-action-dropdown-open {
                    0% {
                        transform: translate(0, 20px);
                        opacity: 0;
                    }

                    100% {
                        transform: translate(0);
                        opacity: 1;
                    }
                }
            }

            &.closing {
                animation: header-action-dropdown-close 0.4s cubic-bezier(.75,-.02,.2,.97) forwards;

                @keyframes header-action-dropdown-close {
                    0% {
                        transform: translate(0);
                        opacity: 1;
                    }

                    100% {
                        transform: translate(0, 20px);
                        opacity: 0;
                    }
                }
            }

            &.closed {
                display: none;
            }

            &::before {
                position: absolute;
                content: "";
                top: -10px;
                right: 10px;
                border-width: 0 10px 10px;
                border-style: solid;
                border-color: transparent transparent $bold-green-12-db;
            }

            .header-action-dropdown-title {
                padding: 15px 20px;
                font-size: 18px;
                border-bottom: 1px solid $deep-blue;
                user-select: none;
            }

            .header-action-dropdown-action {
                display: flex;
                align-items: center;
                padding: 15px 20px;
                color: $interactive-coral;
                outline: none;
                cursor: pointer;
                user-select: none;
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                transition: color 0.3s ease-in-out;

                &.disabled {
                    color: $deep-blue-50;
                    cursor: default;

                    svg {
                        fill: $deep-blue-50;
                    }
                }

                &:not(:last-child) {
                    padding-bottom: 10px;
                }

                &:not(:nth-child(2)) {
                    padding-top: 10px;
                }

                &:not(.disabled):hover {
                    color: $bold-green;

                    svg {
                        fill: $bold-green;
                    }
                }

                &:focus-visible {
                    outline: 1px solid $focus-blue;
                    outline-offset: -1px;
                }

                svg {
                    margin-right: 5px;
                    fill: $interactive-coral;
                    transition: fill 0.3s ease-in-out;
                }
            }
        }
    }
}
