.flex {
    display: flex;
    background-color: inherit;

    &.alignment-baseline {
        align-items: baseline;
    }

    &.alignment-center {
        align-items: center;
    }

    &.alignment-end {
        align-items: end;
    }

    &.alignment-start {
        align-items: start;
    }

    &.alignment-stretch {
        align-items: stretch;
    }

    &.direction-column {
        height: 100%;
        flex-direction: column;
    }

    &.direction-column-reverse {
        height: 100%;
        flex-direction: column-reverse;
    }

    &.direction-row {
        flex-direction: row;
    }

    &.direction-row-reverse {
        flex-direction: row-reverse;
    }

    &.justification-center {
        justify-content: center;
    }

    &.justification-flex-end {
        justify-content: flex-end;
    }

    &.justification-flex-start {
        justify-content: flex-start;
    }

    &.justification-space-around {
        justify-content: space-around;
    }

    &.justification-space-evenly {
        justify-content: space-evenly;
    }

    &.justification-space-between {
        justify-content: space-between;
    }

    &.wrap-no-wrap {
        flex-wrap: nowrap;
    }

    &.wrap-wrap {
        flex-wrap: wrap;
    }

    &.wrap-wrap-reverse {
        flex-wrap: wrap-reverse;
    }
}
