﻿.project-header {
    padding: 0 20px 8px;

    &.hide {
        display: none;
    }

    .project-title {
        font-size: 20px;
        font-weight: 700;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .project-locked-tag {
        padding: 4px 12px;
        font-size: 14px;
        color: white;
        background-color: $feedback-red-light;
        border-radius: 12px;
        user-select: none;
    }

    .project-description {
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
