.modal-background {
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 10000;
    padding-top: 149px;
    align-items: center;
    justify-content: center;
    background-color: #2d373c99;
    transition: padding 0.5s ease-in-out;

    &.clickable {
        cursor: pointer;
    }

    .modal {
        --offset-x: 0px;
        --offset-y: 0px;
        position: relative;
        width: 600px;
        max-height: calc(100% - 100px);
        margin: 0 10px;
        padding: 25px;
        background-color: white;
        border-radius: 1px;
        outline: none;
        box-shadow: 0px 0px 50px #21282c99;
        cursor: default;
        transform: translate(var(--offset-x), var(--offset-y));

        &.full-width {
            width: 1350px;
        }

        &:focus-visible {
            outline: 1px solid $focus-blue;
            outline-offset: 2px;
        }

        .move-handle {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            height: 20px;
            cursor: move;
        }
    }

    &.opening {
        animation: modal-background-appear 0.2s ease-in-out forwards;

        @keyframes modal-background-appear {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }

        .modal {
            animation: modal-appear 0.2s ease-in-out forwards;

            @keyframes modal-appear {
                0% {
                    transform: scale(1.1) translate(var(--offset-x), var(--offset-y));
                }

                100% {
                    transform: scale(1) translate(var(--offset-x), var(--offset-y));
                }
            }
        }
    }

    &.closing {
        animation: modal-background-disappear 0.2s ease-in-out forwards;

        @keyframes modal-background-disappear {
            0% {
                opacity: 1;
            }

            100% {
                opacity: 0;
            }
        }

        .modal {
            animation: modal-disappear 0.2s ease-in-out forwards;

            @keyframes modal-disappear {
                0% {
                    transform: scale(1) translate(var(--offset-x), var(--offset-y));
                }

                100% {
                    transform: scale(1.1) translate(var(--offset-x), var(--offset-y));
                }
            }
        }
    }

    &.closed {
        display: none;
    }

    &.dark {
        background-color: #1f2629cc;

        .modal {
            color: white;
            background-color: $deep-blue;
            box-shadow: 0px 0px 50px #0b0d0fcc;

            svg {
                fill: white;
            }
        }
    }
}
