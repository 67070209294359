.snackbars {
    position: fixed;
    left: calc(50% - 300px);
    bottom: 20px;
    width: 600px;
    z-index: 10;
    display: flex;
    flex-direction: column;

    @media (max-width: 620px) {
        right: 10px;
        left: 10px;
        width: auto;
    }
}
