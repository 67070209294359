.menu-icon {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    .menu-bar {
        height: 2px;
        width: 100%;
        background-color: $deep-blue;
        transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
        will-change: opacity, transform;

        &:nth-child(2) {
            width: 66%;
        }
    }

    &.open {
        .menu-bar {
            &:first-child {
                transform: translate(0px, 6px) rotate(45deg);
            }

            &:nth-child(2) {
                opacity: 0;
            }

            &:last-child {
                transform: translate(0px, -6px) rotate(-45deg);
            }
        }
    }

    &:focus-visible {
        outline: 1px solid $focus-blue;
        outline-offset: 1px;
    }
}
