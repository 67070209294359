body {
    margin: 0;
    padding-right: 0 !important; // Negate SideSheet effects
    overflow: auto !important; // Negate SideSheet effects
    font-family: SiemensSans;
    color: $deep-blue;

    svg {
        fill: $deep-blue;
    }

    .page {
        display: flex;
        flex-direction: column;
        min-height: 100vh;

        main {
            position: relative;
            z-index: 0;
            flex-grow: 1;
            padding-top: 100px;
            background-color: white;
            outline: none;
            transition: padding 0.5s ease-in-out;
            will-change: padding;

            @media (max-width: $hide-tabs-max) {
                padding-top: 60px;
            }

            &.with-project-header {
                padding-top: 148px;

                @media (max-width: $hide-tabs-max) {
                    padding-top: 108px;
                }
            }
        }
    }
}
