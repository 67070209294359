.accordion {
  border: 1px solid #4C4C68;
  border-right: none;
  border-left: none;
  outline: none;
  transition: background 0.3s ease-in-out, border 0.3s ease-in-out;
}
.accordion:focus-visible {
  outline: 1px solid #36ABFF;
  outline-offset: 3px;
}
.accordion .header {
  display: grid;
  grid-template-rows: 30px;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding: 25px;
  cursor: pointer;
  user-select: none;
}
.accordion .header .header-icon {
  display: flex;
  justify-self: center;
}
.accordion .header .header-icon svg {
  width: 20px;
  fill: #000028;
}
.accordion .header .header-label-container {
  position: relative;
  color: #000028;
}
.accordion .header .header-label-container .header-label {
  position: relative;
  font-size: 16px;
  font-weight: 900;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transform: translateY(0);
  transition: font-size 0.5s ease-in-out, transform 0.5s ease-in-out;
}
.accordion .header .header-label-container .header-closed-label {
  position: absolute;
  width: calc(100% - 10px);
  margin-left: 10px;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transform: translateY(18px);
  opacity: 0;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}
.accordion .header .header-close-icon {
  height: 20px;
  width: 20px;
  display: grid;
  justify-self: center;
}
.accordion .header .header-close-icon::before, .accordion .header .header-close-icon::after {
  content: "";
  display: block;
  height: 2px;
  width: 16px;
  grid-row: 1/span 1;
  grid-column: 1/span 1;
  align-self: center;
  justify-self: center;
  background-color: #000028;
  transition: transform 0.4s ease-in-out 0.1s;
}
.accordion .header .header-close-icon::before {
  transform: rotate(180deg);
}
.accordion .header .header-close-icon::after {
  transform: rotate(180deg);
}
.accordion .header.with-icon {
  grid-template-columns: auto 1fr auto;
}
.accordion .header.with-icon .header-label-container {
  padding-left: 25px;
}
.accordion .header-content-container .header-content {
  padding: 0 20px 30px;
}
.accordion.closing .header .header-label-container .header-label:not(:first-child), .accordion.closed .header .header-label-container .header-label:not(:first-child) {
  transform: translateY(-12px);
}
.accordion.closing .header .header-label-container .header-closed-label, .accordion.closed .header .header-label-container .header-closed-label {
  transform: translateY(8px);
  opacity: 1;
}
.accordion.closing .header .header-close-icon::before, .accordion.closed .header .header-close-icon::before {
  transform: rotate(0deg);
}
.accordion.closing .header .header-close-icon::after, .accordion.closed .header .header-close-icon::after {
  transform: rotate(90deg);
}
.accordion.opening .header-content-container {
  animation: accordion-open 0.5s ease-in-out forwards;
}
@keyframes accordion-open {
  0% {
    height: 0;
  }
}
.accordion.closing .header-content-container {
  animation: accordion-close 0.5s ease-in-out forwards;
}
@keyframes accordion-close {
  100% {
    height: 0;
  }
}
.accordion:not(.open) {
  overflow: hidden;
}
.accordion.closed .header-content-container {
  height: 0;
}
.accordion:not(:last-child) {
  border-bottom: none;
}
.accordion:hover {
  background-color: #FAFDFD;
  border-color: #00646E;
}
.accordion:hover:not(:last-child) ~ .accordion {
  border-top-color: #00646E;
}
.accordion.dark {
  border-color: #CCCCD4;
}
.accordion.dark .header .header-icon svg {
  fill: white;
}
.accordion.dark .header .header-label-container {
  color: white;
}
.accordion.dark .header .header-close-icon::before, .accordion.dark .header .header-close-icon::after {
  background-color: white;
}
.accordion.dark .header-content-container {
  color: white;
}
.accordion.dark:hover {
  background-color: #001F39;
  border-color: #00FFB9;
}
.accordion.dark:hover:not(:last-child) ~ .accordion {
  border-top-color: #00FFB9;
}

.banner .banner-content-container {
  background-color: white;
}
.banner .banner-content-container .content-container .banner-content {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 15px;
  padding: 10px 0;
}
.banner .banner-content-container .content-container .banner-content svg {
  width: 30px;
}
.banner .banner-content-container .content-container .banner-content .banner-text {
  padding: 10px 0;
  font-size: 18px;
  color: #000028;
}
.banner .banner-content-container .content-container .banner-content .banner-close-button {
  display: flex;
  align-items: center;
  outline: none;
  cursor: pointer;
}
.banner .banner-content-container .content-container .banner-content .banner-close-button svg {
  width: 20px;
  fill: black;
}
.banner .banner-content-container .content-container .banner-content .banner-close-button:focus-visible {
  outline: 1px solid #36ABFF;
}
.banner.opening {
  animation: banner-open 0.5s ease-in-out forwards;
}
@keyframes banner-open {
  0% {
    height: 0;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.banner.closing {
  animation: banner-close 0.5s ease-in-out forwards;
}
@keyframes banner-close {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    height: 0;
  }
}
.banner.closed {
  height: 0;
  opacity: 0;
}
.banner.error .banner-content-container .content-container .banner-content > svg {
  fill: #D72339;
}
.banner.info .banner-content-container .content-container .banner-content > svg {
  fill: black;
}
.banner.success .banner-content-container .content-container .banner-content > svg {
  fill: #01893A;
}
.banner.warning .banner-content-container .content-container .banner-content > svg {
  fill: #E9C32A;
}
.banner.dark .banner-content-container {
  background-color: #001F39;
}
.banner.dark .banner-content-container .content-container .banner-content .banner-text {
  color: white;
}
.banner.dark .banner-content-container .content-container .banner-content .banner-close-button svg {
  fill: white;
}
.banner.dark.error .banner-content-container .content-container .banner-content > svg {
  fill: #FF2640;
}
.banner.dark.info .banner-content-container .content-container .banner-content > svg {
  fill: white;
}
.banner.dark.success .banner-content-container .content-container .banner-content > svg {
  fill: #01D65A;
}
.banner.dark.warning .banner-content-container .content-container .banner-content > svg {
  fill: #FFD732;
}

.button {
  position: relative;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  padding: 10px 25px;
  font-weight: 700;
  border: none;
  outline: none;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: color 0.3s ease-in-out, background 0.3s ease-in-out, border 0.3s ease-in-out;
}
.button svg {
  width: 20px;
  transition: fill 0.3s ease-in-out;
}
.button.call-to-action {
  color: #000028;
  background: linear-gradient(to right, #00FFB9, #00E6DC);
}
.button.call-to-action svg {
  fill: #000028;
}
.button.call-to-action::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: #62EEC7;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.button.primary {
  color: white;
  background: #007993;
}
.button.primary svg {
  fill: white;
}
.button.secondary {
  padding: 9px 24px;
  color: #007993;
  background-color: transparent;
  border: 1px solid #007993;
  border-radius: 2px;
}
.button.secondary svg {
  fill: #007993;
}
.button.tertiary {
  color: #007993;
  background-color: transparent;
}
.button.tertiary svg {
  fill: #007993;
}
.button.disabled {
  cursor: not-allowed;
}
.button.disabled.call-to-action {
  color: #EBEBEE;
  background: #B3B3BE;
}
.button.disabled.call-to-action svg {
  fill: #EBEBEE;
}
.button.disabled.primary {
  color: #EBEBEE;
  background: #B3B3BE;
}
.button.disabled.primary svg {
  fill: #EBEBEE;
}
.button.disabled.secondary {
  color: #B3B3BE;
  border-color: #B3B3BE;
}
.button.disabled.secondary svg {
  fill: #B3B3BE;
}
.button.disabled.tertiary {
  color: #B3B3BE;
}
.button.disabled.tertiary svg {
  fill: #B3B3BE;
}
.button:not(.disabled):hover.call-to-action::before, .button:not(.disabled):active.call-to-action::before {
  opacity: 1;
}
.button:not(.disabled):hover.primary, .button:not(.disabled):active.primary {
  background: #00646E;
}
.button:not(.disabled):hover.secondary, .button:not(.disabled):active.secondary {
  color: #005159;
  background-color: #C2FFEE;
  border: 1px solid #005159;
  border-radius: 2px;
}
.button:not(.disabled):hover.secondary svg, .button:not(.disabled):active.secondary svg {
  fill: #005159;
}
.button:not(.disabled):hover.tertiary, .button:not(.disabled):active.tertiary {
  color: #005159;
  background-color: #C2FFEE;
}
.button:not(.disabled):hover.tertiary svg, .button:not(.disabled):active.tertiary svg {
  fill: #005159;
}
.button:not(.disabled):focus-visible::after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  outline: 1px solid #36ABFF;
  outline-offset: 1px;
}
.button:not(.disabled):focus-visible.secondary::after {
  outline-offset: 2px;
}
.button.dark.call-to-action::before {
  background: #C5FFEF;
}
.button.dark.primary {
  color: #000028;
  background: #00CCCC;
}
.button.dark.primary svg {
  fill: #000028;
}
.button.dark.secondary {
  color: #00CCCC;
  background-color: transparent;
  border: 1px solid #00CCCC;
}
.button.dark.secondary svg {
  fill: #00CCCC;
}
.button.dark.tertiary {
  color: #00CCCC;
  background-color: transparent;
}
.button.dark.tertiary svg {
  fill: #00CCCC;
}
.button.dark.disabled.call-to-action {
  color: #B3B3BE;
  background: #737389;
}
.button.dark.disabled.call-to-action svg {
  fill: #B3B3BE;
}
.button.dark.disabled.primary {
  color: #B3B3BE;
  background: #737389;
}
.button.dark.disabled.primary svg {
  fill: #B3B3BE;
}
.button.dark.disabled.secondary {
  color: #737389;
  border-color: #737389;
}
.button.dark.disabled.secondary svg {
  fill: #737389;
}
.button.dark.disabled.tertiary {
  color: #737389;
}
.button.dark.disabled.tertiary svg {
  fill: #737389;
}
.button.dark:not(.disabled):hover.primary, .button.dark:not(.disabled):active.primary {
  background: #00FFB9;
}
.button.dark:not(.disabled):hover.secondary, .button.dark:not(.disabled):active.secondary {
  color: #00FFB9;
  background-color: #001F39;
  border: 1px solid #00FFB9;
}
.button.dark:not(.disabled):hover.secondary svg, .button.dark:not(.disabled):active.secondary svg {
  fill: #00FFB9;
}
.button.dark:not(.disabled):hover.tertiary, .button.dark:not(.disabled):active.tertiary {
  color: #00FFB9;
  background-color: #001F39;
}
.button.dark:not(.disabled):hover.tertiary svg, .button.dark:not(.disabled):active.tertiary svg {
  fill: #00FFB9;
}

.button-with-popup {
  align-items: center;
}
.button-with-popup .button {
  z-index: unset;
}
.button-with-popup .button-with-popup-button {
  position: relative;
}
.button-with-popup .button-with-popup-button .button-with-popup-dropdown {
  position: absolute;
  top: calc(100% + 10px);
  right: -5px;
  z-index: 1;
  min-width: 200px;
  color: white;
  background-color: #001F39;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  cursor: default;
}
.button-with-popup .button-with-popup-button .button-with-popup-dropdown.opening {
  animation: button-with-popup-dropdown-open 0.4s cubic-bezier(0.75, -0.02, 0.2, 0.97) forwards;
}
@keyframes button-with-popup-dropdown-open {
  0% {
    transform: translate(0, 20px);
    opacity: 0;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
}
.button-with-popup .button-with-popup-button .button-with-popup-dropdown.closing {
  animation: button-with-popup-dropdown-close 0.4s cubic-bezier(0.75, -0.02, 0.2, 0.97) forwards;
}
@keyframes button-with-popup-dropdown-close {
  0% {
    transform: translate(0);
    opacity: 1;
  }
  100% {
    transform: translate(0, 20px);
    opacity: 0;
  }
}
.button-with-popup .button-with-popup-button .button-with-popup-dropdown.closed {
  display: none;
}
.button-with-popup .button-with-popup-button .button-with-popup-dropdown::before {
  position: absolute;
  content: "";
  top: -10px;
  right: 30px;
  border-width: 0 10px 10px;
  border-style: solid;
  border-color: transparent transparent #001F39;
}
.button-with-popup .button-with-popup-button .button-with-popup-dropdown .button-with-popup-dropdown-action {
  display: flex;
  align-items: center;
  padding: 5px 20px 5px 20px;
  color: #00CCCC;
  outline: none;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: color 0.3s ease-in-out;
}
.button-with-popup .button-with-popup-button .button-with-popup-dropdown .button-with-popup-dropdown-action:first-child {
  padding-top: 15px;
}
.button-with-popup .button-with-popup-button .button-with-popup-dropdown .button-with-popup-dropdown-action:last-child {
  padding-bottom: 15px;
}
.button-with-popup .button-with-popup-button .button-with-popup-dropdown .button-with-popup-dropdown-action:hover {
  color: #00FFB9;
}
.button-with-popup .button-with-popup-button .button-with-popup-dropdown .button-with-popup-dropdown-action:hover svg {
  fill: #00FFB9;
}
.button-with-popup .button-with-popup-button .button-with-popup-dropdown .button-with-popup-dropdown-action:focus-visible {
  outline: 1px solid #36ABFF;
  outline-offset: -1px;
}
.button-with-popup .button-with-popup-button .button-with-popup-dropdown .button-with-popup-dropdown-action svg {
  margin-right: 5px;
  fill: #00CCCC;
  transition: fill 0.3s ease-in-out;
}

.carousel .slick-slider {
  margin-bottom: 35px;
}
.carousel .slick-slider .slick-arrow {
  height: 50px;
  width: 50px;
  z-index: 1;
  background-color: #007993;
  border-radius: 50%;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06), 0 6px 8px 0 rgba(0, 0, 0, 0.08);
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}
.carousel .slick-slider .slick-arrow::before {
  font-size: 30px;
  font-family: SiemensIcons;
  color: white;
  opacity: 1;
}
.carousel .slick-slider .slick-arrow:focus-visible {
  outline: 1px solid #36ABFF;
  outline-offset: 2px;
}
.carousel .slick-slider .slick-arrow.slick-disabled {
  background: #B3B3BE;
  cursor: not-allowed;
}
.carousel .slick-slider .slick-arrow.slick-disabled::before {
  color: #EBEBEE;
}
.carousel .slick-slider .slick-arrow:not(.slick-disabled):hover {
  background: #00646E;
}
.carousel .slick-slider .slick-arrow.slick-prev {
  left: 10px;
}
.carousel .slick-slider .slick-arrow.slick-prev::before {
  content: "\e902";
}
.carousel .slick-slider .slick-arrow.slick-next {
  right: 10px;
}
.carousel .slick-slider .slick-arrow.slick-next::before {
  content: "\e903";
}
.carousel .slick-slider .slick-dots {
  bottom: -30px;
}
.carousel .slick-slider .slick-dots li {
  margin: 0;
}
.carousel .slick-slider .slick-dots li button::before {
  font-size: 12px;
  color: #000028;
}
.carousel .slick-slider .slick-dots li button:hover::before {
  color: #00646E;
}
.carousel .slick-slider .slick-dots li button:focus-visible {
  outline: 1px solid #36ABFF;
}
.carousel .slick-slider .slick-list .slick-track .slick-slide > div > div {
  outline: none;
}
.carousel.dark .slick-slider .slick-arrow {
  background-color: #00CCCC;
}
.carousel.dark .slick-slider .slick-arrow::before {
  color: #000028;
}
.carousel.dark .slick-slider .slick-arrow.slick-disabled {
  background: #737389;
}
.carousel.dark .slick-slider .slick-arrow.slick-disabled::before {
  color: #B3B3BE;
}
.carousel.dark .slick-slider .slick-arrow:not(.slick-disabled):hover {
  background: #00FFB9;
}

.checkbox-container {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 8px;
  outline: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.checkbox-container .checkbox {
  position: relative;
  height: 16px;
  min-width: 16px;
  border: 2px solid #4C4C68;
  background-color: #E0F1F4;
  outline: none;
  transition: background 0.3s ease-in-out, border 0.3s ease-in-out;
}
.checkbox-container .checkbox .checkmark {
  position: absolute;
  top: 0px;
  left: 4px;
  height: 12px;
  width: 8px;
  transform: rotate(40deg);
}
.checkbox-container .checkbox .checkmark::before, .checkbox-container .checkbox .checkmark::after {
  position: absolute;
  content: "";
  background-color: #4C4C68;
}
.checkbox-container .checkbox .checkmark::before {
  width: 0;
  height: 2px;
  top: calc(100% - 2px);
  left: 0;
  transition: width 0.15s ease-in-out 0.15s, background 0.3s ease-in-out 0s;
}
.checkbox-container .checkbox .checkmark::after {
  width: 2px;
  height: 0;
  bottom: 0;
  left: calc(100% - 2px);
  transition: height 0.15s ease-in-out 0s, background 0.3s ease-in-out 0s;
}
.checkbox-container .checkbox.checked .checkmark::before {
  width: 100%;
  transition: width 0.15s ease-in-out 0s, background 0.3s ease-in-out 0s;
}
.checkbox-container .checkbox.checked .checkmark::after {
  height: 100%;
  transition: height 0.15s ease-in-out 0.15s, background 0.3s ease-in-out 0s;
}
.checkbox-container .checkbox-label {
  font-size: 16px;
  color: #4C4C68;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  transition: color 0.3s ease-in-out;
}
.checkbox-container:not(.disabled):hover .checkbox, .checkbox-container:not(.disabled):active .checkbox {
  background-color: #C2FFEE;
  border-color: #005159;
}
.checkbox-container:not(.disabled):hover .checkbox .checkmark::before, .checkbox-container:not(.disabled):hover .checkbox .checkmark::after, .checkbox-container:not(.disabled):active .checkbox .checkmark::before, .checkbox-container:not(.disabled):active .checkbox .checkmark::after {
  background-color: #005159;
}
.checkbox-container:not(.disabled):hover .checkbox-label, .checkbox-container:not(.disabled):active .checkbox-label {
  color: #000028;
}
.checkbox-container:not(:disabled):focus-visible .checkbox {
  outline: 1px solid #36ABFF;
  outline-offset: 1px;
}
.checkbox-container:not(.disabled) .checkbox.checked + .checkbox-label {
  color: #000028;
}
.checkbox-container.disabled {
  cursor: not-allowed;
}
.checkbox-container.disabled .checkbox {
  background-color: transparent;
  border-color: #7D8099;
}
.checkbox-container.disabled .checkbox .checkmark::before, .checkbox-container.disabled .checkbox .checkmark::after {
  background-color: #7D8099;
}
.checkbox-container.disabled .checkbox-label {
  color: #7D8099;
}
.checkbox-container.dark .checkbox {
  background-color: #262648;
  border-color: #B3B3BE;
}
.checkbox-container.dark .checkbox .checkmark::before {
  background-color: #B3B3BE;
}
.checkbox-container.dark .checkbox .checkmark::after {
  background-color: #B3B3BE;
}
.checkbox-container.dark .checkbox-label {
  color: #B3B3BE;
}
.checkbox-container.dark:not(.disabled):hover .checkbox, .checkbox-container.dark:not(.disabled):active .checkbox {
  background-color: #001F39;
  border-color: #00FFB9;
}
.checkbox-container.dark:not(.disabled):hover .checkbox .checkmark::before, .checkbox-container.dark:not(.disabled):hover .checkbox .checkmark::after, .checkbox-container.dark:not(.disabled):active .checkbox .checkmark::before, .checkbox-container.dark:not(.disabled):active .checkbox .checkmark::after {
  background-color: #00FFB9;
}
.checkbox-container.dark:not(.disabled):hover .checkbox-label, .checkbox-container.dark:not(.disabled):active .checkbox-label {
  color: #EBEBEE;
}
.checkbox-container.dark:not(.disabled) .checkbox.checked + .checkbox-label {
  color: #EBEBEE;
}
.checkbox-container.dark.disabled .checkbox-label {
  color: #66667E;
}
.checkbox-container.dark.disabled .checkbox {
  background-color: transparent;
  border-color: #66667E;
}
.checkbox-container.dark.disabled .checkbox .checkmark::before, .checkbox-container.dark.disabled .checkbox .checkmark::after {
  background-color: #66667E;
}

.content-container {
  width: 1350px;
  margin: 0 auto;
  background-color: inherit;
}
.content-container.header-content-container {
  width: 1385px;
}
@media (max-width: 1450px) {
  .content-container {
    width: calc(100% - 2 * (17px + (100% * (30 / 1410))));
    padding: 0 calc(17px + (100% * (30 / 1410)));
  }
  .content-container.header-content-container {
    width: calc(100% - 2 * (0px + (100% * (30 / 1410))));
    padding: 0 calc(0px + (100% * (30 / 1410)));
  }
}

.date-input .date-input-field {
  position: relative;
  min-width: 205px;
  box-sizing: border-box;
  padding: 16px 14px 6px;
  color: #4C4C68;
  background-color: #EBF7F8;
  border-bottom: 1px solid #4C4C68;
  outline: none;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: color 0.3s ease-in-out, background 0.3s ease-in-out, border 0.3s ease-in-out;
}
.date-input .date-input-field .date-input-label {
  position: absolute;
  top: 2px;
  bottom: 2px;
  display: flex;
  align-items: center;
  font-size: 16px;
  user-select: none;
  pointer-events: none;
  transition: color 0.3s ease-in-out, font-size 0.15s ease-in-out, bottom 0.15s ease-in-out;
}
.date-input .date-input-field .date-input-label.filled {
  bottom: 24px;
  font-size: 10px;
}
.date-input .date-input-field .date-input-label.required::before {
  position: absolute;
  content: "*";
  right: calc(100% + 2px);
}
.date-input .date-input-field .date-input-value-container .date-input-value {
  display: inline-block;
  border-radius: 1px;
  outline: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.date-input .date-input-field:focus-within .date-input-value-container .date-input-value.active {
  color: white;
  background-color: #00D7A0;
}
.date-input .date-input-field:hover, .date-input .date-input-field:focus-within {
  color: #000028;
  background-color: #D1FFF2;
  border-color: #00D7A0;
}
.date-input .date-input-field:hover .date-input-label, .date-input .date-input-field:focus-within .date-input-label {
  color: #000028;
}
.date-input .date-input-message {
  margin: 3px 14px 0;
  font-size: 10px;
  color: #000028;
  user-select: none;
}
.date-input.error:not(.disabled) .date-input-field:not(:focus-within) {
  color: #000028;
  background-color: #FCCCD7;
  border-color: #FF2640;
}
.date-input.error:not(.disabled) .date-input-field:not(:focus-within) .date-input-label {
  color: #FF2640 !important;
}
.date-input.error:not(.disabled) .date-input-field:not(:focus-within) .date-input-value-container .date-input-value:not(.active):hover {
  color: white;
  background-color: #FF2640;
}
.date-input.error:not(.disabled) .date-input-message {
  color: #FF2640;
}
.date-input:not(.disabled) .date-input-field .date-input-value-container .date-input-value:not(.active):hover {
  background-color: #62EEC7;
}
.date-input.disabled .date-input-field {
  color: #7D8099;
  background-color: #EBF7F8;
  border-color: transparent;
  cursor: not-allowed;
}
.date-input.disabled .date-input-field .date-input-label {
  color: #7D8099;
}
.date-input.dark .date-input-field {
  color: #EBEBEE;
  background-color: #00183B;
  border-color: #7D8099;
}
.date-input.dark .date-input-field .date-input-label {
  color: #7D8099;
}
.date-input.dark .date-input-field:focus-within .date-input-value-container .date-input-value.active {
  color: #000028;
  background-color: #00FFB9;
}
.date-input.dark .date-input-field:hover, .date-input.dark .date-input-field:focus-within {
  color: #EBEBEE;
  background-color: #001F39;
  border-color: #00FFB9;
}
.date-input.dark .date-input-field:hover .date-input-label, .date-input.dark .date-input-field:focus-within .date-input-label {
  color: #00FFB9;
}
.date-input.dark .date-input-message {
  color: #EBEBEE;
}
.date-input.dark.error:not(.disabled) .date-input-field:not(:focus-within) {
  color: #EBEBEE;
  background-color: #331131;
  border-color: #FF5454;
}
.date-input.dark.error:not(.disabled) .date-input-field:not(:focus-within) .date-input-label {
  color: #FF5454 !important;
}
.date-input.dark.error:not(.disabled) .date-input-field:not(:focus-within) .date-input-value-container .date-input-value:not(.active):hover {
  background-color: #FF5454;
}
.date-input.dark.error:not(.disabled) .date-input-message {
  color: #7D8099;
}
.date-input.dark:not(.disabled) .date-input-field .date-input-value-container .date-input-value:not(.active):hover {
  color: white;
  background-color: #005159;
}
.date-input.dark.disabled .date-input-field, .date-input.dark.disabled .date-input-field:hover {
  color: #66667E;
  background-color: #00183B;
  border-color: transparent;
}
.date-input.dark.disabled .date-input-field .date-input-label, .date-input.dark.disabled .date-input-field:hover .date-input-label {
  color: #66667E;
}

.file-upload {
  align-items: center;
  background-color: #EBF7F8;
  border-bottom: 1px solid #4C4C68;
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-width: 205px;
  padding: 67px 14px 41px;
  transition: border 0.3s ease-in-out;
}
.file-upload .file-upload-label {
  align-items: center;
  color: #000028;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 700;
  gap: 8px;
  line-height: 24px;
}
.file-upload .file-upload-label svg {
  fill: #000028;
  width: 40px;
}
.file-upload input[type=file] {
  display: none;
}
.file-upload.highlighted:not(:focus-within):not(:hover) {
  border-color: #007993;
}
.file-upload.dark {
  background-color: #00183B;
  border-color: #7D8099;
  color: #EBEBEE;
}
.file-upload.dark .file-upload-label {
  color: white;
}
.file-upload.dark .file-upload-label svg {
  fill: white;
}
.file-upload.dark.highlighted:not(:focus-within):not(:hover) {
  border-color: #00CCCC;
}
.file-upload .error-message {
  color: #FF2640;
}

.flex {
  display: flex;
  background-color: inherit;
}
.flex.alignment-baseline {
  align-items: baseline;
}
.flex.alignment-center {
  align-items: center;
}
.flex.alignment-end {
  align-items: end;
}
.flex.alignment-start {
  align-items: start;
}
.flex.alignment-stretch {
  align-items: stretch;
}
.flex.direction-column {
  height: 100%;
  flex-direction: column;
}
.flex.direction-column-reverse {
  height: 100%;
  flex-direction: column-reverse;
}
.flex.direction-row {
  flex-direction: row;
}
.flex.direction-row-reverse {
  flex-direction: row-reverse;
}
.flex.justification-center {
  justify-content: center;
}
.flex.justification-flex-end {
  justify-content: flex-end;
}
.flex.justification-flex-start {
  justify-content: flex-start;
}
.flex.justification-space-around {
  justify-content: space-around;
}
.flex.justification-space-evenly {
  justify-content: space-evenly;
}
.flex.justification-space-between {
  justify-content: space-between;
}
.flex.wrap-no-wrap {
  flex-wrap: nowrap;
}
.flex.wrap-wrap {
  flex-wrap: wrap;
}
.flex.wrap-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.grid-container {
  display: grid;
  background-color: inherit;
}
.grid-container.grid-columns-1 {
  grid-template: 1fr/repeat(1, minmax(0, 1fr));
}
.grid-container.grid-columns-2 {
  grid-template: 1fr/repeat(2, minmax(0, 1fr));
}
.grid-container.grid-columns-3 {
  grid-template: 1fr/repeat(3, minmax(0, 1fr));
}
.grid-container.grid-columns-4 {
  grid-template: 1fr/repeat(4, minmax(0, 1fr));
}
.grid-container.grid-columns-5 {
  grid-template: 1fr/repeat(5, minmax(0, 1fr));
}
.grid-container.grid-columns-6 {
  grid-template: 1fr/repeat(6, minmax(0, 1fr));
}
.grid-container.grid-columns-7 {
  grid-template: 1fr/repeat(7, minmax(0, 1fr));
}
.grid-container.grid-columns-8 {
  grid-template: 1fr/repeat(8, minmax(0, 1fr));
}
.grid-container.grid-columns-9 {
  grid-template: 1fr/repeat(9, minmax(0, 1fr));
}
.grid-container.grid-columns-10 {
  grid-template: 1fr/repeat(10, minmax(0, 1fr));
}
.grid-container.grid-columns-11 {
  grid-template: 1fr/repeat(11, minmax(0, 1fr));
}
.grid-container.grid-columns-12 {
  grid-template: 1fr/repeat(12, minmax(0, 1fr));
}
.grid-container.grid-columns-13 {
  grid-template: 1fr/repeat(13, minmax(0, 1fr));
}
.grid-container.grid-columns-14 {
  grid-template: 1fr/repeat(14, minmax(0, 1fr));
}
.grid-container.grid-columns-15 {
  grid-template: 1fr/repeat(15, minmax(0, 1fr));
}
.grid-container.grid-columns-16 {
  grid-template: 1fr/repeat(16, minmax(0, 1fr));
}
.grid-container.grid-columns-17 {
  grid-template: 1fr/repeat(17, minmax(0, 1fr));
}
.grid-container.grid-columns-18 {
  grid-template: 1fr/repeat(18, minmax(0, 1fr));
}
.grid-container.grid-columns-19 {
  grid-template: 1fr/repeat(19, minmax(0, 1fr));
}
.grid-container.grid-columns-20 {
  grid-template: 1fr/repeat(20, minmax(0, 1fr));
}
@media (max-width: 992px) {
  .grid-container.grid-columns-992-1 {
    grid-template: 1fr/repeat(1, minmax(0, 1fr));
  }
  .grid-container.grid-columns-992-2 {
    grid-template: 1fr/repeat(2, minmax(0, 1fr));
  }
  .grid-container.grid-columns-992-3 {
    grid-template: 1fr/repeat(3, minmax(0, 1fr));
  }
  .grid-container.grid-columns-992-4 {
    grid-template: 1fr/repeat(4, minmax(0, 1fr));
  }
  .grid-container.grid-columns-992-5 {
    grid-template: 1fr/repeat(5, minmax(0, 1fr));
  }
  .grid-container.grid-columns-992-6 {
    grid-template: 1fr/repeat(6, minmax(0, 1fr));
  }
  .grid-container.grid-columns-992-7 {
    grid-template: 1fr/repeat(7, minmax(0, 1fr));
  }
  .grid-container.grid-columns-992-8 {
    grid-template: 1fr/repeat(8, minmax(0, 1fr));
  }
  .grid-container.grid-columns-992-9 {
    grid-template: 1fr/repeat(9, minmax(0, 1fr));
  }
  .grid-container.grid-columns-992-10 {
    grid-template: 1fr/repeat(10, minmax(0, 1fr));
  }
  .grid-container.grid-columns-992-11 {
    grid-template: 1fr/repeat(11, minmax(0, 1fr));
  }
  .grid-container.grid-columns-992-12 {
    grid-template: 1fr/repeat(12, minmax(0, 1fr));
  }
  .grid-container.grid-columns-992-13 {
    grid-template: 1fr/repeat(13, minmax(0, 1fr));
  }
  .grid-container.grid-columns-992-14 {
    grid-template: 1fr/repeat(14, minmax(0, 1fr));
  }
  .grid-container.grid-columns-992-15 {
    grid-template: 1fr/repeat(15, minmax(0, 1fr));
  }
  .grid-container.grid-columns-992-16 {
    grid-template: 1fr/repeat(16, minmax(0, 1fr));
  }
  .grid-container.grid-columns-992-17 {
    grid-template: 1fr/repeat(17, minmax(0, 1fr));
  }
  .grid-container.grid-columns-992-18 {
    grid-template: 1fr/repeat(18, minmax(0, 1fr));
  }
  .grid-container.grid-columns-992-19 {
    grid-template: 1fr/repeat(19, minmax(0, 1fr));
  }
  .grid-container.grid-columns-992-20 {
    grid-template: 1fr/repeat(20, minmax(0, 1fr));
  }
}
@media (max-width: 768px) {
  .grid-container.grid-columns-768-1 {
    grid-template: 1fr/repeat(1, minmax(0, 1fr));
  }
  .grid-container.grid-columns-768-2 {
    grid-template: 1fr/repeat(2, minmax(0, 1fr));
  }
  .grid-container.grid-columns-768-3 {
    grid-template: 1fr/repeat(3, minmax(0, 1fr));
  }
  .grid-container.grid-columns-768-4 {
    grid-template: 1fr/repeat(4, minmax(0, 1fr));
  }
  .grid-container.grid-columns-768-5 {
    grid-template: 1fr/repeat(5, minmax(0, 1fr));
  }
  .grid-container.grid-columns-768-6 {
    grid-template: 1fr/repeat(6, minmax(0, 1fr));
  }
  .grid-container.grid-columns-768-7 {
    grid-template: 1fr/repeat(7, minmax(0, 1fr));
  }
  .grid-container.grid-columns-768-8 {
    grid-template: 1fr/repeat(8, minmax(0, 1fr));
  }
  .grid-container.grid-columns-768-9 {
    grid-template: 1fr/repeat(9, minmax(0, 1fr));
  }
  .grid-container.grid-columns-768-10 {
    grid-template: 1fr/repeat(10, minmax(0, 1fr));
  }
  .grid-container.grid-columns-768-11 {
    grid-template: 1fr/repeat(11, minmax(0, 1fr));
  }
  .grid-container.grid-columns-768-12 {
    grid-template: 1fr/repeat(12, minmax(0, 1fr));
  }
  .grid-container.grid-columns-768-13 {
    grid-template: 1fr/repeat(13, minmax(0, 1fr));
  }
  .grid-container.grid-columns-768-14 {
    grid-template: 1fr/repeat(14, minmax(0, 1fr));
  }
  .grid-container.grid-columns-768-15 {
    grid-template: 1fr/repeat(15, minmax(0, 1fr));
  }
  .grid-container.grid-columns-768-16 {
    grid-template: 1fr/repeat(16, minmax(0, 1fr));
  }
  .grid-container.grid-columns-768-17 {
    grid-template: 1fr/repeat(17, minmax(0, 1fr));
  }
  .grid-container.grid-columns-768-18 {
    grid-template: 1fr/repeat(18, minmax(0, 1fr));
  }
  .grid-container.grid-columns-768-19 {
    grid-template: 1fr/repeat(19, minmax(0, 1fr));
  }
  .grid-container.grid-columns-768-20 {
    grid-template: 1fr/repeat(20, minmax(0, 1fr));
  }
}
@media (max-width: 600px) {
  .grid-container.grid-columns-600-1 {
    grid-template: 1fr/repeat(1, minmax(0, 1fr));
  }
  .grid-container.grid-columns-600-2 {
    grid-template: 1fr/repeat(2, minmax(0, 1fr));
  }
  .grid-container.grid-columns-600-3 {
    grid-template: 1fr/repeat(3, minmax(0, 1fr));
  }
  .grid-container.grid-columns-600-4 {
    grid-template: 1fr/repeat(4, minmax(0, 1fr));
  }
  .grid-container.grid-columns-600-5 {
    grid-template: 1fr/repeat(5, minmax(0, 1fr));
  }
  .grid-container.grid-columns-600-6 {
    grid-template: 1fr/repeat(6, minmax(0, 1fr));
  }
  .grid-container.grid-columns-600-7 {
    grid-template: 1fr/repeat(7, minmax(0, 1fr));
  }
  .grid-container.grid-columns-600-8 {
    grid-template: 1fr/repeat(8, minmax(0, 1fr));
  }
  .grid-container.grid-columns-600-9 {
    grid-template: 1fr/repeat(9, minmax(0, 1fr));
  }
  .grid-container.grid-columns-600-10 {
    grid-template: 1fr/repeat(10, minmax(0, 1fr));
  }
  .grid-container.grid-columns-600-11 {
    grid-template: 1fr/repeat(11, minmax(0, 1fr));
  }
  .grid-container.grid-columns-600-12 {
    grid-template: 1fr/repeat(12, minmax(0, 1fr));
  }
  .grid-container.grid-columns-600-13 {
    grid-template: 1fr/repeat(13, minmax(0, 1fr));
  }
  .grid-container.grid-columns-600-14 {
    grid-template: 1fr/repeat(14, minmax(0, 1fr));
  }
  .grid-container.grid-columns-600-15 {
    grid-template: 1fr/repeat(15, minmax(0, 1fr));
  }
  .grid-container.grid-columns-600-16 {
    grid-template: 1fr/repeat(16, minmax(0, 1fr));
  }
  .grid-container.grid-columns-600-17 {
    grid-template: 1fr/repeat(17, minmax(0, 1fr));
  }
  .grid-container.grid-columns-600-18 {
    grid-template: 1fr/repeat(18, minmax(0, 1fr));
  }
  .grid-container.grid-columns-600-19 {
    grid-template: 1fr/repeat(19, minmax(0, 1fr));
  }
  .grid-container.grid-columns-600-20 {
    grid-template: 1fr/repeat(20, minmax(0, 1fr));
  }
}
@media (max-width: 480px) {
  .grid-container.grid-columns-480-1 {
    grid-template: 1fr/repeat(1, minmax(0, 1fr));
  }
  .grid-container.grid-columns-480-2 {
    grid-template: 1fr/repeat(2, minmax(0, 1fr));
  }
  .grid-container.grid-columns-480-3 {
    grid-template: 1fr/repeat(3, minmax(0, 1fr));
  }
  .grid-container.grid-columns-480-4 {
    grid-template: 1fr/repeat(4, minmax(0, 1fr));
  }
  .grid-container.grid-columns-480-5 {
    grid-template: 1fr/repeat(5, minmax(0, 1fr));
  }
  .grid-container.grid-columns-480-6 {
    grid-template: 1fr/repeat(6, minmax(0, 1fr));
  }
  .grid-container.grid-columns-480-7 {
    grid-template: 1fr/repeat(7, minmax(0, 1fr));
  }
  .grid-container.grid-columns-480-8 {
    grid-template: 1fr/repeat(8, minmax(0, 1fr));
  }
  .grid-container.grid-columns-480-9 {
    grid-template: 1fr/repeat(9, minmax(0, 1fr));
  }
  .grid-container.grid-columns-480-10 {
    grid-template: 1fr/repeat(10, minmax(0, 1fr));
  }
  .grid-container.grid-columns-480-11 {
    grid-template: 1fr/repeat(11, minmax(0, 1fr));
  }
  .grid-container.grid-columns-480-12 {
    grid-template: 1fr/repeat(12, minmax(0, 1fr));
  }
  .grid-container.grid-columns-480-13 {
    grid-template: 1fr/repeat(13, minmax(0, 1fr));
  }
  .grid-container.grid-columns-480-14 {
    grid-template: 1fr/repeat(14, minmax(0, 1fr));
  }
  .grid-container.grid-columns-480-15 {
    grid-template: 1fr/repeat(15, minmax(0, 1fr));
  }
  .grid-container.grid-columns-480-16 {
    grid-template: 1fr/repeat(16, minmax(0, 1fr));
  }
  .grid-container.grid-columns-480-17 {
    grid-template: 1fr/repeat(17, minmax(0, 1fr));
  }
  .grid-container.grid-columns-480-18 {
    grid-template: 1fr/repeat(18, minmax(0, 1fr));
  }
  .grid-container.grid-columns-480-19 {
    grid-template: 1fr/repeat(19, minmax(0, 1fr));
  }
  .grid-container.grid-columns-480-20 {
    grid-template: 1fr/repeat(20, minmax(0, 1fr));
  }
}

.headline {
  max-width: 600px;
  display: flex;
}
.headline .headline-bar {
  width: 6px;
  margin: 11px 20px 11px 0;
  flex-shrink: 0;
  background: linear-gradient(#00FFB9, #00E6DC);
  animation: headline-bar-appear 0.6s ease-in-out forwards;
}
@keyframes headline-bar-appear {
  0% {
    opacity: 0;
    transform: translateY(-100px) scaleY(0);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scaleY(1);
  }
}
.headline .headline-bar.with-label {
  margin-top: 4px;
}
.headline .headline-text {
  color: white;
}
.headline .headline-text .headline-label {
  padding-bottom: 15px;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  animation: headline-label-appear 0.6s ease-in-out forwards;
}
@keyframes headline-label-appear {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.headline .headline-text .headline-line {
  font-size: 45px;
  font-weight: 900;
  animation: headline-line-appear 0.6s ease-in-out forwards;
}
@keyframes headline-line-appear {
  0% {
    opacity: 0;
    transform: translateY(60px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.icon-button {
  color: white;
  cursor: pointer;
  display: inline-flex;
  outline: none;
  position: relative;
  user-select: none;
  z-index: 1;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.icon-button::before {
  border-radius: 4px;
  bottom: -6px;
  content: "";
  left: -6px;
  pointer-events: none;
  position: absolute;
  right: -6px;
  top: -6px;
  transition: background 0.3s ease-in-out;
  z-index: -1;
}
.icon-button svg {
  fill: #000028;
  transition: fill 0.3s ease-in-out;
}
.icon-button.icon-button-16 svg {
  width: 16px;
}
.icon-button.icon-button-20 svg {
  width: 20px;
}
.icon-button.icon-button-24 svg {
  width: 24px;
}
.icon-button.icon-button-40::before {
  bottom: -2px;
  left: -2px;
  right: -2px;
  top: -2px;
}
.icon-button.icon-button-40 svg {
  width: 40px;
}
.icon-button.secondary svg {
  fill: #7D8099;
}
.icon-button.transparent::before {
  opacity: 0.4;
}
.icon-button.disabled {
  cursor: not-allowed;
}
.icon-button.disabled svg {
  fill: #B3B3BE;
}
.icon-button:not(.disabled):hover.icon-button-layer-1::before, .icon-button:not(.disabled):active.icon-button-layer-1::before, .icon-button.open.icon-button-layer-1::before {
  background: #F3F3F0;
}
.icon-button:not(.disabled):hover.icon-button-layer-2::before, .icon-button:not(.disabled):active.icon-button-layer-2::before, .icon-button.open.icon-button-layer-2::before {
  background: #DFDFD9;
}
.icon-button:not(.disabled):hover.icon-button-layer-3::before, .icon-button:not(.disabled):active.icon-button-layer-3::before, .icon-button.open.icon-button-layer-3::before {
  background: #C5C5B8;
}
.icon-button.open svg {
  fill: #005159;
}
.icon-button:not(.disabled):focus-visible::before {
  outline: 1px solid #36ABFF;
  outline-offset: -1px;
}
.icon-button.dark svg {
  fill: white;
}
.icon-button.dark.secondary svg {
  fill: #66667E;
}
.icon-button.dark.disabled svg {
  fill: #737389;
}
.icon-button.dark:not(.disabled):hover.icon-button-layer-1::before, .icon-button.dark:not(.disabled):active.icon-button-layer-1::before, .icon-button.dark.open.icon-button-layer-1::before {
  background: #23233C;
}
.icon-button.dark:not(.disabled):hover.icon-button-layer-2::before, .icon-button.dark:not(.disabled):active.icon-button-layer-2::before, .icon-button.dark.open.icon-button-layer-2::before {
  background: #333353;
}
.icon-button.dark:not(.disabled):hover.icon-button-layer-3::before, .icon-button.dark:not(.disabled):active.icon-button-layer-3::before, .icon-button.dark.open.icon-button-layer-3::before {
  background: #4C4C68;
}
.icon-button.dark.open svg {
  fill: #00FFB9;
}

.key-visual {
  background-size: cover;
  background-position: center;
}

.link {
  display: inline-flex;
  gap: 8px;
  align-items: center;
  font-weight: 700;
  color: #007993;
  text-decoration: underline;
  text-decoration-color: transparent;
  text-underline-position: under;
  text-underline-offset: 1px;
  outline: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: color 0.3s ease-in-out, text-decoration-color 0.3s ease-in-out;
}
.link svg {
  width: 20px;
  flex-shrink: 0;
  fill: #007993;
  transition: fill 0.3s ease-in-out;
}
.link:hover, .link:active {
  color: #00646E;
  text-decoration-color: #00646E;
}
.link:hover svg, .link:active svg {
  fill: #00646E;
}
.link:focus-visible {
  outline: 1px solid #36ABFF;
  outline-offset: 2px;
}
.link.dark {
  color: #00CCCC;
}
.link.dark svg {
  fill: #00CCCC;
}
.link.dark:hover, .link.dark:active {
  color: #00FFB9;
  text-decoration-color: #00FFB9;
}
.link.dark:hover svg, .link.dark:active svg {
  fill: #00FFB9;
}

.loader-container {
  position: relative;
  background-color: inherit;
}
.loader-container .loading-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(162, 178, 186, 0.3764705882);
  visibility: hidden;
}
.loader-container .loading-overlay.loading-animation.loading {
  animation: appear 0.3s ease-in-out forwards;
}
@keyframes appear {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
.loader-container .loading-overlay.loading-animation:not(.loading) {
  animation: disappear 0.3s ease-in-out forwards;
}
@keyframes disappear {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
.loader-container .loading-overlay.transparent {
  background-color: transparent;
}
.loader-container .loading-overlay .loader-icon {
  width: 50px;
  height: 50px;
  animation: rotate 1s linear infinite;
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
.loader-container .loading-overlay .loader-icon circle {
  fill: none;
  stroke: #000028;
  stroke-width: 4px;
  stroke-dasharray: 50, 42;
  stroke-dashoffset: 8;
}
.loader-container.dark .loading-overlay {
  background-color: rgba(31, 38, 41, 0.5019607843);
}
.loader-container.dark .loading-overlay .loader-icon circle {
  stroke: white;
}

.menu-icon {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.menu-icon .menu-bar {
  height: 2px;
  width: 100%;
  background-color: #000028;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  will-change: opacity, transform;
}
.menu-icon .menu-bar:nth-child(2) {
  width: 66%;
}
.menu-icon.open .menu-bar:first-child {
  transform: translate(0px, 6px) rotate(45deg);
}
.menu-icon.open .menu-bar:nth-child(2) {
  opacity: 0;
}
.menu-icon.open .menu-bar:last-child {
  transform: translate(0px, -6px) rotate(-45deg);
}
.menu-icon:focus-visible {
  outline: 1px solid #36ABFF;
  outline-offset: 1px;
}

.modal-background {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10000;
  padding-top: 149px;
  align-items: center;
  justify-content: center;
  background-color: rgba(45, 55, 60, 0.6);
  transition: padding 0.5s ease-in-out;
}
.modal-background.clickable {
  cursor: pointer;
}
.modal-background .modal {
  --offset-x: 0px;
  --offset-y: 0px;
  position: relative;
  width: 600px;
  max-height: calc(100% - 100px);
  margin: 0 10px;
  padding: 25px;
  background-color: white;
  border-radius: 1px;
  outline: none;
  box-shadow: 0px 0px 50px rgba(33, 40, 44, 0.6);
  cursor: default;
  transform: translate(var(--offset-x), var(--offset-y));
}
.modal-background .modal.full-width {
  width: 1350px;
}
.modal-background .modal:focus-visible {
  outline: 1px solid #36ABFF;
  outline-offset: 2px;
}
.modal-background .modal .move-handle {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 20px;
  cursor: move;
}
.modal-background.opening {
  animation: modal-background-appear 0.2s ease-in-out forwards;
}
@keyframes modal-background-appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.modal-background.opening .modal {
  animation: modal-appear 0.2s ease-in-out forwards;
}
@keyframes modal-appear {
  0% {
    transform: scale(1.1) translate(var(--offset-x), var(--offset-y));
  }
  100% {
    transform: scale(1) translate(var(--offset-x), var(--offset-y));
  }
}
.modal-background.closing {
  animation: modal-background-disappear 0.2s ease-in-out forwards;
}
@keyframes modal-background-disappear {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.modal-background.closing .modal {
  animation: modal-disappear 0.2s ease-in-out forwards;
}
@keyframes modal-disappear {
  0% {
    transform: scale(1) translate(var(--offset-x), var(--offset-y));
  }
  100% {
    transform: scale(1.1) translate(var(--offset-x), var(--offset-y));
  }
}
.modal-background.closed {
  display: none;
}
.modal-background.dark {
  background-color: rgba(31, 38, 41, 0.8);
}
.modal-background.dark .modal {
  color: white;
  background-color: #000028;
  box-shadow: 0px 0px 50px rgba(11, 13, 15, 0.8);
}
.modal-background.dark .modal svg {
  fill: white;
}

.multiselect {
  position: relative;
  min-width: 205px;
  color: #4C4C68;
  background-color: #EBF7F8;
  border-bottom: 1px solid #4C4C68;
  outline: none;
  user-select: none;
  cursor: pointer;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border 0.3s ease-in-out;
}
.multiselect .select-opener {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 5px;
  padding: 0 5px 0 14px;
}
.multiselect .select-opener.with-reset {
  grid-template-columns: 1fr auto auto;
}
.multiselect .select-opener.filled .select-label {
  bottom: 24px;
  font-size: 10px;
}
.multiselect .select-opener.filled .select-value {
  opacity: 1;
}
.multiselect .select-opener .select-label {
  position: absolute;
  top: 2px;
  bottom: 2px;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #4C4C68;
  user-select: none;
  pointer-events: none;
  transition: color 0.3s ease-in-out, font-size 0.15s ease-in-out, bottom 0.15s ease-in-out;
}
.multiselect .select-opener .select-label.required::before {
  position: absolute;
  content: "*";
  right: calc(100% + 2px);
}
.multiselect .select-opener .select-value {
  padding: 16px 0 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.multiselect .select-opener .select-reset {
  display: flex;
  align-items: center;
  margin: 16px 0 6px;
  border-radius: 100%;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}
.multiselect .select-opener .select-reset:hover {
  background-color: #62EEC7;
}
.multiselect .select-opener .select-reset:hover svg {
  color: #000028;
}
.multiselect .select-opener .select-reset svg {
  width: 15px;
  padding: 0 2px;
  fill: #4C4C68;
  transition: fill 0.3s ease-in-out;
}
.multiselect .select-opener .select-arrow {
  display: flex;
  align-items: center;
}
.multiselect .select-opener .select-arrow svg {
  width: 20px;
  fill: #4C4C68;
  transition: fill 0.3s ease-in-out, transform 0.5s ease-in-out;
}
.multiselect .select-values {
  position: absolute;
  top: calc(100% + 1px);
  z-index: 2;
  max-height: 0;
  width: 100%;
  overflow-y: auto;
  background-color: #EBF7F8;
  transition: max-height 0.5s ease-in-out, background-color 0.5s ease-in-out;
}
.multiselect .select-values::-webkit-scrollbar {
  width: 6px;
}
.multiselect .select-values::-webkit-scrollbar-track {
  background-color: inherit;
}
.multiselect .select-values::-webkit-scrollbar-thumb {
  background-color: #D9EFF1;
}
.multiselect .select-values .select-value {
  position: relative;
  z-index: 1;
  display: grid;
  grid-template-columns: 16px 1fr;
  gap: 5px;
  align-items: baseline;
  padding: 5px 14px;
  user-select: none;
  cursor: pointer;
}
.multiselect .select-values .select-value .checkmark {
  height: 13px;
  width: 8px;
  transform: translateX(5px) rotate(40deg);
}
.multiselect .select-values .select-value .checkmark::before, .multiselect .select-values .select-value .checkmark::after {
  position: absolute;
  content: "";
  background-color: #000028;
}
.multiselect .select-values .select-value .checkmark::before {
  width: 0;
  height: 2px;
  top: calc(100% - 2px);
  left: 0;
  transition: width 0.15s ease-in-out 0.15s, background-color 0.3s ease-in-out 0s;
}
.multiselect .select-values .select-value .checkmark::after {
  width: 2px;
  height: 0;
  bottom: 0;
  left: calc(100% - 2px);
  transition: height 0.15s ease-in-out 0s, background-color 0.3s ease-in-out 0s;
}
.multiselect .select-values .select-value .checkmark.checked::before {
  width: 100%;
  transition: width 0.15s ease-in-out 0s, background-color 0.3s ease-in-out 0s;
}
.multiselect .select-values .select-value .checkmark.checked::after {
  height: 100%;
  transition: height 0.15s ease-in-out 0.15s, background-color 0.3s ease-in-out 0s;
}
.multiselect .select-values .select-value .select-value-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.multiselect.open .select-values.select-values-1 {
  max-height: 29px;
}
.multiselect.open .select-values.select-values-2 {
  max-height: 58px;
}
.multiselect.open .select-values.select-values-3 {
  max-height: 87px;
}
.multiselect.open .select-values.select-values-4 {
  max-height: 116px;
}
.multiselect.open .select-values.select-values-5 {
  max-height: 145px;
}
.multiselect.open .select-opener .select-arrow svg {
  transform: rotate(-180deg);
}
.multiselect.open, .multiselect:hover {
  color: #000028;
  background-color: #D1FFF2;
  border-color: #00D7A0;
}
.multiselect.open .select-opener .select-label, .multiselect:hover .select-opener .select-label {
  color: #000028;
}
.multiselect.open .select-opener .select-reset svg, .multiselect:hover .select-opener .select-reset svg {
  fill: #000028;
}
.multiselect.open .select-opener .select-arrow svg, .multiselect:hover .select-opener .select-arrow svg {
  fill: #000028;
}
.multiselect.open .select-values, .multiselect:hover .select-values {
  background-color: #D1FFF2;
}
.multiselect.open .select-values::-webkit-scrollbar-thumb, .multiselect:hover .select-values::-webkit-scrollbar-thumb {
  background-color: #91F3D7;
}
.multiselect.open .select-values::-webkit-scrollbar-thumb:hover, .multiselect:hover .select-values::-webkit-scrollbar-thumb:hover {
  background-color: #B0F6E3;
}
.multiselect.open .select-values .select-value.selected, .multiselect:hover .select-values .select-value.selected {
  background-color: #62EEC7;
}
.multiselect.disabled {
  color: #7D8099;
  background-color: #EBF7F8;
  border-color: transparent;
  cursor: not-allowed;
}
.multiselect.disabled .select-opener .select-label {
  color: #7D8099;
}
.multiselect.disabled .select-opener .select-arrow svg {
  fill: #7D8099;
}
.multiselect.error:not(.disaebled):not(:focus-within) {
  color: #000028;
  background-color: #FCCCD7;
  border-color: #FF2640;
}
.multiselect.error:not(.disaebled):not(:focus-within) .select-opener .select-label {
  color: #FF2640;
}
.multiselect.error:not(.disaebled):not(:focus-within) .select-opener .select-reset:hover {
  background-color: #FFA8B3;
}
.multiselect.error:not(.disaebled):not(:focus-within) .select-opener .select-reset:hover svg {
  color: #000028;
}
.multiselect.error:not(.disaebled):not(:focus-within) .select-opener .select-reset svg {
  fill: #000028;
}
.multiselect.error:not(.disaebled):not(:focus-within) .select-opener .select-arrow svg {
  fill: #000028;
}
.multiselect.error:not(.disaebled):not(:focus-within) .select-values {
  background-color: #FCCCD7;
}
.multiselect.error:not(.disaebled):not(:focus-within) .select-values::-webkit-scrollbar-thumb {
  background-color: #FFA8B3;
}
.multiselect.error:not(.disaebled):not(:focus-within) .select-values .select-value.selected {
  background-color: inherit !important;
}
.multiselect.dark {
  color: #EBEBEE;
  background-color: #00183B;
  border-color: #7D8099;
}
.multiselect.dark .select-opener .select-label {
  color: #7D8099;
}
.multiselect.dark .select-opener .select-reset:hover {
  background-color: #66667E;
}
.multiselect.dark .select-opener .select-reset:hover svg {
  color: #EBEBEE;
}
.multiselect.dark .select-opener .select-reset svg {
  fill: #EBEBEE;
}
.multiselect.dark .select-opener .select-arrow svg {
  fill: #7D8099;
}
.multiselect.dark .select-values {
  background-color: #00183B;
}
.multiselect.dark .select-values::-webkit-scrollbar-thumb {
  background-color: #333353;
}
.multiselect.dark .select-values::-webkit-scrollbar-thumb:hover {
  background-color: #66667E;
}
.multiselect.dark .select-values .checkmark::before, .multiselect.dark .select-values .checkmark::after {
  background-color: #EBEBEE;
}
.multiselect.dark.open, .multiselect.dark:hover {
  color: #EBEBEE;
  background-color: #001F39;
  border-color: #00FFB9;
}
.multiselect.dark.open .select-opener .select-label, .multiselect.dark:hover .select-opener .select-label {
  color: #EBEBEE;
}
.multiselect.dark.open .select-opener .select-reset svg, .multiselect.dark:hover .select-opener .select-reset svg {
  fill: #EBEBEE;
}
.multiselect.dark.open .select-opener .select-arrow svg, .multiselect.dark:hover .select-opener .select-arrow svg {
  fill: #00FFB9;
}
.multiselect.dark.open .select-opener.filled .select-label, .multiselect.dark:hover .select-opener.filled .select-label {
  color: #00FFB9;
}
.multiselect.dark.open .select-values, .multiselect.dark:hover .select-values {
  background-color: #001F39;
}
.multiselect.dark.open .select-values .select-value.selected, .multiselect.dark:hover .select-values .select-value.selected {
  background-color: #005159;
}
.multiselect.dark.disabled, .multiselect.dark.disabled:hover {
  color: #66667E;
  background-color: #00183B;
  border-color: transparent;
}
.multiselect.dark.disabled .select-opener .select-label, .multiselect.dark.disabled:hover .select-opener .select-label {
  color: #66667E;
}
.multiselect.dark.disabled .select-opener .select-arrow svg, .multiselect.dark.disabled:hover .select-opener .select-arrow svg {
  fill: #66667E;
}
.multiselect.dark.error:not(.disaebled):not(:focus-within) {
  color: #EBEBEE;
  background-color: #331131;
  border-color: #FF5454;
}
.multiselect.dark.error:not(.disaebled):not(:focus-within) .select-opener .select-label {
  color: #FF5454;
}
.multiselect.dark.error:not(.disaebled):not(:focus-within) .select-opener .select-reset:hover {
  background-color: #4D1A4A;
}
.multiselect.dark.error:not(.disaebled):not(:focus-within) .select-opener .select-reset:hover svg {
  color: #EBEBEE;
}
.multiselect.dark.error:not(.disaebled):not(:focus-within) .select-opener .select-reset svg {
  fill: #EBEBEE;
}
.multiselect.dark.error:not(.disaebled):not(:focus-within) .select-opener .select-arrow svg {
  fill: #FF5454;
}
.multiselect.dark.error:not(.disaebled):not(:focus-within) .select-values {
  background-color: #331131;
}
.multiselect.dark.error:not(.disaebled):not(:focus-within) .select-values::-webkit-scrollbar-thumb {
  background-color: #4D1A4A;
}

.mutable-list {
  background-color: #EBF7F8;
  border-bottom: 1px solid #4C4C68;
  display: flex;
  flex-direction: column;
  height: 400px;
  padding: 0 11px 6px;
}
.mutable-list .label {
  color: #4C4C68;
  flex-shrink: 0;
  font-size: 10px;
  overflow: hidden;
  padding: 4px 3px;
  text-overflow: ellipsis;
  user-select: none;
  white-space: nowrap;
}
.mutable-list .items {
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 3px;
}
.mutable-list .items::-webkit-scrollbar {
  width: 6px;
}
.mutable-list .items::-webkit-scrollbar-track {
  background-color: inherit;
}
.mutable-list .items::-webkit-scrollbar-thumb {
  background-color: #D9EFF1;
}
.mutable-list .items::-webkit-scrollbar-thumb:hover {
  background-color: #C8E7EA;
}
.mutable-list .items .item {
  align-items: center;
  background-color: #EBF7F8;
  border: 1px solid #007993;
  border-radius: 17px;
  color: #007993;
  display: flex;
  gap: 8px;
  margin-top: 6px;
  padding: 6px 16px;
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, color 0.3s ease-in-out;
  user-select: none;
}
.mutable-list .items .item svg {
  fill: #007993;
  flex-shrink: 0;
  width: 20px;
}
.mutable-list .items .item .name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mutable-list .items .item:hover:not(.disabled), .mutable-list .items .item.dragging {
  background-color: #C2FFEE;
  border-color: #005159;
  color: #005159;
}
.mutable-list .items .item:hover:not(.disabled) svg, .mutable-list .items .item.dragging svg {
  fill: #005159;
}
.mutable-list .items .item:focus-visible {
  outline: #36ABFF solid 1px;
  outline-offset: 2px;
}
.mutable-list .items .item.disabled {
  border-color: #7D8099;
  color: #7D8099;
  cursor: not-allowed;
}
.mutable-list .items .item.disabled svg {
  fill: #7D8099;
}

.pagination {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.pagination .pagination-button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  padding: 5px;
  color: #007993;
  outline: none;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.pagination .pagination-button svg {
  width: 20px;
  fill: #007993;
}
.pagination .pagination-button.active {
  color: white;
  background-color: #005159;
  cursor: default;
}
.pagination .pagination-button.active svg {
  fill: white;
}
.pagination .pagination-button.active.disabled {
  background-color: #4C4C68;
  cursor: not-allowed;
}
.pagination .pagination-button:not(.active).disabled {
  color: #7D8099;
  cursor: not-allowed;
}
.pagination .pagination-button:not(.active).disabled svg {
  fill: #7D8099;
}
.pagination .pagination-button.divider, .pagination .pagination-button.divider.disabled {
  cursor: default;
}
.pagination .pagination-button:not(.active):not(.disabled):not(.divider):hover {
  color: #005159;
  background-color: #C5FFEF;
}
.pagination .pagination-button:not(.active):not(.disabled):not(.divider):hover svg {
  fill: #005159;
}
.pagination .pagination-button:not(.disabled):focus-visible {
  outline: 1px solid #36ABFF;
}
.pagination.dark .pagination-button {
  color: #00CCCC;
}
.pagination.dark .pagination-button svg {
  fill: #00CCCC;
}
.pagination.dark .pagination-button.active {
  color: #000028;
  background-color: #00CCCC;
}
.pagination.dark .pagination-button.active svg {
  fill: #000028;
}
.pagination.dark .pagination-button.active.disabled {
  background-color: #B3B3BE;
}
.pagination.dark .pagination-button:not(.active).disabled {
  color: #B3B3BE;
}
.pagination.dark .pagination-button:not(.active).disabled svg {
  fill: #B3B3BE;
}
.pagination.dark .pagination-button:not(.active):not(.disabled):not(.divider):hover {
  color: #000028;
  background-color: #C5FFEF;
}
.pagination.dark .pagination-button:not(.active):not(.disabled):not(.divider):hover svg {
  fill: #000028;
}

.radio-button-container {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 5px;
  outline: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.radio-button-container .radio-button {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  border: 2px solid #4C4C68;
  background-color: #E0F1F4;
  border-radius: 100%;
  outline: none;
  transition: background 0.3s ease-in-out, border 0.3s ease-in-out;
}
.radio-button-container .radio-button::before {
  content: "";
  height: 8px;
  width: 8px;
  border-radius: 100%;
  background-color: #4C4C68;
  transition: background 0.3s ease-in-out;
  transform: scale(0.05);
  animation: radio-button-close 0.2s ease-in forwards;
}
@keyframes radio-button-close {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
.radio-button-container .radio-button.selected::before {
  animation: radio-button-open 0.4s linear forwards;
}
@keyframes radio-button-open {
  0% {
    transform: scale(0);
  }
  40% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.radio-button-container .radio-button-label {
  font-size: 16px;
  color: #4C4C68;
  user-select: none;
  transition: color 0.3s ease-in-out;
}
.radio-button-container:not(.disabled):hover .radio-button, .radio-button-container:not(.disabled):active .radio-button {
  background-color: #C2FFEE;
  border-color: #005159;
}
.radio-button-container:not(.disabled):hover .radio-button::before, .radio-button-container:not(.disabled):active .radio-button::before {
  background-color: #005159;
}
.radio-button-container:not(.disabled):hover .radio-button-label, .radio-button-container:not(.disabled):active .radio-button-label {
  color: #000028;
}
.radio-button-container:not(:disabled):focus-visible .radio-button::after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  outline: 1px solid #36ABFF;
  outline-offset: 3px;
}
.radio-button-container:not(.disabled) .radio-button.selected + .radio-button-label {
  color: #000028;
}
.radio-button-container.disabled {
  cursor: not-allowed;
}
.radio-button-container.disabled .radio-button {
  background-color: transparent;
  border-color: #7D8099;
}
.radio-button-container.disabled .radio-button::before {
  background-color: #7D8099;
}
.radio-button-container.disabled .radio-button-label {
  color: #7D8099;
}
.radio-button-container.dark .radio-button {
  background-color: #262648;
  border-color: #B3B3BE;
}
.radio-button-container.dark .radio-button::before {
  background-color: #B3B3BE;
}
.radio-button-container.dark .radio-button-label {
  color: #B3B3BE;
}
.radio-button-container.dark:not(.disabled):hover .radio-button, .radio-button-container.dark:not(.disabled):active .radio-button {
  background-color: #001F39;
  border-color: #00FFB9;
}
.radio-button-container.dark:not(.disabled):hover .radio-button::before, .radio-button-container.dark:not(.disabled):active .radio-button::before {
  background-color: #00FFB9;
}
.radio-button-container.dark:not(.disabled):hover .radio-button-label, .radio-button-container.dark:not(.disabled):active .radio-button-label {
  color: #EBEBEE;
}
.radio-button-container.dark:not(.disabled) .radio-button.selected + .radio-button-label {
  color: #EBEBEE;
}
.radio-button-container.dark.disabled .radio-button {
  background-color: transparent;
  border-color: #66667E;
}
.radio-button-container.dark.disabled .radio-button::before {
  background-color: #66667E;
}
.radio-button-container.dark.disabled .radio-button-label {
  color: #66667E;
}

.search {
  position: relative;
  height: 100%;
}
.search .search-input-field {
  display: flex;
}
.search .search-input-field input {
  width: calc(100% - 28px);
  margin: 0;
  padding: 16px 14px 6px;
  font: inherit;
  color: #4C4C68;
  background-color: #EBF7F8;
  border: none;
  border-bottom: 1px solid #4C4C68;
  border-radius: 0;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border 0.3s ease-in-out;
}
.search .search-input-field input::placeholder {
  color: #4C4C68;
  user-select: none;
  transition: color 0.3s ease-in-out;
}
.search .search-input-field input:focus, .search .search-input-field input:hover {
  color: #000028;
  background-color: #D1FFF2;
  border-color: #00D7A0;
}
.search .search-input-field input:focus::placeholder, .search .search-input-field input:hover::placeholder {
  color: #000028;
}
.search .search-input-field input:disabled {
  color: #7D8099 !important;
  -webkit-text-fill-color: #7D8099;
  background-color: #EBF7F8 !important;
  border-color: transparent;
  cursor: not-allowed;
}
.search .search-input-field input:focus + .search-label {
  bottom: 24px;
  font-size: 10px;
  color: #000028;
}
.search .search-input-field input:hover + .search-label {
  color: #000028;
}
.search .search-input-field input:disabled + .search-label {
  color: #7D8099 !important;
}
.search .search-input-field .search-label {
  position: absolute;
  top: 2px;
  bottom: 2px;
  display: flex;
  align-items: center;
  margin-left: 14px;
  font-size: 16px;
  color: #4C4C68;
  user-select: none;
  pointer-events: none;
  transition: color 0.3s ease-in-out, font-size 0.15s ease-in-out, bottom 0.15s ease-in-out;
}
.search .search-input-field .search-label.filled {
  bottom: 24px;
  font-size: 10px;
}
.search .search-input-field .search-label.required::before {
  position: absolute;
  content: "*";
  right: calc(100% + 2px);
}
.search .search-input-field .search-icon {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 0 11px;
  background: linear-gradient(#00FFB9, #00E6DC);
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.search .search-input-field .search-icon svg {
  width: 20px;
  fill: #000028;
}
.search .search-input-field .search-icon::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background-color: #62EEC7;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.search .search-results {
  position: absolute;
  top: 100%;
  z-index: 2;
  max-height: 0;
  width: 100%;
  overflow-y: auto;
  background-color: #EBF7F8;
  transition: max-height 0.5s ease-in-out, background-color 0.5s ease-in-out;
}
.search .search-results::-webkit-scrollbar {
  width: 6px;
}
.search .search-results::-webkit-scrollbar-track {
  background-color: inherit;
}
.search .search-results::-webkit-scrollbar-thumb {
  background-color: #D9EFF1;
}
.search .search-results .search-result {
  position: relative;
  z-index: 1;
  padding: 5px 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  cursor: pointer;
}
.search.open .search-results.search-results-1 {
  max-height: 29px;
}
.search.open .search-results.search-results-2 {
  max-height: 58px;
}
.search.open .search-results.search-results-3 {
  max-height: 87px;
}
.search.open .search-results.search-results-4 {
  max-height: 116px;
}
.search.open .search-results.search-results-5 {
  max-height: 145px;
}
.search.open .search-results, .search:hover .search-results {
  background-color: #D1FFF2;
}
.search.open .search-results::-webkit-scrollbar-thumb, .search:hover .search-results::-webkit-scrollbar-thumb {
  background-color: #91F3D7;
}
.search.open .search-results::-webkit-scrollbar-thumb:hover, .search:hover .search-results::-webkit-scrollbar-thumb:hover {
  background-color: #B0F6E3;
}
.search.open .search-results .search-result.selected, .search:hover .search-results .search-result.selected {
  background-color: #62EEC7;
}
.search.condensed .search-input-field input {
  padding: 6px 14px;
}
.search.condensed .search-input-field .search-icon {
  padding: 0 6px;
}
.search.disabled .search-input-field .search-icon {
  cursor: not-allowed;
}
.search:not(.disabled) .search-input-field .search-icon:hover::before {
  opacity: 1;
}
.search.error:not(.disabled):not(:focus-within) .search-input-field input {
  color: #000028;
  background-color: #FCCCD7;
  border-color: #FF2640;
}
.search.error:not(.disabled):not(:focus-within) .search-input-field input::placeholder {
  color: #000028;
}
.search.error:not(.disabled):not(:focus-within) .search-input-field .search-label {
  color: #FF2640 !important;
}
.search.error:not(.disabled):not(:focus-within) .search-results {
  background-color: #FCCCD7;
}
.search.error:not(.disabled):not(:focus-within) .search-results::-webkit-scrollbar-thumb {
  background-color: #FFA8B3;
}
.search.error:not(.disabled):not(:focus-within) .search-results .search-result.selected {
  background-color: inherit !important;
}
.search.dark .search-input-field input {
  color: #EBEBEE;
  background-color: #00183B;
  border-color: #7D8099;
}
.search.dark .search-input-field input::placeholder {
  color: #7D8099;
}
.search.dark .search-input-field input:focus, .search.dark .search-input-field input:hover {
  color: #EBEBEE;
  background-color: #001F39;
  border-color: #00FFB9;
}
.search.dark .search-input-field input:focus::placeholder, .search.dark .search-input-field input:hover::placeholder {
  color: #EBEBEE;
}
.search.dark .search-input-field input:disabled {
  color: #66667E !important;
  -webkit-text-fill-color: #66667E;
  background-color: #00183B !important;
  border-color: transparent;
}
.search.dark .search-input-field input:hover + .search-label {
  color: #EBEBEE;
}
.search.dark .search-input-field input:hover + .search-label.filled, .search.dark .search-input-field input:focus + .search-label, .search.dark .search-input-field input:focus:hover + .search-label {
  color: #00FFB9;
}
.search.dark .search-input-field input:disabled + .search-label, .search.dark .search-input-field input:disabled:hover + .search-label {
  color: #66667E !important;
}
.search.dark .search-input-field .search-label {
  color: #7D8099;
}
.search.dark .search-input-field .search-icon::before {
  background-color: #C5FFEF;
}
.search.dark .search-results {
  background-color: #00183B;
}
.search.dark .search-results::-webkit-scrollbar-thumb {
  background-color: #333353;
}
.search.dark .search-results::-webkit-scrollbar-thumb:hover {
  background-color: #66667E;
}
.search.dark.open .search-results, .search.dark:hover .search-results {
  background-color: #001F39;
}
.search.dark.open .search-results .search-result.selected, .search.dark:hover .search-results .search-result.selected {
  background-color: #005159;
}
.search.dark.error:not(.disabled):not(:focus-within) .search-input-field input {
  color: #EBEBEE;
  background-color: #331131;
  border-color: #FF5454;
}
.search.dark.error:not(.disabled):not(:focus-within) .search-input-field input::placeholder {
  color: #EBEBEE;
}
.search.dark.error:not(.disabled):not(:focus-within) .search-input-field .search-label {
  color: #FF5454 !important;
}
.search.dark.error:not(.disabled):not(:focus-within) .search-results {
  background-color: #331131;
}
.search.dark.error:not(.disabled):not(:focus-within) .search-results::-webkit-scrollbar-thumb {
  background-color: #4D1A4A;
}

.section {
  padding: 30px 0;
}
.section.deep-blue {
  color: #EBEBEE;
  background-color: #000028;
}
.section.light-sand {
  background-color: #F3F3F0;
}
.section.white {
  background-color: white;
}

.select {
  position: relative;
  min-width: 205px;
  color: #4C4C68;
  background-color: #EBF7F8;
  border-bottom: 1px solid #4C4C68;
  outline: none;
  user-select: none;
  cursor: pointer;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border 0.3s ease-in-out;
}
.select .select-opener {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 5px;
  padding: 0 5px 0 14px;
}
.select .select-opener.with-reset {
  grid-template-columns: 1fr auto auto;
}
.select .select-opener.filled .select-label {
  bottom: 24px;
  font-size: 10px;
}
.select .select-opener.filled .select-value {
  opacity: 1;
}
.select .select-opener .select-label {
  position: absolute;
  top: 2px;
  bottom: 2px;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #4C4C68;
  user-select: none;
  pointer-events: none;
  transition: color 0.3s ease-in-out, font-size 0.15s ease-in-out, bottom 0.15s ease-in-out;
}
.select .select-opener .select-label.required::before {
  position: absolute;
  content: "*";
  right: calc(100% + 2px);
}
.select .select-opener .select-value {
  padding: 16px 0 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.select .select-opener .select-reset {
  display: flex;
  align-items: center;
  margin: 16px 0 6px;
  border-radius: 100%;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}
.select .select-opener .select-reset:hover {
  background-color: #62EEC7;
}
.select .select-opener .select-reset:hover svg {
  color: #000028;
}
.select .select-opener .select-reset svg {
  width: 15px;
  padding: 0 2px;
  fill: #4C4C68;
  transition: fill 0.3s ease-in-out;
}
.select .select-opener .select-arrow {
  display: flex;
  align-items: center;
}
.select .select-opener .select-arrow svg {
  width: 20px;
  fill: #4C4C68;
  transition: fill 0.3s ease-in-out, transform 0.5s ease-in-out;
}
.select .select-values {
  position: absolute;
  top: calc(100% + 1px);
  z-index: 2;
  max-height: 0;
  width: 100%;
  overflow-y: auto;
  background-color: #EBF7F8;
  transition: max-height 0.5s ease-in-out, background-color 0.5s ease-in-out;
}
.select .select-values::-webkit-scrollbar {
  width: 6px;
}
.select .select-values::-webkit-scrollbar-track {
  background-color: inherit;
}
.select .select-values::-webkit-scrollbar-thumb {
  background-color: #D9EFF1;
}
.select .select-values .select-value {
  position: relative;
  z-index: 1;
  padding: 5px 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  cursor: pointer;
}
.select.open .select-values.select-values-1 {
  max-height: 29px;
}
.select.open .select-values.select-values-2 {
  max-height: 58px;
}
.select.open .select-values.select-values-3 {
  max-height: 87px;
}
.select.open .select-values.select-values-4 {
  max-height: 116px;
}
.select.open .select-values.select-values-5 {
  max-height: 145px;
}
.select.open .select-opener .select-arrow svg {
  transform: rotate(-180deg);
}
.select.open, .select:hover {
  color: #000028;
  background-color: #D1FFF2;
  border-color: #00D7A0;
}
.select.open .select-opener .select-label, .select:hover .select-opener .select-label {
  color: #000028;
}
.select.open .select-opener .select-reset svg, .select:hover .select-opener .select-reset svg {
  fill: #000028;
}
.select.open .select-opener .select-arrow svg, .select:hover .select-opener .select-arrow svg {
  fill: #000028;
}
.select.open .select-values, .select:hover .select-values {
  background-color: #D1FFF2;
}
.select.open .select-values::-webkit-scrollbar-thumb, .select:hover .select-values::-webkit-scrollbar-thumb {
  background-color: #91F3D7;
}
.select.open .select-values::-webkit-scrollbar-thumb:hover, .select:hover .select-values::-webkit-scrollbar-thumb:hover {
  background-color: #B0F6E3;
}
.select.open .select-values .select-value.selected, .select:hover .select-values .select-value.selected {
  background-color: #62EEC7;
}
.select.disabled {
  color: #7D8099;
  background-color: #EBF7F8;
  border-color: transparent;
  cursor: not-allowed;
}
.select.disabled .select-opener .select-label {
  color: #7D8099;
}
.select.disabled .select-opener .select-arrow svg {
  fill: #7D8099;
}
.select.error:not(.disabled):not(:focus-within) {
  color: #000028;
  background-color: #FCCCD7;
  border-color: #FF2640;
}
.select.error:not(.disabled):not(:focus-within) .select-opener .select-label {
  color: #FF2640;
}
.select.error:not(.disabled):not(:focus-within) .select-opener .select-reset:hover {
  background-color: #FFA8B3;
}
.select.error:not(.disabled):not(:focus-within) .select-opener .select-reset:hover svg {
  color: #000028;
}
.select.error:not(.disabled):not(:focus-within) .select-opener .select-reset svg {
  fill: #000028;
}
.select.error:not(.disabled):not(:focus-within) .select-opener .select-arrow svg {
  fill: #000028;
}
.select.error:not(.disabled):not(:focus-within) .select-values {
  background-color: #FCCCD7;
}
.select.error:not(.disabled):not(:focus-within) .select-values::-webkit-scrollbar-thumb {
  background-color: #FFA8B3;
}
.select.error:not(.disabled):not(:focus-within) .select-values .select-value.selected {
  background-color: inherit !important;
}
.select.dark {
  color: #EBEBEE;
  background-color: #00183B;
  border-color: #7D8099;
}
.select.dark .select-opener .select-label {
  color: #7D8099;
}
.select.dark .select-opener .select-reset:hover {
  background-color: #66667E;
}
.select.dark .select-opener .select-reset:hover svg {
  color: #EBEBEE;
}
.select.dark .select-opener .select-reset svg {
  fill: #EBEBEE;
}
.select.dark .select-opener .select-arrow svg {
  fill: #7D8099;
}
.select.dark .select-values {
  background-color: #00183B;
}
.select.dark .select-values::-webkit-scrollbar-thumb {
  background-color: #333353;
}
.select.dark .select-values::-webkit-scrollbar-thumb:hover {
  background-color: #66667E;
}
.select.dark.open, .select.dark:hover {
  color: #EBEBEE;
  background-color: #001F39;
  border-color: #00FFB9;
}
.select.dark.open .select-opener .select-label, .select.dark:hover .select-opener .select-label {
  color: #EBEBEE;
}
.select.dark.open .select-opener .select-reset svg, .select.dark:hover .select-opener .select-reset svg {
  fill: #EBEBEE;
}
.select.dark.open .select-opener .select-arrow svg, .select.dark:hover .select-opener .select-arrow svg {
  fill: #00FFB9;
}
.select.dark.open .select-opener.filled .select-label, .select.dark:hover .select-opener.filled .select-label {
  color: #00FFB9;
}
.select.dark.open .select-values, .select.dark:hover .select-values {
  background-color: #001F39;
}
.select.dark.open .select-values .select-value.selected, .select.dark:hover .select-values .select-value.selected {
  background-color: #005159;
}
.select.dark.disabled, .select.dark.disabled:hover {
  color: #66667E;
  background-color: #00183B;
  border-color: transparent;
}
.select.dark.disabled .select-opener .select-label, .select.dark.disabled:hover .select-opener .select-label {
  color: #66667E;
}
.select.dark.disabled .select-opener .select-arrow svg, .select.dark.disabled:hover .select-opener .select-arrow svg {
  fill: #66667E;
}
.select.dark.error:not(.disabled):not(:focus-within) {
  color: #EBEBEE;
  background-color: #331131;
  border-color: #FF5454;
}
.select.dark.error:not(.disabled):not(:focus-within) .select-opener .select-label {
  color: #FF5454;
}
.select.dark.error:not(.disabled):not(:focus-within) .select-opener .select-reset:hover {
  background-color: #4D1A4A;
}
.select.dark.error:not(.disabled):not(:focus-within) .select-opener .select-reset:hover svg {
  color: #EBEBEE;
}
.select.dark.error:not(.disabled):not(:focus-within) .select-opener .select-reset svg {
  fill: #EBEBEE;
}
.select.dark.error:not(.disabled):not(:focus-within) .select-opener .select-arrow svg {
  fill: #FF5454;
}
.select.dark.error:not(.disabled):not(:focus-within) .select-values {
  background-color: #331131;
}
.select.dark.error:not(.disabled):not(:focus-within) .select-values::-webkit-scrollbar-thumb {
  background-color: #4D1A4A;
}

.rc-slider {
  margin: 0 7px;
}
.rc-slider .rc-slider-rail {
  background-color: #EBEBEE;
}
.rc-slider .rc-slider-track {
  background-color: #00FFB9;
}
.rc-slider .rc-slider-handle {
  border: none;
  background-color: #0098A6;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
}
.rc-slider .rc-slider-handle:hover, .rc-slider .rc-slider-handle:active, .rc-slider .rc-slider-handle.rc-slider-handle-dragging {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
}
.rc-slider .rc-slider-handle:hover, .rc-slider .rc-slider-handle:active {
  background-color: #00646E;
}
.rc-slider .rc-slider-handle:focus-visible {
  background-color: #00646E;
}
.rc-slider.rc-slider-disabled {
  background: none;
}
.rc-slider.rc-slider-disabled .rc-slider-handle {
  background-color: #D9D9D6;
}
.rc-slider.dark .rc-slider-rail {
  background-color: #DFDFD9;
}
.rc-slider.dark .rc-slider-track {
  background-color: #00FFB9;
}
.rc-slider.dark .rc-slider-handle {
  background-color: white;
}
.rc-slider.dark .rc-slider-handle:hover, .rc-slider.dark .rc-slider-handle:active {
  background-color: #CCCCD4;
}
.rc-slider.dark .rc-slider-handle:hover, .rc-slider.dark .rc-slider-handle:active, .rc-slider.dark .rc-slider-handle:focus-visible {
  background-color: #CCCCD4;
}
.rc-slider.dark.rc-slider-disabled {
  background: none;
}
.rc-slider.dark.rc-slider-disabled .rc-slider-rail {
  background-color: #737389;
}
.rc-slider.dark.rc-slider-disabled .rc-slider-track {
  background-color: #006662;
}
.rc-slider.dark.rc-slider-disabled .rc-slider-handle {
  background-color: #D9D9D6;
}

.snackbar .snackbar-content-container {
  padding-top: 5px;
}
.snackbar .snackbar-content-container .snackbar-content {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: start;
  gap: 20px;
  padding: 15px 20px;
  font-size: 18px;
  color: #000028;
  background-color: white;
  border-radius: 1px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);
}
.snackbar .snackbar-content-container .snackbar-content svg {
  width: 20px;
  fill: black;
}
.snackbar .snackbar-content-container .snackbar-content .snackbar-close-button {
  display: flex;
  align-items: center;
  outline: none;
  cursor: pointer;
}
.snackbar .snackbar-content-container .snackbar-content .snackbar-close-button:focus-visible {
  outline: 1px solid #36ABFF;
}
.snackbar.opening {
  animation: snackbar-open 0.5s ease-in-out forwards;
}
@keyframes snackbar-open {
  0% {
    height: 0;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.snackbar.closing {
  animation: snackbar-close 0.5s ease-in-out forwards;
}
@keyframes snackbar-close {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    height: 0;
  }
}
.snackbar.closed {
  height: 0;
  opacity: 0;
}
.snackbar.error .snackbar-content-container .snackbar-content > svg {
  fill: #D72339;
}
.snackbar.info .snackbar-content-container .snackbar-content > svg {
  fill: black;
}
.snackbar.success .snackbar-content-container .snackbar-content > svg {
  fill: #01893A;
}
.snackbar.warning .snackbar-content-container .snackbar-content > svg {
  fill: #E9C32A;
}
.snackbar.dark .snackbar-content-container .snackbar-content {
  color: white;
  background-color: #001F39;
}
.snackbar.dark .snackbar-content-container .snackbar-content svg {
  fill: white;
}
.snackbar.dark.error .snackbar-content-container .snackbar-content > svg {
  fill: #FF2640;
}
.snackbar.dark.info .snackbar-content-container .snackbar-content > svg {
  fill: white;
}
.snackbar.dark.success .snackbar-content-container .snackbar-content > svg {
  fill: #01D65A;
}
.snackbar.dark.warning .snackbar-content-container .snackbar-content > svg {
  fill: #FFD732;
}

.snackbars {
  position: fixed;
  left: calc(50% - 300px);
  bottom: 20px;
  width: 600px;
  z-index: 10;
  display: flex;
  flex-direction: column;
}
@media (max-width: 620px) {
  .snackbars {
    right: 10px;
    left: 10px;
    width: auto;
  }
}

.spacing-container {
  background-color: inherit;
}
.spacing-container > *:not(:first-child) {
  margin-top: 10px;
}

.sticky {
  position: sticky;
  z-index: 1;
  margin: 0 -10px;
  padding: 0 10px;
  background-color: inherit;
}

.table {
  position: relative;
  background-color: inherit;
}
.table::before, .table::after {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  width: 10px;
  pointer-events: none;
}
.table::before {
  left: -11px;
  background: linear-gradient(to right, white, rgba(255, 255, 255, 0));
}
.table::after {
  right: -10px;
  background: linear-gradient(to left, white, rgba(255, 255, 255, 0));
}
.table .table-container {
  height: 100%;
  margin: 0 -10px;
  padding: 0 10px;
  overflow-x: auto;
  overflow-y: hidden;
}
.table .table-container::-webkit-scrollbar {
  height: 8px;
}
.table .table-container::-webkit-scrollbar-track {
  background: transparent;
}
.table .table-container::-webkit-scrollbar-thumb {
  background: #CCCCD4;
}
.table .table-container::-webkit-scrollbar-thumb:hover {
  background-color: #EBEBEE;
}
.table .table-container.no-scrollbar {
  scrollbar-width: none;
}
.table .table-container.no-scrollbar::-webkit-scrollbar {
  display: none;
}
.table .table-container .table-grid {
  height: 100%;
  display: grid;
  column-gap: 20px;
}
.table .table-container .table-grid .table-row {
  display: contents;
}
.table .table-container .table-grid .table-row .table-cell {
  padding: 16px;
  color: #000028;
  font-weight: 400;
  border: 1px solid;
  border-left: none;
  border-right: none;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.table .table-container .table-grid .table-row .table-cell.colored {
  background-color: #FAFDFD;
}
.table .table-container .table-grid .table-row .table-cell:focus-visible {
  outline: 1px solid #36ABFF;
  outline-offset: -1px;
}
.table .table-container .table-grid .table-row .table-cell.table-header-cell {
  display: grid;
  grid-template: minmax(20px, auto)/1fr 20px;
  align-items: center;
  gap: 5px;
  border-color: #000028;
  border-top: none;
  user-select: none;
}
.table .table-container .table-grid .table-row .table-cell.table-header-cell svg {
  width: 20px;
  fill: #000028;
  transition: fill 0.2s ease-in-out;
}
.table .table-container .table-grid .table-row .table-cell.table-header-cell.clickable {
  cursor: pointer;
}
.table .table-container .table-grid .table-row .table-cell.table-header-cell.clickable:hover {
  color: #00646E;
  background-color: #D1FFF2;
}
.table .table-container .table-grid .table-row .table-cell.table-header-cell.clickable:hover svg {
  fill: #00646E;
}
.table .table-container .table-grid .table-row .table-cell.table-header-cell.bold {
  font-weight: 700;
}
.table .table-container .table-grid .table-row .table-cell.table-header-cell.button-left {
  display: grid;
  grid-template-columns: fit-content(20px) 1fr;
  grid-template-areas: "buttonIcon label";
}
.table .table-container .table-grid .table-row .table-cell.table-header-cell.button-left .button-icon {
  display: flex;
  grid-area: buttonIcon;
  align-self: center;
}
.table .table-container .table-grid .table-row .table-cell.table-header-cell.button-left .button-icon svg {
  width: 20px;
}
.table .table-container .table-grid .table-row .table-cell.table-header-cell.button-left .sorting-placeholder {
  display: none;
}
.table .table-container .table-grid .table-row .table-cell.table-header-cell.button-right {
  display: grid;
  grid-template-columns: 1fr fit-content(20px);
  grid-template-areas: "title buttonIcon";
}
.table .table-container .table-grid .table-row .table-cell.table-header-cell.button-right .button-icon {
  display: flex;
  grid-area: buttonIcon;
  align-self: center;
}
.table .table-container .table-grid .table-row .table-cell.table-header-cell.button-right .button-icon svg {
  width: 20px;
}
.table .table-container .table-grid .table-row .table-cell.table-header-cell.button-right .sorting-placeholder {
  display: none;
}
.table .table-container .table-grid .table-row .table-cell.table-header-cell.no-header-linebreak {
  white-space: nowrap;
}
.table .table-container .table-grid .table-row .table-cell.table-header-cell.highlighted {
  font-weight: 700;
  border-width: 3px;
}
.table .table-container .table-grid .table-row .table-cell.table-data-cell {
  border-color: #B3B3BE;
}
.table .table-container .table-grid .table-row .table-cell.table-data-cell.thick-bottom-line {
  border-color: #4C4C68;
}
.table .table-container .table-grid .table-row .table-cell.table-data-cell.single-line {
  white-space: nowrap;
}
.table .table-container .table-grid .table-row .table-cell.table-data-cell.sized {
  overflow: hidden;
  text-overflow: ellipsis;
}
.table .table-container .table-grid .table-row .table-cell.table-data-cell.input-style {
  padding: 5px;
  overflow: visible;
}
.table .table-container .table-grid .table-row .table-cell.table-data-cell.input-style > * {
  min-width: auto;
}
.table .table-container .table-grid .table-row .table-cell.table-data-cell.plain {
  border: initial;
}
.table .table-container .table-grid .table-row .table-cell.table-data-cell.highlighted {
  font-weight: 700;
}
.table .table-container .table-grid .table-row .table-cell.table-data-cell .divider-content {
  height: 110px;
}
.table .table-container .table-grid .table-row.hoverable:hover .table-data-cell, .table .table-container .table-grid .table-row.clickable:hover .table-data-cell {
  color: #00646E;
  background-color: #D1FFF2;
  border-color: #00646E;
}
.table .table-container .table-grid .table-row.hoverable:hover + .table-row .table-data-cell, .table .table-container .table-grid .table-row.clickable:hover + .table-row .table-data-cell {
  border-top-color: #00646E;
}
.table .table-container .table-grid .table-row.hoverable:hover:last-child .table-data-cell.highlighted, .table .table-container .table-grid .table-row.clickable:hover:last-child .table-data-cell.highlighted {
  border-bottom-color: #000028;
}
.table .table-container .table-grid .table-row.clickable .table-data-cell {
  cursor: pointer;
}
.table .table-container .table-grid .table-row:not(.no-header):nth-child(2) .table-data-cell {
  border-top: none;
}
.table .table-container .table-grid .table-row:not(:last-child) .table-data-cell {
  border-bottom: none;
}
.table .table-container .table-grid .table-row:last-child.no-border-last .table-data-cell {
  border-bottom: none;
}
.table .table-container .table-grid .table-row:last-child .table-data-cell.highlighted {
  border-bottom-width: 3px;
  border-bottom-color: #000028;
}
.table .loading-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -10px;
  right: -10px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(162, 178, 186, 0.3764705882);
  visibility: hidden;
}
.table .loading-overlay.loading-animation.loading {
  animation: appear 0.3s ease-in-out forwards;
}
@keyframes appear {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
.table .loading-overlay.loading-animation:not(.loading) {
  animation: disappear 0.3s ease-in-out forwards;
}
@keyframes disappear {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
.table .loading-overlay .loader-icon {
  width: 50px;
  height: 50px;
  animation: rotate 1s linear infinite;
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
.table .loading-overlay .loader-icon circle {
  fill: none;
  stroke: #000028;
  stroke-width: 4px;
  stroke-dasharray: 50, 42;
  stroke-dashoffset: 8;
}
.table.dark::before {
  background: linear-gradient(to right, #000028, rgba(0, 0, 40, 0));
}
.table.dark::after {
  background: linear-gradient(to left, #000028, rgba(0, 0, 40, 0));
}
.table.dark .table-container::-webkit-scrollbar-thumb {
  background: #262648;
}
.table.dark .table-container::-webkit-scrollbar-thumb:hover {
  background-color: #333353;
}
.table.dark .table-container .table-grid .table-row .table-cell {
  color: white;
}
.table.dark .table-container .table-grid .table-row .table-cell.table-header-cell {
  border-color: white;
}
.table.dark .table-container .table-grid .table-row .table-cell.table-header-cell svg {
  fill: white;
}
.table.dark .table-container .table-grid .table-row .table-cell.table-header-cell.clickable:hover {
  color: #00FFB9;
  background-color: #001F39;
}
.table.dark .table-container .table-grid .table-row .table-cell.table-header-cell.clickable:hover svg {
  fill: #00FFB9;
}
.table.dark .table-container .table-grid .table-row .table-cell.table-data-cell {
  border-color: #4C4C68;
}
.table.dark .table-container .table-grid .table-row.hoverable:hover .table-data-cell, .table.dark .table-container .table-grid .table-row.clickable:hover .table-data-cell {
  color: #00FFB9;
  background-color: #001F39;
  border-color: #00646E;
}
.table.dark .table-container .table-grid .table-row.hoverable:hover + .table-row .table-data-cell, .table.dark .table-container .table-grid .table-row.clickable:hover + .table-row .table-data-cell {
  border-top-color: #00646E;
}
.table.dark .table-container .table-grid .table-row.hoverable:hover:last-child .table-data-cell.highlighted, .table.dark .table-container .table-grid .table-row.clickable:hover:last-child .table-data-cell.highlighted {
  border-bottom-color: white;
}
.table.dark .table-container .table-grid .table-row:last-child .table-data-cell.highlighted {
  border-bottom-color: white;
}
.table.dark .loading-overlay {
  background-color: rgba(31, 38, 41, 0.5019607843);
}
.table.dark .loading-overlay .loader-icon circle {
  stroke: white;
}

.tabs .tab-switcher-container {
  position: relative;
  margin-bottom: 10px;
  outline: none;
}
.tabs .tab-switcher-container::before, .tabs .tab-switcher-container::after {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  z-index: 1;
  width: 10px;
  pointer-events: none;
}
.tabs .tab-switcher-container::before {
  left: -11px;
  background: linear-gradient(to right, white, rgba(255, 255, 255, 0));
}
.tabs .tab-switcher-container::after {
  right: -10px;
  background: linear-gradient(to left, white, rgba(255, 255, 255, 0));
}
.tabs .tab-switcher-container:focus-visible .tab-switcher .tab.active {
  background-color: #C5FFEF;
}
.tabs .tab-switcher-container .tab-switcher {
  position: relative;
  display: flex;
  margin: 0 -10px;
  padding: 0 10px;
  overflow-x: auto;
}
.tabs .tab-switcher-container .tab-switcher::-webkit-scrollbar {
  height: 4px;
}
.tabs .tab-switcher-container .tab-switcher::-webkit-scrollbar-track {
  background: transparent;
}
.tabs .tab-switcher-container .tab-switcher::-webkit-scrollbar-thumb {
  background: #B3B3BE;
}
.tabs .tab-switcher-container .tab-switcher .tab {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  padding: 25px;
  font-size: 18px;
  font-weight: 900;
  color: #000028;
  border-bottom: 2px solid #CCCCD4;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.tabs .tab-switcher-container .tab-switcher .tab:hover, .tabs .tab-switcher-container .tab-switcher .tab:active {
  background-color: #C5FFEF;
}
.tabs .tab-switcher-container .tab-switcher .tab.required::before {
  content: "*";
  margin-right: 2px;
}
.tabs .tab-switcher-container .tab-switcher .tab-switcher-underline {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  border-bottom: 2px solid #009999;
  transition: left 0.5s ease-in-out, width 0.5s ease-in-out;
}
.tabs .tab-content-container {
  display: grid;
  margin: 0 -20px;
  padding: 0 20px;
  overflow: hidden;
}
.tabs .tab-content-container .tab-content {
  grid-column: 1/span 1;
  grid-row: 1/span 1;
}
.tabs .tab-content-container .tab-content.open-from-left {
  animation: open-from-left 0.5s ease-in-out forwards;
}
@keyframes open-from-left {
  0% {
    opacity: 0;
    transform: translateX(-20px);
    visibility: hidden;
  }
  50% {
    opacity: 0;
    transform: translateX(-20px);
    visibility: visible;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
    visibility: visible;
  }
}
.tabs .tab-content-container .tab-content.open-from-right {
  animation: open-from-right 0.5s ease-in-out forwards;
}
@keyframes open-from-right {
  0% {
    opacity: 0;
    transform: translateX(20px);
    visibility: hidden;
  }
  50% {
    opacity: 0;
    transform: translateX(20px);
    visibility: visible;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
    visibility: visible;
  }
}
.tabs .tab-content-container .tab-content.close-to-left {
  animation: close-to-left 0.5s ease-in-out forwards;
}
@keyframes close-to-left {
  0% {
    opacity: 1;
    transform: translateX(0);
    visibility: visible;
  }
  50% {
    opacity: 0;
    transform: translateX(20px);
    visibility: visible;
  }
  100% {
    opacity: 0;
    transform: translateX(20px);
    visibility: hidden;
  }
}
.tabs .tab-content-container .tab-content.close-to-right {
  animation: close-to-right 0.5s ease-in-out forwards;
}
@keyframes close-to-right {
  0% {
    opacity: 1;
    transform: translateX(0);
    visibility: visible;
  }
  50% {
    opacity: 0;
    transform: translateX(-20px);
    visibility: visible;
  }
  100% {
    opacity: 0;
    transform: translateX(-20px);
    visibility: hidden;
  }
}
.tabs.dark .tab-switcher-container::before {
  background: linear-gradient(to right, #000028, rgba(0, 0, 40, 0));
}
.tabs.dark .tab-switcher-container::after {
  background: linear-gradient(to left, #000028, rgba(0, 0, 40, 0));
}
.tabs.dark .tab-switcher-container:focus-visible .tab-switcher .tab.active {
  background-color: #001F39;
}
.tabs.dark .tab-switcher-container .tab-switcher::-webkit-scrollbar-thumb {
  background: #4C4C68;
}
.tabs.dark .tab-switcher-container .tab-switcher .tab {
  color: white;
  border-color: #66667E;
}
.tabs.dark .tab-switcher-container .tab-switcher .tab:hover, .tabs.dark .tab-switcher-container .tab-switcher .tab:active {
  background-color: #001F39;
}
.tabs.dark .tab-switcher-container .tab-switcher .tab-switcher-underline {
  border-color: #00FFB9;
}

.tag {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
  padding: 6px 16px;
  color: #007993;
  background-color: transparent;
  border: 1px solid #007993;
  border-radius: 17px;
  outline: none;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
}
.tag:hover, .tag:active {
  color: #005159;
  background-color: #C2FFEE;
  border-color: #005159;
}
.tag:hover svg, .tag:active svg {
  fill: #005159;
}
.tag.disabled {
  color: #7D8099;
  background-color: transparent;
  border-color: #7D8099;
  cursor: not-allowed;
}
.tag.disabled svg {
  fill: #7D8099;
}
.tag.display {
  color: #000028;
  background-color: transparent;
  border-color: #000028;
  cursor: default;
}
.tag.display svg {
  fill: #000028;
}
.tag:focus-visible::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  outline: 1px solid #36ABFF;
  outline-offset: 2px;
}
.tag .tag-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tag svg {
  fill: #007993;
  width: 20px;
  transition: fill 0.3s ease-in-out;
}
.tag.dark {
  color: #00CCCC;
  border-color: #00CCCC;
}
.tag.dark:hover, .tag.dark:active {
  color: #00FFB9;
  background-color: #001F39;
  border-color: #00FFB9;
}
.tag.dark:hover svg, .tag.dark:active svg {
  fill: #00FFB9;
}
.tag.dark.disabled {
  color: #66667E;
  background-color: transparent;
  border-color: #66667E;
}
.tag.dark.disabled svg {
  fill: #66667E;
}
.tag.dark.display {
  color: #B3B3BE;
  background-color: transparent;
  border-color: #B3B3BE;
}
.tag.dark.display svg {
  fill: #B3B3BE;
}
.tag.dark svg {
  fill: #00CCCC;
}

.tag-bar {
  display: flex;
  justify-content: space-between;
  gap: 25px;
}
@media (min-width: 576px) {
  .tag-bar {
    gap: 15px;
  }
}
.tag-bar .tags-container {
  position: relative;
  min-width: 0;
}
.tag-bar .tags-container::before, .tag-bar .tags-container::after {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  z-index: 2;
  width: 10px;
  pointer-events: none;
}
.tag-bar .tags-container::before {
  left: -11px;
}
.tag-bar .tags-container::after {
  right: -10px;
}
.tag-bar .tags-container .tags {
  display: flex;
  gap: 5px;
  flex-wrap: nowrap;
  margin: 0 -10px;
  padding: 2px 10px;
  overflow-x: auto;
}
@media (min-width: 576px) {
  .tag-bar .tags-container .tags {
    flex-wrap: wrap;
  }
}
.tag-bar .tags-container .tags::-webkit-scrollbar {
  height: 4px;
}
.tag-bar .tags-container .tags::-webkit-scrollbar-track {
  background: transparent;
}
.tag-bar .tags-container .tags::-webkit-scrollbar-thumb {
  background: #CCCCD4;
}
.tag-bar .tags-container .tags .tag {
  max-width: 140px;
}
.tag-bar.deep-blue .tags-container::before {
  background: linear-gradient(to right, #000028, rgba(0, 0, 40, 0));
}
.tag-bar.deep-blue .tags-container::after {
  background: linear-gradient(to left, #000028, rgba(0, 0, 40, 0));
}
.tag-bar.deep-blue .tags-container .tags::-webkit-scrollbar-thumb {
  background: #4C4C68;
}
.tag-bar.light-sand .tags-container::before {
  background: linear-gradient(to right, #F3F3F0, rgba(243, 243, 240, 0));
}
.tag-bar.light-sand .tags-container::after {
  background: linear-gradient(to left, #F3F3F0, rgba(243, 243, 240, 0));
}
.tag-bar.white .tags-container::before {
  background: linear-gradient(to right, white, rgba(255, 255, 255, 0));
}
.tag-bar.white .tags-container::after {
  background: linear-gradient(to left, white, rgba(255, 255, 255, 0));
}

.tag-input .tag-input-field {
  min-width: 205px;
  box-sizing: border-box;
  padding: 4px 14px 6px 14px;
  color: #4C4C68;
  background-color: #EBF7F8;
  border-bottom: 1px solid #4C4C68;
  outline: none;
  user-select: none;
  cursor: text;
  transition: color 0.3s ease-in-out, background 0.3s ease-in-out, border 0.3s ease-in-out;
}
.tag-input .tag-input-field .tag-input-label {
  position: relative;
  font-size: 10px;
  user-select: none;
  pointer-events: none;
  transition: color 0.3s ease-in-out;
}
.tag-input .tag-input-field .tag-input-label.required::before {
  position: absolute;
  content: "*";
  right: calc(100% + 2px);
}
.tag-input .tag-input-field .tag-input-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding-top: 5px;
}
.tag-input .tag-input-field .tag-input-container .tag {
  padding: 4px 12px;
  gap: 6px;
}
.tag-input .tag-input-field .tag-input-container input {
  height: 30px;
  box-sizing: border-box;
  padding: 0 2px;
  flex-grow: 1;
  font-size: 16px;
  color: #000028;
  background: none;
  border: none;
  outline: none;
}
.tag-input .tag-input-field:hover, .tag-input .tag-input-field:focus-within {
  color: #000028;
  background-color: #D1FFF2;
  border-color: #00D7A0;
}
.tag-input .tag-input-field:hover .tag-input-label, .tag-input .tag-input-field:focus-within .tag-input-label {
  color: #000028;
}
.tag-input .tag-input-message {
  margin: 3px 14px 0;
  font-size: 10px;
  color: #000028;
  user-select: none;
}
.tag-input.error:not(.disabled) .tag-input-field:not(:focus-within) {
  color: #000028;
  background-color: #FCCCD7;
  border-color: #FF2640;
}
.tag-input.error:not(.disabled) .tag-input-field:not(:focus-within) .tag-input-label {
  color: #FF2640 !important;
}
.tag-input.error:not(.disabled) .tag-input-message {
  color: #FF2640;
}
.tag-input.disabled .tag-input-field {
  color: #7D8099;
  background-color: #EBF7F8;
  border-color: transparent;
  cursor: not-allowed;
}
.tag-input.disabled .tag-input-field .tag-input-label {
  color: #7D8099;
}
.tag-input.disabled .tag-input-field .tag-input-container .tag {
  cursor: not-allowed;
}
.tag-input.disabled .tag-input-field .tag-input-container input {
  cursor: not-allowed;
}
.tag-input.dark .tag-input-field {
  color: #EBEBEE;
  background-color: #00183B;
  border-color: #7D8099;
}
.tag-input.dark .tag-input-field .tag-input-label {
  color: #7D8099;
}
.tag-input.dark .tag-input-field .tag-input-container input {
  color: white;
}
.tag-input.dark .tag-input-field:hover, .tag-input.dark .tag-input-field:focus-within {
  color: #EBEBEE;
  background-color: #001F39;
  border-color: #00FFB9;
}
.tag-input.dark .tag-input-field:hover .tag-input-label, .tag-input.dark .tag-input-field:focus-within .tag-input-label {
  color: #00FFB9;
}
.tag-input.dark .tag-input-message {
  color: #EBEBEE;
}
.tag-input.dark.error:not(.disabled) .tag-input-field:not(:focus-within) {
  color: #EBEBEE;
  background-color: #331131;
  border-color: #FF5454;
}
.tag-input.dark.error:not(.disabled) .tag-input-field:not(:focus-within) .tag-input-label {
  color: #FF5454 !important;
}
.tag-input.dark.error:not(.disabled) .tag-input-message {
  color: #7D8099;
}
.tag-input.dark.disabled .tag-input-field, .tag-input.dark.disabled:hover .tag-input-field {
  color: #66667E;
  background-color: #00183B;
  border-color: transparent;
}
.tag-input.dark.disabled .tag-input-field .tag-input-label, .tag-input.dark.disabled:hover .tag-input-field .tag-input-label {
  color: #66667E;
}

.text-area .text-area-field {
  position: relative;
  overflow: hidden;
}
.text-area .text-area-field textarea {
  width: calc(100% - 28px);
  margin: 0;
  padding: 16px 14px 6px;
  scroll-padding-top: 16px;
  font: inherit;
  color: #4C4C68;
  background-color: #EBF7F8;
  border: none;
  border-bottom: 1px solid #4C4C68;
  border-radius: 0;
  outline: none;
  resize: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: color 0.3s ease-in-out, background 0.3s ease-in-out, border 0.3s ease-in-out;
}
.text-area .text-area-field textarea::placeholder {
  color: #4C4C68;
  user-select: none;
  transition: color 0.3s ease-in-out;
}
.text-area .text-area-field textarea.error:not(:disabled):not(:focus) {
  color: #000028;
  background-color: #FCCCD7;
  border-color: #FF2640;
}
.text-area .text-area-field textarea:focus, .text-area .text-area-field textarea:hover {
  color: #000028;
  background-color: #D1FFF2;
  border-color: #00D7A0;
}
.text-area .text-area-field textarea:focus::placeholder, .text-area .text-area-field textarea:hover::placeholder {
  color: #000028;
}
.text-area .text-area-field textarea:disabled {
  color: #7D8099 !important;
  -webkit-text-fill-color: #7D8099;
  background-color: #EBF7F8 !important;
  border-color: transparent;
  cursor: not-allowed;
}
.text-area .text-area-field textarea:read-only {
  color: #4C4C68;
  background-color: transparent;
  border-color: transparent;
  cursor: not-allowed;
}
.text-area .text-area-field textarea.error:not(:disabled):not(:focus) + .text-area-label {
  color: #FF2640 !important;
  background-color: #FCCCD7;
  box-shadow: -2px -4px 3px 6px #FCCCD7;
}
.text-area .text-area-field textarea:focus:not(:read-only) + .text-area-label {
  top: 2px;
  font-size: 10px;
  color: #000028;
  background-color: #D1FFF2;
  box-shadow: -2px -4px 3px 6px #D1FFF2;
}
.text-area .text-area-field textarea:hover + .text-area-label {
  color: #000028;
  background-color: #D1FFF2;
  box-shadow: -2px -4px 3px 6px #D1FFF2;
}
.text-area .text-area-field textarea:disabled + .text-area-label {
  color: #7D8099 !important;
  background-color: #EBF7F8 !important;
  box-shadow: -2px -4px 3px 6px #EBF7F8 !important;
}
.text-area .text-area-field textarea:read-only + .text-area-label {
  color: #4C4C68;
  background-color: white;
  box-shadow: -2px -4px 3px 6px white;
}
.text-area .text-area-field textarea:required + .text-area-label::before {
  position: absolute;
  content: "*";
  right: calc(100% + 2px);
}
.text-area .text-area-field .text-area-label {
  position: absolute;
  top: 16px;
  display: flex;
  align-items: center;
  margin-left: 14px;
  padding-right: 2px;
  font-size: 16px;
  color: #4C4C68;
  background-color: #EBF7F8;
  box-shadow: -2px -4px 3px 6px #EBF7F8;
  user-select: none;
  pointer-events: none;
  transition: color 0.3s ease-in-out, font-size 0.15s ease-in-out, top 0.15s ease-in-out, background 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.text-area .text-area-field .text-area-label.filled {
  top: 2px;
  font-size: 10px;
}
.text-area .text-area-message {
  margin: 3px 14px 0;
  font-size: 10px;
  color: #000028;
  user-select: none;
}
.text-area .text-area-message.error {
  color: #FF2640;
}
.text-area.dark .text-area-field textarea {
  color: #EBEBEE;
  background-color: #00183B;
  border-color: #7D8099;
}
.text-area.dark .text-area-field textarea::placeholder {
  color: #EBEBEE;
}
.text-area.dark .text-area-field textarea.error:not(:disabled):not(:focus) {
  color: #EBEBEE;
  background-color: #331131;
  border-color: #FF5454;
}
.text-area.dark .text-area-field textarea:focus, .text-area.dark .text-area-field textarea:hover {
  color: #EBEBEE;
  background-color: #001F39;
  border-color: #00FFB9;
}
.text-area.dark .text-area-field textarea:disabled {
  color: #66667E !important;
  -webkit-text-fill-color: #66667E;
  background-color: #00183B !important;
  border-color: transparent;
}
.text-area.dark .text-area-field textarea:read-only {
  color: #7D8099;
  background-color: transparent;
  border-color: transparent;
}
.text-area.dark .text-area-field textarea.error:not(:disabled):not(:focus) + .text-area-label {
  color: #FF5454 !important;
  background-color: #331131 !important;
  box-shadow: -2px -4px 3px 6px #331131 !important;
}
.text-area.dark .text-area-field textarea:hover + .text-area-label {
  color: #EBEBEE;
  background-color: #001F39;
  box-shadow: -2px -4px 3px 6px #001F39;
}
.text-area.dark .text-area-field textarea:hover + .text-area-label.filled, .text-area.dark .text-area-field textarea:focus + .text-area-label, .text-area.dark .text-area-field textarea:focus:hover + .text-area-label {
  color: #00FFB9;
  background-color: #001F39;
  box-shadow: -2px -4px 3px 6px #001F39;
}
.text-area.dark .text-area-field textarea:disabled + .text-area-label, .text-area.dark .text-area-field textarea:disabled:hover + .text-area-label {
  color: #66667E !important;
  background-color: #00183B !important;
  box-shadow: -2px -4px 3px 6px #00183B !important;
}
.text-area.dark .text-area-field textarea:read-only + .text-area-label, .text-area.dark .text-area-field textarea:read-only:hover + .text-area-label, .text-area.dark .text-area-field textarea:read-only:focus + .text-area-label {
  color: #7D8099;
  background-color: #000028;
  box-shadow: -2px -4px 3px 6px #000028;
}
.text-area.dark .text-area-field .text-area-label {
  color: #7D8099;
  background-color: #00183B;
  box-shadow: -2px -4px 3px 6px #00183B;
}
.text-area.dark .text-area-message {
  color: #EBEBEE;
}
.text-area.dark .text-area-message.error {
  color: #7D8099;
}

.text-input .text-input-field {
  position: relative;
}
.text-input .text-input-field input {
  width: calc(100% - 28px);
  margin: 0;
  padding: 16px 14px 6px;
  font: inherit;
  color: #4C4C68;
  background-color: #EBF7F8;
  border: none;
  border-bottom: 1px solid #4C4C68;
  border-radius: 0;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: color 0.3s ease-in-out, background 0.3s ease-in-out, border 0.3s ease-in-out;
}
.text-input .text-input-field input::placeholder {
  color: #4C4C68;
  user-select: none;
  transition: color 0.3s ease-in-out;
}
.text-input .text-input-field input.error:not(:focus) {
  color: #000028;
  background-color: #FCCCD7;
  border-color: #FF2640;
}
.text-input .text-input-field input:focus, .text-input .text-input-field input:hover {
  color: #000028;
  background-color: #D1FFF2;
  border-color: #00D7A0;
}
.text-input .text-input-field input:focus::placeholder, .text-input .text-input-field input:hover::placeholder {
  color: #000028;
}
.text-input .text-input-field input:disabled {
  color: #7D8099 !important;
  -webkit-text-fill-color: #7D8099;
  background-color: #EBF7F8 !important;
  border-color: transparent;
  cursor: not-allowed;
}
.text-input .text-input-field input:read-only {
  color: #4C4C68;
  background-color: transparent;
  border-color: transparent;
  cursor: not-allowed;
}
.text-input .text-input-field input.error:not(:disabled):not(:focus) + .text-input-label {
  color: #FF2640 !important;
}
.text-input .text-input-field input:focus:not(:read-only) + .text-input-label {
  bottom: 24px;
  font-size: 10px;
  color: #000028;
}
.text-input .text-input-field input:hover + .text-input-label {
  color: #000028;
}
.text-input .text-input-field input:disabled + .text-input-label {
  color: #7D8099 !important;
}
.text-input .text-input-field input:read-only + .text-input-label {
  color: #4C4C68;
}
.text-input .text-input-field input:required + .text-input-label::before {
  position: absolute;
  content: "*";
  right: calc(100% + 2px);
}
.text-input .text-input-field .text-input-label {
  position: absolute;
  top: 2px;
  bottom: 2px;
  display: flex;
  align-items: center;
  margin-left: 14px;
  font-size: 16px;
  color: #4C4C68;
  user-select: none;
  pointer-events: none;
  transition: color 0.3s ease-in-out, font-size 0.15s ease-in-out, bottom 0.15s ease-in-out;
}
.text-input .text-input-field .text-input-label.filled {
  bottom: 24px;
  font-size: 10px;
}
.text-input .text-input-message {
  margin: 3px 14px 0;
  font-size: 10px;
  color: #000028;
  user-select: none;
}
.text-input .text-input-message.error {
  color: #FF2640;
}
.text-input.dark .text-input-field input {
  color: #EBEBEE;
  background-color: #00183B;
  border-color: #7D8099;
}
.text-input.dark .text-input-field input::placeholder {
  color: #EBEBEE;
}
.text-input.dark .text-input-field input.error:not(:disabled):not(:focus) {
  color: #EBEBEE;
  background-color: #331131;
  border-color: #FF5454;
}
.text-input.dark .text-input-field input:focus, .text-input.dark .text-input-field input:hover {
  color: #EBEBEE;
  background-color: #001F39;
  border-color: #00FFB9;
}
.text-input.dark .text-input-field input:disabled {
  color: #66667E !important;
  -webkit-text-fill-color: #66667E;
  background-color: #00183B !important;
  border-color: transparent;
}
.text-input.dark .text-input-field input:read-only {
  color: #7D8099;
  background-color: transparent;
  border-color: transparent;
}
.text-input.dark .text-input-field input.error:not(:disabled):not(:focus) + .text-input-label {
  color: #FF5454 !important;
}
.text-input.dark .text-input-field input:hover + .text-input-label {
  color: #EBEBEE;
}
.text-input.dark .text-input-field input:hover + .text-input-label.filled, .text-input.dark .text-input-field input:focus + .text-input-label, .text-input.dark .text-input-field input:focus:hover + .text-input-label {
  color: #00FFB9;
}
.text-input.dark .text-input-field input:disabled + .text-input-label, .text-input.dark .text-input-field input:disabled:hover + .text-input-label {
  color: #66667E !important;
}
.text-input.dark .text-input-field input:read-only + .text-input-label, .text-input.dark .text-input-field input:read-only:hover + .text-input-label, .text-input.dark .text-input-field input:read-only:focus + .text-input-label {
  color: #7D8099;
}
.text-input.dark .text-input-field .text-input-label {
  color: #7D8099;
}
.text-input.dark .text-input-message {
  color: #EBEBEE;
}
.text-input.dark .text-input-message.error {
  color: #7D8099;
}
.text-input.display-only .text-input-field input:read-only {
  color: #4C4C68 !important;
  -webkit-text-fill-color: #331131;
  background-color: transparent !important;
  border-color: transparent !important;
  cursor: not-allowed;
}
.text-input.display-only .text-input-field input:read-only + .text-input-label, .text-input.display-only .text-input-field input:read-only:hover + .text-input-label {
  color: #331131 !important;
}
.text-input.display-only-red .text-input-field input:read-only {
  color: #331131 !important;
  -webkit-text-fill-color: #331131;
  background-color: #FCCCD7 !important;
  cursor: not-allowed;
}
.text-input.display-only-red .text-input-field input:read-only + .text-input-label, .text-input.display-only-red .text-input-field input:read-only:hover + .text-input-label {
  color: #331131 !important;
}
.text-input.red .text-input-field input {
  color: #331131;
  background-color: #FCCCD7;
  border-color: #331131;
}
.text-input.red .text-input-field input::placeholder {
  color: #FF5454;
}
.text-input.red .text-input-field input.error:not(:disabled):not(:focus) {
  color: #EBEBEE;
  background-color: #331131;
  border-color: #FF5454;
}
.text-input.red .text-input-field input:focus, .text-input.red .text-input-field input:hover {
  color: #331131;
  background-color: #FFA8B3;
  border-color: #FF5454;
}
.text-input.red .text-input-field input:disabled {
  color: #331131 !important;
  -webkit-text-fill-color: #331131;
  background-color: #FCCCD7 !important;
  border-color: #FCCCD7;
  cursor: not-allowed;
}
.text-input.red .text-input-field input:read-only {
  color: #331131;
  background-color: #FCCCD7;
  border-color: #FCCCD7;
  cursor: not-allowed;
}
.text-input.red .text-input-field input.error:not(:disabled):not(:focus) + .text-input-label {
  color: #FF5454 !important;
}
.text-input.red .text-input-field input:hover + .text-input-label {
  color: #331131;
}
.text-input.red .text-input-field input:hover + .text-input-label.filled, .text-input.red .text-input-field input:focus + .text-input-label, .text-input.red .text-input-field input:focus:hover + .text-input-label {
  color: #331131;
}
.text-input.red .text-input-field input:disabled + .text-input-label, .text-input.red .text-input-field input:disabled:hover + .text-input-label {
  color: #331131 !important;
}
.text-input.red .text-input-field input:read-only + .text-input-label, .text-input.red .text-input-field input:read-only:hover + .text-input-label, .text-input.red .text-input-field input:read-only:focus + .text-input-label {
  color: #331131;
}
.text-input.red .text-input-field .text-input-label {
  color: #331131;
}
.text-input.yellow .text-input-field input {
  color: #4b4015;
  background-color: #FFF0B3;
  border-color: #4b4015;
}
.text-input.yellow .text-input-field input::placeholder {
  color: #4b4015;
}
.text-input.yellow .text-input-field input.error:not(:disabled):not(:focus) {
  color: #EBEBEE;
  background-color: #331131;
  border-color: #FF5454;
}
.text-input.yellow .text-input-field input:focus, .text-input.yellow .text-input-field input:hover {
  color: #4b4015;
  background-color: #FAE79A;
  border-color: #FFD732;
}
.text-input.yellow .text-input-field input:disabled {
  color: #4b4015 !important;
  -webkit-text-fill-color: #4b4015;
  background-color: #FFF0B3 !important;
  border-color: #FFF0B3;
  cursor: not-allowed;
}
.text-input.yellow .text-input-field input:read-only {
  color: #4C4C68;
  background-color: transparent;
  border-color: transparent;
  cursor: not-allowed;
}
.text-input.yellow .text-input-field input.error:not(:disabled):not(:focus) + .text-input-label {
  color: #FF5454 !important;
}
.text-input.yellow .text-input-field input:hover + .text-input-label {
  color: #4b4015;
}
.text-input.yellow .text-input-field input:hover + .text-input-label.filled, .text-input.yellow .text-input-field input:focus + .text-input-label, .text-input.yellow .text-input-field input:focus:hover + .text-input-label {
  color: #4b4015;
}
.text-input.yellow .text-input-field input:disabled + .text-input-label, .text-input.yellow .text-input-field input:disabled:hover + .text-input-label {
  color: #66667E !important;
}
.text-input.yellow .text-input-field input:read-only + .text-input-label, .text-input.yellow .text-input-field input:read-only:hover + .text-input-label, .text-input.yellow .text-input-field input:read-only:focus + .text-input-label {
  color: #4b4015;
}
.text-input.yellow .text-input-field .text-input-label {
  color: #4b4015;
}

.toggle {
  position: relative;
  height: 20px;
  width: 48px;
  padding: 4px;
  outline: none;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.toggle::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: #EBEBEE;
  border-radius: 10px;
  transition: background 0.3s ease-out;
}
.toggle::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  display: block;
  width: 28px;
  height: 28px;
  background-color: #0098A6;
  border-radius: 100%;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
  transition: left 0.3s ease-in-out, background 0.3s ease-in-out;
}
.toggle.on::before {
  background-color: #00FFB9;
  transition: background 0.3s ease-in;
}
.toggle.on::after {
  left: 28px;
}
.toggle:not(.disabled):hover::after, .toggle:not(.disabled):active::after {
  background-color: #00646E;
}
.toggle.disabled {
  cursor: not-allowed;
}
.toggle.disabled::after {
  background-color: #D9D9D6;
}
.toggle:focus-visible {
  outline: 1px solid #36ABFF;
  outline-offset: 1px;
}
.toggle.dark::before {
  background-color: #DFDFD9;
}
.toggle.dark::after {
  background-color: white;
}
.toggle.dark.on::before {
  background-color: #00FFB9;
}
.toggle.dark:not(.disabled):hover:not(.on)::before, .toggle.dark:not(.disabled):active:not(.on)::before {
  background-color: white;
}
.toggle.dark:not(.disabled):hover:not(.on)::after, .toggle.dark:not(.disabled):active:not(.on)::after {
  background-color: white;
}
.toggle.dark:not(.disabled):hover.on::before, .toggle.dark:not(.disabled):active.on::before {
  background-color: #00FFB9;
}
.toggle.dark:not(.disabled):hover.on::after, .toggle.dark:not(.disabled):active.on::after {
  background-color: #CCCCD4;
}
.toggle.dark.disabled::after {
  background-color: #B3B3BE;
}
.toggle.dark.disabled:not(.on)::before {
  background-color: #737389;
}
.toggle.dark.disabled.on::before {
  background-color: #006662;
}

.tooltip {
  overflow: hidden;
  text-overflow: ellipsis;
}

.wizard {
  position: relative;
}
.wizard.wizard-steps-1 .wizard-steps {
  grid-template-columns: repeat(1, 1fr);
}
.wizard.wizard-steps-1 .wizard-line-container {
  left: calc(50% / 1);
  width: calc(100% - (100% / 1));
}
.wizard.wizard-steps-2 .wizard-steps {
  grid-template-columns: repeat(2, 1fr);
}
.wizard.wizard-steps-2 .wizard-line-container {
  left: calc(50% / 2);
  width: calc(100% - (100% / 2));
}
.wizard.wizard-steps-3 .wizard-steps {
  grid-template-columns: repeat(3, 1fr);
}
.wizard.wizard-steps-3 .wizard-line-container {
  left: calc(50% / 3);
  width: calc(100% - (100% / 3));
}
.wizard.wizard-steps-4 .wizard-steps {
  grid-template-columns: repeat(4, 1fr);
}
.wizard.wizard-steps-4 .wizard-line-container {
  left: calc(50% / 4);
  width: calc(100% - (100% / 4));
}
.wizard.wizard-steps-5 .wizard-steps {
  grid-template-columns: repeat(5, 1fr);
}
.wizard.wizard-steps-5 .wizard-line-container {
  left: calc(50% / 5);
  width: calc(100% - (100% / 5));
}
.wizard.wizard-steps-6 .wizard-steps {
  grid-template-columns: repeat(6, 1fr);
}
.wizard.wizard-steps-6 .wizard-line-container {
  left: calc(50% / 6);
  width: calc(100% - (100% / 6));
}
.wizard.wizard-steps-7 .wizard-steps {
  grid-template-columns: repeat(7, 1fr);
}
.wizard.wizard-steps-7 .wizard-line-container {
  left: calc(50% / 7);
  width: calc(100% - (100% / 7));
}
.wizard.wizard-steps-8 .wizard-steps {
  grid-template-columns: repeat(8, 1fr);
}
.wizard.wizard-steps-8 .wizard-line-container {
  left: calc(50% / 8);
  width: calc(100% - (100% / 8));
}
.wizard.wizard-steps-9 .wizard-steps {
  grid-template-columns: repeat(9, 1fr);
}
.wizard.wizard-steps-9 .wizard-line-container {
  left: calc(50% / 9);
  width: calc(100% - (100% / 9));
}
.wizard .wizard-steps {
  position: relative;
  z-index: 1;
  display: grid;
  grid-template-rows: 1fr;
  gap: 5px;
}
.wizard .wizard-steps .wizard-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.wizard .wizard-steps .wizard-step .wizard-step-number {
  position: relative;
  height: 50px;
  width: 50px;
  z-index: 1;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 700;
  color: #7D8099;
  background-color: white;
  border: 3px solid #7D8099;
  border-radius: 50%;
  outline: none;
  user-select: none;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.wizard .wizard-steps .wizard-step .wizard-step-number:focus-visible::after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  outline: 1px solid #36ABFF;
  outline-offset: 4px;
}
.wizard .wizard-steps .wizard-step .wizard-step-name {
  font-size: 16px;
  font-weight: 700;
  color: #7D8099;
  user-select: none;
  transition: color 0.5s ease-in-out;
}
.wizard .wizard-steps .wizard-step.passed {
  cursor: pointer;
}
.wizard .wizard-steps .wizard-step.passed .wizard-step-number {
  color: #007993;
  border-color: #007993;
}
.wizard .wizard-steps .wizard-step.passed .wizard-step-name {
  color: #007993;
}
.wizard .wizard-steps .wizard-step.passed:hover .wizard-step-number {
  color: white;
  background-color: #00646E;
  border-color: #00646E;
}
.wizard .wizard-steps .wizard-step.passed:hover .wizard-step-name {
  color: #00646E;
}
.wizard .wizard-steps .wizard-step.active .wizard-step-number {
  color: white;
  background-color: #007993;
  border-color: #007993;
}
.wizard .wizard-steps .wizard-step.active .wizard-step-name {
  color: #007993;
}
.wizard .wizard-line-container {
  position: absolute;
  top: 23px;
  height: 3px;
}
.wizard .wizard-line-container .wizard-line {
  height: 100%;
  background-color: #007993;
  transition: width 0.3s ease-in-out;
}
.wizard.dark .wizard-steps .wizard-step .wizard-step-number {
  color: #7D8099;
  background-color: #000028;
  border: 3px solid #7D8099;
}
.wizard.dark .wizard-steps .wizard-step .wizard-step-name {
  color: #7D8099;
}
.wizard.dark .wizard-steps .wizard-step.passed .wizard-step-number {
  color: #00CCCC;
  border-color: #00CCCC;
}
.wizard.dark .wizard-steps .wizard-step.passed .wizard-step-name {
  color: #00CCCC;
}
.wizard.dark .wizard-steps .wizard-step.passed:hover .wizard-step-number {
  color: #000028;
  background-color: #00FFB9;
  border-color: #00FFB9;
}
.wizard.dark .wizard-steps .wizard-step.passed:hover .wizard-step-name {
  color: #00FFB9;
}
.wizard.dark .wizard-steps .wizard-step.active .wizard-step-number {
  color: #000028;
  background-color: #00CCCC;
  border-color: #00CCCC;
}
.wizard.dark .wizard-steps .wizard-step.active .wizard-step-name {
  color: #00CCCC;
}
.wizard.dark .wizard-line-container .wizard-line {
  background-color: #00CCCC;
}

footer {
  padding: 30px 0;
  background-color: #000028;
}
footer .footer-container {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 20px;
}
footer .footer-container .footer-social-links {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}
@media (min-width: 431px) {
  footer .footer-container .footer-social-links {
    grid-column: 2;
  }
}
@media (max-width: 430px) {
  footer .footer-container .footer-social-links {
    grid-column: 1;
    justify-content: flex-start;
  }
}
footer .footer-container .footer-social-links .social-link {
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
footer .footer-container .footer-social-links .social-link svg {
  width: 20px;
  fill: white;
}
footer .footer-container .footer-social-links .social-link:focus-visible svg {
  outline: 1px solid #36ABFF;
  outline-offset: 1px;
}
footer .footer-container .footer-contact {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
@media (min-width: 431px) {
  footer .footer-container .footer-contact {
    grid-column: 2;
  }
}
@media (max-width: 430px) {
  footer .footer-container .footer-contact {
    grid-column: 1;
    justify-content: flex-start;
  }
}
footer .footer-container .footer-contact .footer-contact-button {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 25px;
  font-weight: 700;
  color: inherit;
  text-decoration: none;
  background-color: white;
  outline: none;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: background-color 0.3s ease-in-out;
}
footer .footer-container .footer-contact .footer-contact-button:hover {
  background-color: #00FFB9;
}
footer .footer-container .footer-contact .footer-contact-button:focus-visible {
  outline: 1px solid #36ABFF;
  outline-offset: 2px;
}
footer .footer-container .footer-contact .footer-contact-button svg {
  width: 20px;
}
footer .footer-container .footer-links {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
}
@media (min-width: 990px) {
  footer .footer-container .footer-links.footer-legal {
    grid-row: 2;
  }
}
@media (min-width: 431px) and (max-width: 989px) {
  footer .footer-container .footer-links.footer-legal {
    grid-row: 3;
    grid-column: 1/span 2;
  }
}
@media (max-width: 430px) {
  footer .footer-container .footer-links.footer-legal {
    grid-column: 1;
    flex-direction: column;
    align-items: flex-start;
  }
}
@media (min-width: 431px) {
  footer .footer-container .footer-links.footer-global-website {
    grid-row: 1;
  }
}
@media (max-width: 430px) {
  footer .footer-container .footer-links.footer-global-website {
    display: none;
  }
}
@media (min-width: 990px) {
  footer .footer-container .footer-links.footer-siemens {
    display: none;
  }
}
@media (min-width: 431px) and (max-width: 989px) {
  footer .footer-container .footer-links.footer-siemens {
    grid-row: 2;
  }
}
@media (max-width: 430px) {
  footer .footer-container .footer-links.footer-siemens {
    display: none;
  }
}
footer .footer-container .footer-links .footer-link {
  font-size: 14px;
  color: white;
  text-decoration-color: transparent;
  text-underline-position: under;
  text-underline-offset: 4px;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: text-decoration-color 0.3s ease-in-out;
}
footer .footer-container .footer-links .footer-link:hover {
  text-decoration-color: white;
}
footer .footer-container .footer-links .footer-link:focus-visible {
  outline: 1px solid #36ABFF;
  outline-offset: 2px;
}
footer .footer-container .footer-links .footer-link.no-link {
  text-decoration-color: transparent;
  cursor: default;
}
@media (min-width: 431px) {
  footer .footer-container .footer-links .footer-link.footer-global-website-link {
    display: none;
  }
}
@media (min-width: 431px) and (max-width: 989px) {
  footer .footer-container .footer-links .footer-link.footer-siemens-link {
    display: none;
  }
}

header {
  position: fixed;
  top: 0;
  z-index: 1;
  width: 100%;
  font-size: 15px;
  color: #000028;
}
header .header-mask {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10000;
  height: 149px;
  align-items: center;
  justify-content: center;
  background-color: rgba(45, 55, 60, 0.6);
  transition: height 0.5s ease-in-out;
}
header .header-mask.opening {
  animation: header-mask-appear 0.2s ease-in-out forwards;
}
@keyframes header-mask-appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
header .header-mask.closing {
  animation: header-mask-disappear 0.2s ease-in-out forwards;
}
@keyframes header-mask-disappear {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
header .header-mask.closed {
  display: none;
}
header .header-mask.hide-tabs {
  height: 109px;
}
header .main-row-container {
  position: relative;
  z-index: 1;
  background-color: white;
  pointer-events: auto;
}
header .main-row-container .main-row {
  position: relative;
  z-index: 2;
  height: 60px;
  display: flex;
}
header .main-row-container .main-row .skip-button {
  position: absolute;
  top: 10px;
  left: 50%;
  z-index: 1;
  padding: 10px 25px;
  font-size: 16px;
  font-weight: 700;
  color: #000028;
  background: linear-gradient(to right, #00FFB9, #00E6DC);
  outline: 1px solid #36ABFF;
  outline-offset: 1px;
  cursor: default;
  user-select: none;
  white-space: nowrap;
  transform: translate(-50%, -100px);
}
header .main-row-container .main-row .skip-button:focus {
  transform: translate(-50%, 0);
}
header .main-row-container .main-row .header-row-container {
  display: flex;
  align-items: center;
}
header .main-row-container .main-row .header-row-container .siemens-logo {
  height: 20px;
  margin: 0 20px;
  user-select: none;
}
@media (max-width: 375px) {
  header .main-row-container .main-row .header-row-container .siemens-logo {
    display: none;
  }
}
header .main-row-container .main-row .header-row-container .title {
  margin: 0 20px 0 10px;
  font-size: 18px;
  user-select: none;
}
@media (max-width: 500px) {
  header .main-row-container .main-row .header-row-container .title {
    display: none;
  }
}
header .main-row-container .main-row .action-container {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  margin-right: 10px;
}
header .main-row-container .main-row .action-container .menu-icon {
  height: 17px;
  width: 18px;
  margin: 0 5px;
  padding: 6px;
  cursor: pointer;
}
@media (min-width: 768px) {
  header .main-row-container .main-row .action-container .menu-icon {
    display: none;
  }
}
header .addtitional-rows-container {
  pointer-events: auto;
  transition: transform 0.5s ease-in-out;
  will-change: transform;
}
@media (max-width: 767px) {
  header .addtitional-rows-container {
    transform: translateY(-40px);
  }
  header .addtitional-rows-container .secondary-row-container .content-container.header-content-container .secondary-row {
    opacity: 0;
  }
}
header .addtitional-rows-container.hiden {
  transform: translateY(-40px);
}
header .addtitional-rows-container.hiden .secondary-row-container .content-container.header-content-container .secondary-row {
  opacity: 0;
}
header .addtitional-rows-container .secondary-row-container {
  background-color: white;
}
header .addtitional-rows-container .secondary-row-container .content-container.header-content-container .secondary-row {
  display: flex;
  height: 40px;
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
  will-change: opacity;
}
header .header-mega-menu {
  pointer-events: auto;
}
@media (min-width: 768px) {
  header .header-mega-menu {
    display: none;
  }
}

.header-action {
  display: flex;
  align-items: center;
}
.header-action .header-action-button {
  position: relative;
  margin: 0 5px;
  padding: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
  outline: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.header-action .header-action-button:focus-visible {
  outline: 1px solid #36ABFF;
}
.header-action .header-action-button svg {
  width: 20px;
}
.header-action .header-action-button .header-action-dropdown {
  position: absolute;
  top: calc(100% + 10px);
  right: -5px;
  z-index: 1;
  min-width: 200px;
  color: white;
  background-color: #001F39;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  cursor: default;
}
.header-action .header-action-button .header-action-dropdown.opening {
  animation: header-action-dropdown-open 0.4s cubic-bezier(0.75, -0.02, 0.2, 0.97) forwards;
}
@keyframes header-action-dropdown-open {
  0% {
    transform: translate(0, 20px);
    opacity: 0;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
}
.header-action .header-action-button .header-action-dropdown.closing {
  animation: header-action-dropdown-close 0.4s cubic-bezier(0.75, -0.02, 0.2, 0.97) forwards;
}
@keyframes header-action-dropdown-close {
  0% {
    transform: translate(0);
    opacity: 1;
  }
  100% {
    transform: translate(0, 20px);
    opacity: 0;
  }
}
.header-action .header-action-button .header-action-dropdown.closed {
  display: none;
}
.header-action .header-action-button .header-action-dropdown::before {
  position: absolute;
  content: "";
  top: -10px;
  right: 10px;
  border-width: 0 10px 10px;
  border-style: solid;
  border-color: transparent transparent #001F39;
}
.header-action .header-action-button .header-action-dropdown .header-action-dropdown-title {
  padding: 15px 20px;
  font-size: 18px;
  border-bottom: 1px solid #000028;
  user-select: none;
}
.header-action .header-action-button .header-action-dropdown .header-action-dropdown-action {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  color: #00CCCC;
  outline: none;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: color 0.3s ease-in-out;
}
.header-action .header-action-button .header-action-dropdown .header-action-dropdown-action.disabled {
  color: #7D8099;
  cursor: default;
}
.header-action .header-action-button .header-action-dropdown .header-action-dropdown-action.disabled svg {
  fill: #7D8099;
}
.header-action .header-action-button .header-action-dropdown .header-action-dropdown-action:not(:last-child) {
  padding-bottom: 10px;
}
.header-action .header-action-button .header-action-dropdown .header-action-dropdown-action:not(:nth-child(2)) {
  padding-top: 10px;
}
.header-action .header-action-button .header-action-dropdown .header-action-dropdown-action:not(.disabled):hover {
  color: #00FFB9;
}
.header-action .header-action-button .header-action-dropdown .header-action-dropdown-action:not(.disabled):hover svg {
  fill: #00FFB9;
}
.header-action .header-action-button .header-action-dropdown .header-action-dropdown-action:focus-visible {
  outline: 1px solid #36ABFF;
  outline-offset: -1px;
}
.header-action .header-action-button .header-action-dropdown .header-action-dropdown-action svg {
  margin-right: 5px;
  fill: #00CCCC;
  transition: fill 0.3s ease-in-out;
}

.header-mega-menu {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  height: calc(100vh - 60px);
}
.header-mega-menu.with-project-header {
  top: 108px;
  height: calc(100vh - 108px);
}
.header-mega-menu .header-mega-menu-background {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #F3F3F0;
  border-bottom-left-radius: 100%;
}
.header-mega-menu .header-mega-menu-navigation {
  position: relative;
  max-width: 400px;
  margin: 40px auto 0;
}
.header-mega-menu .header-mega-menu-navigation .header-mega-menu-navigation-item {
  margin: 0 20px;
  padding: 10px 15px;
  font-weight: 700;
  outline: none;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: background-color 0.2s ease-in-out;
}
.header-mega-menu .header-mega-menu-navigation .header-mega-menu-navigation-item:hover {
  background-color: #DFDFD9;
}
.header-mega-menu .header-mega-menu-navigation .header-mega-menu-navigation-item:focus-visible {
  outline: 1px solid #36ABFF;
}
.header-mega-menu.opening .header-mega-menu-background {
  animation: header-mega-menu-background-open 0.5s ease-in-out forwards;
}
@keyframes header-mega-menu-background-open {
  0% {
    height: 0px;
    width: 0px;
  }
  100% {
    height: max(300vh, 300vw);
    width: max(300vh, 300vw);
  }
}
.header-mega-menu.opening .header-mega-menu-navigation {
  animation: header-mega-menu-navigation-open 0.5s ease-in-out forwards;
}
@keyframes header-mega-menu-navigation-open {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.header-mega-menu.closing .header-mega-menu-background {
  animation: header-mega-menu-background-close 0.4s ease-in-out forwards;
}
@keyframes header-mega-menu-background-close {
  0% {
    height: max(300vh, 300vw);
    width: max(300vh, 300vw);
  }
  100% {
    height: 0px;
    width: 0px;
  }
}
.header-mega-menu.closing .header-mega-menu-navigation {
  animation: header-mega-menu-navigation-close 0.4s ease-in-out forwards;
}
@keyframes header-mega-menu-navigation-close {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.header-mega-menu.closed {
  display: none;
}
.header-mega-menu.open .header-mega-menu-background {
  height: max(300vh, 300vw);
  width: max(300vh, 300vw);
}

.header-tabs {
  position: relative;
  display: flex;
  margin-left: 10px;
}
.header-tabs .tab {
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-weight: 700;
  outline: none;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.header-tabs .tab:focus-visible {
  outline: 1px solid #36ABFF;
  outline-offset: -1px;
}
.header-tabs .header-tab-underline {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
  width: 0;
  border-bottom: 2px solid #009999;
  transition: left 0.5s ease-in-out, width 0.5s ease-in-out;
}

body {
  margin: 0;
  padding-right: 0 !important;
  overflow: auto !important;
  font-family: SiemensSans;
  color: #000028;
}
body svg {
  fill: #000028;
}
body .page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
body .page main {
  position: relative;
  z-index: 0;
  flex-grow: 1;
  padding-top: 100px;
  background-color: white;
  outline: none;
  transition: padding 0.5s ease-in-out;
  will-change: padding;
}
@media (max-width: 767px) {
  body .page main {
    padding-top: 60px;
  }
}
body .page main.with-project-header {
  padding-top: 148px;
}
@media (max-width: 767px) {
  body .page main.with-project-header {
    padding-top: 108px;
  }
}

.project-header {
  padding: 0 20px 8px;
}
.project-header.hide {
  display: none;
}
.project-header .project-title {
  font-size: 20px;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.project-header .project-locked-tag {
  padding: 4px 12px;
  font-size: 14px;
  color: white;
  background-color: #D72339;
  border-radius: 12px;
  user-select: none;
}
.project-header .project-description {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.general {
  margin-top: 30px;
}
.general .form-group {
  display: grid;
  grid-template: auto/repeat(12, 1fr);
  gap: 15px;
}
.general .form-group:not(:last-child) {
  margin-bottom: 40px;
}
.general .form-group .length-3 {
  grid-column: span 3;
}
@media (max-width: 990px) {
  .general .form-group .length-3 {
    grid-column: span 4;
  }
}
@media (max-width: 700px) {
  .general .form-group .length-3 {
    grid-column: span 6;
  }
}
@media (max-width: 500px) {
  .general .form-group .length-3 {
    grid-column: span 12;
  }
}
.general .form-group .length-4 {
  grid-column: span 4;
  display: grid;
  gap: 15px;
}
@media (max-width: 700px) {
  .general .form-group .length-4 {
    grid-column: span 6;
  }
}
@media (max-width: 500px) {
  .general .form-group .length-4 {
    grid-column: span 12;
  }
}
.general .form-group .length-6 {
  grid-column: span 6;
}
@media (max-width: 990px) {
  .general .form-group .length-6 {
    grid-column: span 4;
  }
}
@media (max-width: 700px) {
  .general .form-group .length-6 {
    grid-column: span 6;
  }
}
@media (max-width: 500px) {
  .general .form-group .length-6 {
    grid-column: span 12;
  }
}
.general .form-group .length-9 {
  grid-column: span 9;
}
@media (max-width: 990px) {
  .general .form-group .length-9 {
    grid-column: span 12;
  }
}
.general .form-group .length-12 {
  grid-column: span 12;
}

.serviceCategoryField > .select {
  min-width: 65px;
  max-width: 75px;
  margin-left: 11px;
}

