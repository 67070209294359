.table {
    position: relative;
    background-color: inherit;

    &::before, &::after {
        position: absolute;
        content: "";
        top: 0;
        bottom: 0;
        width: 10px;
        pointer-events: none;
    }

    &::before {
        left: -11px;
        background: linear-gradient(to right, white, #FFFFFF00);
    }

    &::after {
        right: -10px;
        background: linear-gradient(to left, white, #FFFFFF00);
    }

    .table-container {
        height: 100%;
        margin: 0 -10px;
        padding: 0 10px;
        overflow-x: auto;
        overflow-y: hidden;

        &::-webkit-scrollbar {
            height: 8px;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background: $deep-blue-20;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: $deep-blue-8;
        }

        &.no-scrollbar {
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .table-grid {
            height: 100%;
            display: grid;
            column-gap: 20px;

            .table-row {
                display: contents;

                .table-cell {
                    padding: 16px;
                    color: $deep-blue;
                    font-weight: 400;
                    border: 1px solid;
                    border-left: none;
                    border-right: none;
                    outline: none;
                    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;

                    &.colored {
                        background-color: $interactive-cyan-2;
                    }

                    &:focus-visible {
                        outline: 1px solid $focus-blue;
                        outline-offset: -1px;
                    }

                    &.table-header-cell {
                        display: grid;
                        grid-template: minmax(20px, auto) / 1fr 20px;
                        align-items: center;
                        gap: 5px;
                        border-color: $deep-blue;
                        border-top: none;
                        user-select: none;

                        svg {
                            width: 20px;
                            fill: $deep-blue;
                            transition: fill 0.2s ease-in-out;
                        }

                        &.clickable {
                            cursor: pointer;

                            &:hover {
                                color: $teal-90;
                                background-color: $bold-green-18;

                                svg {
                                    fill: $teal-90;
                                }
                            }
                        }
                      
                        &.bold {
                            font-weight: 700;
                        }

                        &.button-left {
                            display: grid;
                            grid-template-columns: fit-content(20px) 1fr;
                            grid-template-areas: "buttonIcon label";

                            .button-icon {
                                display: flex;
                                grid-area: buttonIcon;
                                align-self: center;

                                svg {
                                    width: 20px;
                                }
                            }
                            
                            .sorting-placeholder {
                                display: none;
                            }
                        }

                        &.button-right {
                            display: grid;
                            grid-template-columns: 1fr fit-content(20px);
                            grid-template-areas: "title buttonIcon";
                            
                            .button-icon {
                                display: flex;
                                grid-area: buttonIcon;
                                align-self: center;

                                svg {
                                    width: 20px;
                                }
                            }

                            .sorting-placeholder {
                                display: none;
                            }
                        }

                        &.no-header-linebreak {
                            white-space: nowrap;
                        }

                        &.highlighted {
                            font-weight: 700;
                            border-width: 3px;
                        }
                    }

                    &.table-data-cell {
                        border-color: $deep-blue-30;
                        
                        &.thick-bottom-line{
                            border-color: $deep-blue-70;      
                        }

                        &.single-line {
                            white-space: nowrap; 
                        }

                        &.sized {
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        &.input-style {
                            padding: 5px;
                            overflow: visible;

                            & > * {
                                min-width: auto;
                            }
                        }

                        &.plain {
                            border: initial;
                        }

                        &.highlighted {
                            font-weight: 700;
                        }

                        .divider-content {
                            height: 110px;
                        }
                    }
                }

                &.hoverable:hover, &.clickable:hover {
                    .table-data-cell {
                        color: $teal-90;
                        background-color: $bold-green-18;
                        border-color: $teal-90;
                    }

                    & + .table-row .table-data-cell {
                        border-top-color: $teal-90;
                    }

                    &:last-child .table-data-cell.highlighted {
                        border-bottom-color: $deep-blue;
                    }
                }

                &.clickable .table-data-cell {
                    cursor: pointer;
                }

                &:not(.no-header):nth-child(2) .table-data-cell {
                    border-top: none;
                }

                &:not(:last-child) .table-data-cell {
                    border-bottom: none;
                }

                &:last-child.no-border-last .table-data-cell {
                    border-bottom: none;
                }

                &:last-child .table-data-cell.highlighted {
                    border-bottom-width: 3px;
                    border-bottom-color: $deep-blue;
                }
            }
        }
    }

    .loading-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: -10px;
        right: -10px;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #A2B2BA60;
        visibility: hidden;

        &.loading-animation {
            &.loading {
                animation: appear 0.3s ease-in-out forwards;

                @keyframes appear {
                    0% {
                        opacity: 0;
                        visibility: hidden;
                    }

                    100% {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }

            &:not(.loading) {
                animation: disappear 0.3s ease-in-out forwards;

                @keyframes disappear {
                    0% {
                        opacity: 1;
                        visibility: visible;
                    }

                    100% {
                        opacity: 0;
                        visibility: hidden;
                    }
                }
            }
        }

        .loader-icon {
            width: 50px;
            height: 50px;
            animation: rotate 1s linear infinite;

            @keyframes rotate {
                100% {
                    transform: rotate(360deg);
                }
            }

            circle {
                fill: none;
                stroke: $deep-blue;
                stroke-width: 4px;
                stroke-dasharray: 50, 42;
                stroke-dashoffset: 8;
            }
        }
    }

    &.dark {
        &::before {
            background: linear-gradient(to right, $deep-blue, rgba($deep-blue, 0.0));
        }

        &::after {
            background: linear-gradient(to left, $deep-blue, rgba($deep-blue, 0.0));
        }

        .table-container {
            &::-webkit-scrollbar-thumb {
                background: $deep-blue-85;
            }

            &::-webkit-scrollbar-thumb:hover {
                background-color: $deep-blue-80;
            }

            .table-grid .table-row {
                .table-cell {
                    color: white;

                    &.table-header-cell {
                        border-color: white;

                        svg {
                            fill: white;
                        }

                        &.clickable:hover {
                            color: $bold-green;
                            background-color: $bold-green-12-db;

                            svg {
                                fill: $bold-green;
                            }
                        }
                    }

                    &.table-data-cell {
                        border-color: $deep-blue-70;
                    }
                }

                &.hoverable:hover, &.clickable:hover {
                    .table-data-cell {
                        color: $bold-green;
                        background-color: $bold-green-12-db;
                        border-color: $teal-90;
                    }

                    & + .table-row .table-data-cell {
                        border-top-color: $teal-90;
                    }

                    &:last-child .table-data-cell.highlighted {
                        border-bottom-color: white;
                    }
                }

                &:last-child .table-data-cell.highlighted {
                    border-bottom-color: white;
                }
            }
        }

        .loading-overlay {
            background-color: #1F262980;

            .loader-icon circle {
                stroke: white;
            }
        }
    }
}
