﻿.wizard {
    position: relative;

    @for $i from 1 to 10 {
        &.wizard-steps-#{$i} {
            .wizard-steps {
                grid-template-columns: repeat($i, 1fr);
            }

            .wizard-line-container {
                left: calc(50% / #{$i});
                width: calc(100% - (100% / #{$i}));
            }
        }
    }

    .wizard-steps {
        position: relative;
        z-index: 1;
        display: grid;
        grid-template-rows: 1fr;
        gap: 5px;

        .wizard-step {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 15px;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

            .wizard-step-number {
                position: relative;
                height: 50px;
                width: 50px;
                z-index: 1;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 18px;
                font-weight: 700;
                color: $deep-blue-50;
                background-color: white;
                border: 3px solid $deep-blue-50;
                border-radius: 50%;
                outline: none;
                user-select: none;
                transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

                &:focus-visible::after {
                    position: absolute;
                    content: "";
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    z-index: -1;
                    outline: 1px solid $focus-blue;
                    outline-offset: 4px;
                }
            }

            .wizard-step-name {
                font-size: 16px;
                font-weight: 700;
                color: $deep-blue-50;
                user-select: none;
                transition: color 0.5s ease-in-out;
            }

            &.passed {
                cursor: pointer;

                .wizard-step-number {
                    color: $interactive-blue;
                    border-color: $interactive-blue;
                }

                .wizard-step-name {
                    color: $interactive-blue;
                }

                &:hover {
                    .wizard-step-number {
                        color: white;
                        background-color: $teal-90;
                        border-color: $teal-90;
                    }

                    .wizard-step-name {
                        color: $teal-90;
                    }
                }
            }

            &.active {
                .wizard-step-number {
                    color: white;
                    background-color: $interactive-blue;
                    border-color: $interactive-blue;
                }

                .wizard-step-name {
                    color: $interactive-blue;
                }
            }
        }
    }

    .wizard-line-container {
        position: absolute;
        top: 23px;
        height: 3px;

        .wizard-line {
            height: 100%;
            background-color: $interactive-blue;
            transition: width 0.3s ease-in-out;
        }
    }

    &.dark {
        .wizard-steps .wizard-step {
            .wizard-step-number {
                color: $deep-blue-50;
                background-color: $deep-blue;
                border: 3px solid $deep-blue-50;
            }

            .wizard-step-name {
                color: $deep-blue-50;
            }

            &.passed {
                .wizard-step-number {
                    color: $interactive-coral;
                    border-color: $interactive-coral;
                }

                .wizard-step-name {
                    color: $interactive-coral;
                }

                &:hover {
                    .wizard-step-number {
                        color: $deep-blue;
                        background-color: $bold-green;
                        border-color: $bold-green;
                    }

                    .wizard-step-name {
                        color: $bold-green;
                    }
                }
            }

            &.active {
                .wizard-step-number {
                    color: $deep-blue;
                    background-color: $interactive-coral;
                    border-color: $interactive-coral;
                }

                .wizard-step-name {
                    color: $interactive-coral;
                }
            }
        }

        .wizard-line-container .wizard-line {
            background-color: $interactive-coral;
        }
    }
}
