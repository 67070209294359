﻿.grid-container {
    display: grid;
    background-color: inherit;

    @mixin grid-columns($max-width) {
        @for $i from 1 through 20 {
            &.grid-columns#{$max-width}-#{$i} {
                grid-template: 1fr / repeat($i, minmax(0, 1fr));
            }
        }
    }

    @include grid-columns("");

    @media (max-width: 992px) {
        @include grid-columns(-992);
    }

    @media (max-width: 768px) {
        @include grid-columns(-768);
    }

    @media (max-width: 600px) {
        @include grid-columns(-600);
    }

    @media (max-width: 480px) {
        @include grid-columns(-480);
    }
}
