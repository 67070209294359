﻿.general {
    margin-top: 30px;

    .form-group {
        display: grid;
        grid-template: auto / repeat(12, 1fr);
        gap: 15px;

        &:not(:last-child) {
            margin-bottom: 40px;
        }

        .length-3 {
            grid-column: span 3;

            @media (max-width: $general-grid-small) {
                grid-column: span 4;
            }

            @media (max-width: $general-grid-tablet) {
                grid-column: span 6;
            }

            @media (max-width: $general-grid-mobile) {
                grid-column: span 12;
            }
        }

        .length-4 {
            grid-column: span 4;
            display: grid;
            gap: 15px;

            @media (max-width: $general-grid-tablet) {
                grid-column: span 6;
            }

            @media (max-width: $general-grid-mobile) {
                grid-column: span 12;
            }
        }

        .length-6 {
            grid-column: span 6;

            @media (max-width: $general-grid-small) {
                grid-column: span 4;
            }

            @media (max-width: $general-grid-tablet) {
                grid-column: span 6;
            }

            @media (max-width: $general-grid-mobile) {
                grid-column: span 12;
            }
        }

        .length-9 {
            grid-column: span 9;

            @media (max-width: $general-grid-small) {
                grid-column: span 12;
            }
        }

        .length-12 {
            grid-column: span 12;
        }
    }
}
