﻿.snackbar {
    .snackbar-content-container {
        padding-top: 5px;

        .snackbar-content {
            display: grid;
            grid-template-columns: auto 1fr auto;
            align-items: start;
            gap: 20px;
            padding: 15px 20px;
            font-size: 18px;
            color: $deep-blue;
            background-color: white;
            border-radius: 1px;
            box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);

            svg {
                width: 20px;
                fill: black;
            }

            .snackbar-close-button {
                display: flex;
                align-items: center;
                outline: none;
                cursor: pointer;

                &:focus-visible {
                    outline: 1px solid $focus-blue;
                }
            }
        }
    }

    &.opening {
        animation: snackbar-open 0.5s ease-in-out forwards;

        @keyframes snackbar-open {
            0% {
                height: 0;
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }
    }

    &.closing {
        animation: snackbar-close 0.5s ease-in-out forwards;

        @keyframes snackbar-close {
            0% {
                opacity: 1;
            }

            100% {
                opacity: 0;
                height: 0;
            }
        }
    }

    &.closed {
        height: 0;
        opacity: 0;
    }

    &.error .snackbar-content-container .snackbar-content > svg {
        fill: $feedback-red-light;
    }

    &.info .snackbar-content-container .snackbar-content > svg {
        fill: black;
    }

    &.success .snackbar-content-container .snackbar-content > svg {
        fill: $feedback-green-light;
    }

    &.warning .snackbar-content-container .snackbar-content > svg {
        fill: $feedback-yellow-light;
    }

    &.dark {
        .snackbar-content-container .snackbar-content {
            color: white;
            background-color: $bold-green-12-db;

            svg {
                fill: white;
            }
        }

        &.error .snackbar-content-container .snackbar-content > svg {
            fill: $feedback-red-dark;
        }

        &.info .snackbar-content-container .snackbar-content > svg {
            fill: white;
        }

        &.success .snackbar-content-container .snackbar-content > svg {
            fill: $feedback-green-dark;
        }

        &.warning .snackbar-content-container .snackbar-content > svg {
            fill: $feedback-yellow-dark;
        }
    }
}
