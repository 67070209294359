﻿.headline {
    max-width: 600px;
    display: flex;

    .headline-bar {
        width: 6px;
        margin: 11px 20px 11px 0;
        flex-shrink: 0;
        background: linear-gradient($bold-green, $bold-blue);
        animation: headline-bar-appear 0.6s ease-in-out forwards;

        @keyframes headline-bar-appear {
            0% {
                opacity: 0;
                transform: translateY(-100px) scaleY(0);
            }

            100% {
                opacity: 1;
                transform: translateY(0) scaleY(1);
            }
        }

        &.with-label {
            margin-top: 4px;
        }
    }

    .headline-text {
        color: white;

        .headline-label {
            padding-bottom: 15px;
            font-size: 18px;
            font-weight: 700;
            text-transform: uppercase;
            animation: headline-label-appear 0.6s ease-in-out forwards;

            @keyframes headline-label-appear {
                0% {
                    opacity: 0;
                    transform: translateY(40px);
                }

                100% {
                    opacity: 1;
                    transform: translateY(0);
                }
            }
        }

        .headline-line {
            font-size: 45px;
            font-weight: 900;
            animation: headline-line-appear 0.6s ease-in-out forwards;

            @keyframes headline-line-appear {
                0% {
                    opacity: 0;
                    transform: translateY(60px);
                }

                100% {
                    opacity: 1;
                    transform: translateY(0);
                }
            }
        }
    }
}
