.checkbox-container {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 8px;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    .checkbox {
        position: relative;
        height: 16px;
        min-width: 16px;
        border: 2px solid $deep-blue-70;
        background-color: $interactive-blue-12;
        outline: none;
        transition: background 0.3s ease-in-out, border 0.3s ease-in-out;

        .checkmark {
            position: absolute;
            top: 0px;
            left: 4px;
            height: 12px;
            width: 8px;
            transform: rotate(40deg);

            &::before, &::after {
                position: absolute;
                content: "";
                background-color: $deep-blue-70;
            }

            &::before {
                width: 0;
                height: 2px;
                top: calc(100% - 2px);
                left: 0;
                transition: width 0.15s ease-in-out 0.15s, background 0.3s ease-in-out 0s;
            }

            &::after {
                width: 2px;
                height: 0;
                bottom: 0;
                left: calc(100% - 2px);
                transition: height 0.15s ease-in-out 0s, background 0.3s ease-in-out 0s;
            }
        }

        &.checked .checkmark {
            &::before {
                width: 100%;
                transition: width 0.15s ease-in-out 0s, background 0.3s ease-in-out 0s;
            }

            &::after {
                height: 100%;
                transition: height 0.15s ease-in-out 0.15s, background 0.3s ease-in-out 0s;
            }
        }
    }

    .checkbox-label {
        font-size: 16px;
        color: $deep-blue-70;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        user-select: none;
        transition: color 0.3s ease-in-out;
    }

    &:not(.disabled):hover, &:not(.disabled):active {
        .checkbox {
            background-color: $bold-green-20;
            border-color: $teal;

            .checkmark {
                &::before, &::after {
                    background-color: $teal;
                }
            }
        }

        .checkbox-label {
            color: $deep-blue;
        }
    }

    &:not(:disabled):focus-visible .checkbox {
        outline: 1px solid $focus-blue;
        outline-offset: 1px;
    }

    &:not(.disabled) .checkbox.checked + .checkbox-label {
        color: $deep-blue;
    }

    &.disabled {
        cursor: not-allowed;

        .checkbox {
            background-color: transparent;
            border-color: $deep-blue-50;

            .checkmark {
                &::before, &::after {
                    background-color: $deep-blue-50;
                }
            }
        }

        .checkbox-label {
            color: $deep-blue-50;
        }
    }

    &.dark {
        .checkbox {
            background-color: $deep-blue-85;
            border-color: $deep-blue-30;

            .checkmark {
                &::before {
                    background-color: $deep-blue-30;
                }

                &::after {
                    background-color: $deep-blue-30;
                }
            }
        }

        .checkbox-label {
            color: $deep-blue-30;
        }

        &:not(.disabled):hover, &:not(.disabled):active {
            .checkbox {
                background-color: $bold-green-12-db;
                border-color: $bold-green;

                .checkmark {
                    &::before, &::after {
                        background-color: $bold-green;
                    }
                }
            }

            .checkbox-label {
                color: $deep-blue-8;
            }
        }

        &:not(.disabled) .checkbox.checked + .checkbox-label {
            color: $deep-blue-8;
        }

        &.disabled {
            .checkbox-label {
                color: $deep-blue-60;
            }

            .checkbox {
                background-color: transparent;
                border-color: $deep-blue-60;

                .checkmark {
                    &::before, &::after {
                        background-color: $deep-blue-60;
                    }
                }
            }
        }
    }
}
