﻿footer {
    padding: 30px 0;
    background-color: $deep-blue;

    .footer-container {
        display: grid;
        grid-template-columns: 1fr auto;
        grid-gap: 20px;

        .footer-social-links {
            display: flex;
            justify-content: flex-end;
            gap: 15px;

            @media (min-width: $footer-one-column-max + 1) {
                grid-column: 2;
            }

            @media (max-width: $footer-one-column-max) {
                grid-column: 1;
                justify-content: flex-start;
            }

            .social-link {
                outline: none;
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

                svg {
                    width: 20px;
                    fill: white;
                }

                &:focus-visible svg {
                    outline: 1px solid $focus-blue;
                    outline-offset: 1px
                }
            }
        }

        .footer-contact {
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;

            @media (min-width: $footer-one-column-max + 1) {
                grid-column: 2;
            }

            @media (max-width: $footer-one-column-max) {
                grid-column: 1;
                justify-content: flex-start;
            }

            .footer-contact-button {
                display: flex;
                align-items: center;
                gap: 10px;
                padding: 10px 25px;
                font-weight: 700;
                color: inherit;
                text-decoration: none;
                background-color: white;
                outline: none;
                cursor: pointer;
                user-select: none;
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                transition: background-color 0.3s ease-in-out;

                &:hover {
                    background-color: $bold-green;
                }

                &:focus-visible {
                    outline: 1px solid $focus-blue;
                    outline-offset: 2px
                }

                svg {
                    width: 20px;
                }
            }
        }

        .footer-links {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 15px;

            &.footer-legal {
                @media (min-width: $footer-two-rows-min) {
                    grid-row: 2;
                }

                @media (min-width: $footer-one-column-max + 1) and (max-width: $footer-two-rows-min - 1) {
                    grid-row: 3;
                    grid-column: 1 / span 2;
                }

                @media (max-width: $footer-one-column-max) {
                    grid-column: 1;
                    flex-direction: column;
                    align-items: flex-start;
                }
            }

            &.footer-global-website {
                @media (min-width: $footer-one-column-max + 1) {
                    grid-row: 1;
                }

                @media (max-width: $footer-one-column-max) {
                    display: none;
                }
            }

            &.footer-siemens {
                @media (min-width: $footer-two-rows-min) {
                    display: none;
                }

                @media (min-width: $footer-one-column-max + 1) and (max-width: $footer-two-rows-min - 1) {
                    grid-row: 2;
                }

                @media (max-width: $footer-one-column-max) {
                    display: none;
                }
            }

            .footer-link {
                font-size: 14px;
                color: white;
                text-decoration-color: transparent;
                text-underline-position: under;
                text-underline-offset: 4px;
                outline: none;
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                transition: text-decoration-color 0.3s ease-in-out;

                &:hover {
                    text-decoration-color: white;
                }

                &:focus-visible {
                    outline: 1px solid $focus-blue;
                    outline-offset: 2px
                }

                &.no-link {
                    text-decoration-color: transparent;
                    cursor: default;
                }

                &.footer-global-website-link {
                    @media (min-width: $footer-one-column-max + 1) {
                        display: none;
                    }
                }

                &.footer-siemens-link {
                    @media (min-width: $footer-one-column-max + 1) and (max-width: $footer-two-rows-min - 1) {
                        display: none;
                    }
                }
            }
        }
    }
}
