.button-with-popup {
    align-items: center;
    .button {
        z-index: unset;
    }

    .button-with-popup-button {
        position: relative;


        .button-with-popup-dropdown {
            position: absolute;
            top: calc(100% + 10px);
            right: -5px;
            z-index: 1;
            min-width: 200px;
            color: white;
            background-color: $bold-green-12-db;
            box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
            cursor: default;

            &.opening {
                animation: button-with-popup-dropdown-open 0.4s cubic-bezier(.75,-.02,.2,.97) forwards;

                @keyframes button-with-popup-dropdown-open {
                    0% {
                        transform: translate(0, 20px);
                        opacity: 0;
                    }

                    100% {
                        transform: translate(0);
                        opacity: 1;
                    }
                }
            }

            &.closing {
                animation: button-with-popup-dropdown-close 0.4s cubic-bezier(.75,-.02,.2,.97) forwards;

                @keyframes button-with-popup-dropdown-close {
                    0% {
                        transform: translate(0);
                        opacity: 1;
                    }

                    100% {
                        transform: translate(0, 20px);
                        opacity: 0;
                    }
                }
            }

            &.closed {
                display: none;
            }

            &::before {
                position: absolute;
                content: "";
                top: -10px;
                right: 30px;
                border-width: 0 10px 10px;
                border-style: solid;
                border-color: transparent transparent $bold-green-12-db;
            }

            .button-with-popup-dropdown-action {
                display: flex;
                align-items: center;
                padding: 5px 20px 5px 20px;
                color: $interactive-coral;
                outline: none;
                cursor: pointer;
                user-select: none;
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                transition: color 0.3s ease-in-out;

                
                &:first-child {
                    padding-top: 15px
                }

                &:last-child {
                    padding-bottom: 15px
                }

                &:hover {
                    color: $bold-green;

                    svg {
                        fill: $bold-green;
                    }
                }

                &:focus-visible {
                    outline: 1px solid $focus-blue;
                    outline-offset: -1px;
                }

                svg {
                    margin-right: 5px;
                    fill: $interactive-coral;
                    transition: fill 0.3s ease-in-out;
                }
            }
        }
    }
}
