.toggle {
    $track-height: 20px;
    $track-width: 48px;
    $thumb-size: 28px;

    position: relative;
    height: $track-height;
    width: $track-width;
    padding: calc(($thumb-size - $track-height) / 2);
    outline: none;
    cursor: pointer;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &::before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background-color: $deep-blue-8;
        border-radius: calc($track-height / 2);
        transition: background 0.3s ease-out;
    }

    &::after {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        display: block;
        width: $thumb-size;
        height: $thumb-size;
        background-color: $interactive-cyan;
        border-radius: 100%;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
        transition: left 0.3s ease-in-out, background 0.3s ease-in-out;
    }

    &.on {
        &::before {
            background-color: $bold-green;
            transition: background 0.3s ease-in;
        }

        &::after {
            left: $track-width - $track-height;
        }
    }

    &:not(.disabled):hover, &:not(.disabled):active {
        &::after {
            background-color: $teal-90;
        }
    }

    &.disabled {
        cursor: not-allowed;

        &::after {
            background-color: $light-grey;
        }
    }

    &:focus-visible {
        outline: 1px solid $focus-blue;
        outline-offset: 1px;
    }

    &.dark {
        &::before {
            background-color: $bright-sand;
        }

        &::after {
            background-color: white;
        }

        &.on {
            &::before {
                background-color: $bold-green;
            }
        }

        &:not(.disabled):hover, &:not(.disabled):active {
            &:not(.on) {
                &::before {
                    background-color: white;
                }

                &::after {
                    background-color: white;
                }
            }

            &.on {
                &::before {
                    background-color: $bold-green;
                }

                &::after {
                    background-color: $deep-blue-20;
                }
            }
        }

        &.disabled {
            &::after {
                background-color: $deep-blue-30;
            }

            &:not(.on) {
                &::before {
                    background-color: $deep-blue-55;
                }
            }

            &.on {
                &::before {
                    background-color: $teal-95;
                }
            }
        }
    }
}
