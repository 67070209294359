﻿.accordion {
    border: 1px solid $deep-blue-70;
    border-right: none;
    border-left: none;
    outline: none;
    transition: background 0.3s ease-in-out, border 0.3s ease-in-out;

    &:focus-visible {
        outline: 1px solid #36ABFF;
        outline-offset: 3px;
    }

    .header {
        display: grid;
        grid-template-rows: 30px;
        grid-template-columns: 1fr auto;
        align-items: center;
        padding: 25px;
        cursor: pointer;
        user-select: none;

        .header-icon {
            display: flex;
            justify-self: center;

            svg {
                width: 20px;
                fill: $deep-blue;
            }
        }

        .header-label-container {
            position: relative;
            color: $deep-blue;

            .header-label {
                position: relative;
                font-size: 16px;
                font-weight: 900;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                transform: translateY(0);
                transition: font-size 0.5s ease-in-out, transform 0.5s ease-in-out;
            }

            .header-closed-label {
                position: absolute;
                width: calc(100% - 10px);
                margin-left: 10px;
                font-size: 16px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                transform: translateY(18px);
                opacity: 0;
                transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
            }
        }

        .header-close-icon {
            height: 20px;
            width: 20px;
            display: grid;
            justify-self: center;

            &::before, &::after {
                content: "";
                display: block;
                height: 2px;
                width: 16px;
                grid-row: 1 / span 1;
                grid-column: 1 / span 1;
                align-self: center;
                justify-self: center;
                background-color: $deep-blue;
                transition: transform 0.4s ease-in-out 0.1s;
            }

            &::before {
                transform: rotate(180deg);
            }

            &::after {
                transform: rotate(180deg);
            }
        }

        &.with-icon {
            grid-template-columns: auto 1fr auto;

            .header-label-container {
                padding-left: 25px;
            }
        }
    }

    .header-content-container .header-content {
        padding: 0 20px 30px;
    }

    &.closing, &.closed {
        .header {
            .header-label-container {
                .header-label:not(:first-child) {
                    transform: translateY(-12px);
                }

                .header-closed-label {
                    transform: translateY(8px);
                    opacity: 1;
                }
            }

            .header-close-icon {
                &::before {
                    transform: rotate(0deg);
                }

                &::after {
                    transform: rotate(90deg);
                }
            }
        }
    }

    &.opening .header-content-container {
        animation: accordion-open 0.5s ease-in-out forwards;

        @keyframes accordion-open {
            0% {
                height: 0;
            }
        }
    }

    &.closing .header-content-container {
        animation: accordion-close 0.5s ease-in-out forwards;

        @keyframes accordion-close {
            100% {
                height: 0;
            }
        }
    }

    &:not(.open) {
        overflow: hidden;
    }

    &.closed .header-content-container {
        height: 0;
    }

    &:not(:last-child) {
        border-bottom: none;
    }

    &:hover {
        background-color: $interactive-cyan-2;
        border-color: $teal-90;

        &:not(:last-child) ~ .accordion {
            border-top-color: $teal-90;
        }
    }

    &.dark {
        border-color: $deep-blue-20;

        .header {
            .header-icon svg {
                fill: white;
            }

            .header-label-container {
                color: white;
            }

            .header-close-icon {
                &::before, &::after {
                    background-color: white;
                }
            }
        }

        .header-content-container {
            color: white;
        }

        &:hover {
            background-color: $bold-green-12-db;
            border-color: $bold-green;

            &:not(:last-child) ~ .accordion {
                border-top-color: $bold-green;
            }
        }
    }
}
