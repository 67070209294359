header {
    position: fixed;
    top: 0;
    z-index: 1;
    width: 100%;
    font-size: 15px;
    color: $deep-blue;

    .header-mask {
        display: flex;
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 10000;
        height: 149px;
        align-items: center;
        justify-content: center;
        background-color: #2d373c99;
        transition: height 0.5s ease-in-out;

        &.opening {
            animation: header-mask-appear 0.2s ease-in-out forwards;

            @keyframes header-mask-appear {
                0% {
                    opacity: 0;
                }

                100% {
                    opacity: 1;
                }
            }
        }

        &.closing {
            animation: header-mask-disappear 0.2s ease-in-out forwards;

            @keyframes header-mask-disappear {
                0% {
                    opacity: 1;
                }

                100% {
                    opacity: 0;
                }
            }
        }

        &.closed {
            display: none;
        }

        &.hide-tabs {
            height: 109px;
        }
    }

    .main-row-container {
        position: relative;
        z-index: 1;
        background-color: white;
        pointer-events: auto;

        .main-row {
            position: relative;
            z-index: 2;
            height: 60px;
            display: flex;

            .skip-button {
                position: absolute;
                top: 10px;
                left: 50%;
                z-index: 1;
                padding: 10px 25px;
                font-size: 16px;
                font-weight: 700;
                color: $deep-blue;
                background: linear-gradient(to right, $bold-green, $bold-blue);
                outline: 1px solid $focus-blue;
                outline-offset: 1px;
                cursor: default;
                user-select: none;
                white-space: nowrap;
                transform: translate(-50%, -100px);

                &:focus {
                    transform: translate(-50%, 0);
                }
            }

            .header-row-container {
                display: flex;
                align-items: center;

                .siemens-logo {
                    height: 20px;
                    margin: 0 20px;
                    user-select: none;

                    @media (max-width: $hide-siemens-logo-max) {
                        display: none;
                    }
                }

                .title {
                    margin: 0 20px 0 10px;
                    font-size: 18px;
                    user-select: none;

                    @media (max-width: $hide-title-max) {
                        display: none;
                    }
                }
            }

            .action-container {
                display: flex;
                flex-grow: 1;
                justify-content: flex-end;
                margin-right: 10px;

                .menu-icon {
                    height: 17px;
                    width: 18px;
                    margin: 0 5px;
                    padding: 6px;
                    cursor: pointer;

                    @media (min-width: $hide-tabs-max + 1) {
                        display: none;
                    }
                }
            }
        }
    }

    .addtitional-rows-container {
        pointer-events: auto;
        transition: transform 0.5s ease-in-out;
        will-change: transform;

        @mixin hide-secondary-row {
            transform: translateY(-40px);

            .secondary-row-container .content-container.header-content-container .secondary-row {
                opacity: 0;
            }
        }

        @media (max-width: $hide-tabs-max) {
            @include hide-secondary-row;
        }

        &.hiden {
            @include hide-secondary-row;
        }

        .secondary-row-container {
            background-color: white;

            .content-container.header-content-container .secondary-row {
                display: flex;
                height: 40px;
                transition: opacity 0.5s ease-in-out;
                opacity: 1;
                will-change: opacity;
            }
        }
    }

    .header-mega-menu {
        pointer-events: auto;

        @media (min-width: 768px) {
            display: none;
        }
    }
}
