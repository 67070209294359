﻿.carousel {
    .slick-slider {
        margin-bottom: 35px;

        .slick-arrow {
            height: 50px;
            width: 50px;
            z-index: 1;
            background-color: $interactive-blue;
            border-radius: 50%;
            box-shadow: 0 2px 2px 0 rgba(black, 0.06), 0 6px 8px 0 rgba(black, 0.08);
            transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;

            &::before {
                font-size: 30px;
                font-family: SiemensIcons;
                color: white;
                opacity: 1;
            }

            &:focus-visible {
                outline: 1px solid $focus-blue;
                outline-offset: 2px;
            }

            &.slick-disabled {
                background: $deep-blue-30;
                cursor: not-allowed;

                &::before {
                    color: $deep-blue-8;
                }
            }

            &:not(.slick-disabled):hover {
                background: $teal-90;
            }

            &.slick-prev {
                left: 10px;

                &::before {
                    content: '\E902';
                }
            }

            &.slick-next {
                right: 10px;

                &::before {
                    content: '\E903';
                }
            }
        }

        .slick-dots {
            bottom: -30px;

            li {
                margin: 0;

                button {
                    &::before {
                        font-size: 12px;
                        color: $deep-blue;
                    }

                    &:hover::before {
                        color: $teal-90;
                    }

                    &:focus-visible {
                        outline: 1px solid $focus-blue;
                    }
                }
            }
        }

        .slick-list .slick-track .slick-slide > div > div {
            outline: none;
        }
    }

    &.dark .slick-slider .slick-arrow {
        background-color: $interactive-coral;

        &::before {
            color: $deep-blue;
        }

        &.slick-disabled {
            background: $deep-blue-55;

            &::before {
                color: $deep-blue-30;
            }
        }

        &:not(.slick-disabled):hover {
            background: $bold-green;
        }
    }
}
