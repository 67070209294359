.search {
    position: relative;
    height: 100%;

    .search-input-field {
        display: flex;

        input {
            width: calc(100% - 28px);
            margin: 0;
            padding: 16px 14px 6px;
            font: inherit;
            color: $deep-blue-70;
            background-color: $interactive-cyan-8;
            border: none;
            border-bottom: 1px solid $deep-blue-70;
            border-radius: 0;
            outline: none;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border 0.3s ease-in-out;

            &::placeholder {
                color: $deep-blue-70;
                user-select: none;
                transition: color 0.3s ease-in-out;
            }

            &:focus, &:hover {
                color: $deep-blue;
                background-color: $bold-green-18;
                border-color: $green;

                &::placeholder {
                    color: $deep-blue;
                }
            }

            &:disabled {
                color: $deep-blue-50 !important;
                -webkit-text-fill-color: $deep-blue-50;
                background-color: $interactive-cyan-8 !important;
                border-color: transparent;
                cursor: not-allowed;
            }

            &:focus + .search-label {
                bottom: 24px;
                font-size: 10px;
                color: $deep-blue;
            }

            &:hover + .search-label {
                color: $deep-blue;
            }

            &:disabled + .search-label {
                color: $deep-blue-50 !important;
            }
        }

        .search-label {
            position: absolute;
            top: 2px;
            bottom: 2px;
            display: flex;
            align-items: center;
            margin-left: 14px;
            font-size: 16px;
            color: $deep-blue-70;
            user-select: none;
            pointer-events: none;
            transition: color 0.3s ease-in-out, font-size 0.15s ease-in-out, bottom 0.15s ease-in-out;

            &.filled {
                bottom: 24px;
                font-size: 10px;
            }

            &.required::before {
                position: absolute;
                content: "*";
                right: calc(100% + 2px);
            }
        }

        .search-icon {
            position: relative;
            z-index: 1;
            display: flex;
            align-items: center;
            padding: 0 11px;
            background: linear-gradient($bold-green, $bold-blue);
            cursor: pointer;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

            svg {
                width: 20px;
                fill: $deep-blue;
            }

            &::before {
                position: absolute;
                content: "";
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: -1;
                background-color: $light-green;
                opacity: 0;
                transition: opacity 0.3s ease-in-out;
            }
        }
    }

    .search-results {
        position: absolute;
        top: 100%;
        z-index: 2;
        max-height: 0;
        width: 100%;
        overflow-y: auto;
        background-color: $interactive-cyan-8;
        transition: max-height 0.5s ease-in-out, background-color 0.5s ease-in-out;

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-track {
            background-color: inherit;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $interactive-cyan-10;
        }

        .search-result {
            position: relative;
            z-index: 1;
            padding: 5px 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            user-select: none;
            cursor: pointer;
        }
    }

    &.open {
        .search-results {
            &.search-results-1 {
                max-height: 29px;
            }

            &.search-results-2 {
                max-height: 58px;
            }

            &.search-results-3 {
                max-height: 87px;
            }

            &.search-results-4 {
                max-height: 116px;
            }

            &.search-results-5 {
                max-height: 145px;
            }
        }
    }

    &.open, &:hover {
        .search-results {
            background-color: $bold-green-18;

            &::-webkit-scrollbar-thumb {
                background-color: $light-green-70;
            }

            &::-webkit-scrollbar-thumb:hover {
                background-color: $light-green-50;
            }

            .search-result.selected {
                background-color: $light-green;
            }
        }
    }

    &.condensed .search-input-field {
        input {
            padding: 6px 14px;
        }

        .search-icon {
            padding: 0 6px;
        }
    }

    &.disabled .search-input-field .search-icon {
        cursor: not-allowed;
    }

    &:not(.disabled) .search-input-field .search-icon:hover::before {
        opacity: 1;
    }

    &.error:not(.disabled):not(:focus-within) {
        .search-input-field {
            input {
                color: $deep-blue;
                background-color: $light-red;
                border-color: $feedback-red-dark;

                &::placeholder {
                    color: $deep-blue;
                }
            }

            .search-label {
                color: $feedback-red-dark !important;
            }
        }

        .search-results {
            background-color: $light-red;

            &::-webkit-scrollbar-thumb {
                background-color: $feedback-red-dark-40;
            }

            .search-result.selected {
                background-color: inherit !important;
            }
        }
    }

    &.dark {
        .search-input-field {
            input {
                color: $deep-blue-8;
                background-color: $interactive-coral-12-db;
                border-color: $deep-blue-50;

                &::placeholder {
                    color: $deep-blue-50;
                }

                &:focus, &:hover {
                    color: $deep-blue-8;
                    background-color: $bold-green-12-db;
                    border-color: $bold-green;

                    &::placeholder {
                        color: $deep-blue-8;
                    }
                }

                &:disabled {
                    color: $deep-blue-60 !important;
                    -webkit-text-fill-color: $deep-blue-60;
                    background-color: $interactive-coral-12-db !important;
                    border-color: transparent;
                }

                &:hover + .search-label {
                    color: $deep-blue-8;
                }

                &:hover + .search-label.filled, &:focus + .search-label, &:focus:hover + .search-label {
                    color: $bold-green;
                }

                &:disabled + .search-label, &:disabled:hover + .search-label {
                    color: $deep-blue-60 !important;
                }
            }

            .search-label {
                color: $deep-blue-50;
            }

            .search-icon::before {
                background-color: $light-green-40;
            }
        }

        .search-results {
            background-color: $interactive-coral-12-db;

            &::-webkit-scrollbar-thumb {
                background-color: $deep-blue-80;
            }

            &::-webkit-scrollbar-thumb:hover {
                background-color: $deep-blue-60;
            }
        }

        &.open, &:hover {
            .search-results {
                background-color: $bold-green-12-db;

                .search-result.selected {
                    background-color: $teal;
                }
            }
        }

        &.error:not(.disabled):not(:focus-within) {
            .search-input-field {
                input {
                    color: $deep-blue-8;
                    background-color: $dark-red;
                    border-color: $medium-red;

                    &::placeholder {
                        color: $deep-blue-8;
                    }
                }

                .search-label {
                    color: $medium-red !important;
                }
            }

            .search-results {
                background-color: $dark-red;

                &::-webkit-scrollbar-thumb {
                    background-color: $dark-red-92;
                }
            }
        }
    }
}
