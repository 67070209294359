.header-tabs {
    position: relative;
    display: flex;
    margin-left: 10px;

    .tab {
        display: flex;
        align-items: center;
        padding: 0 10px;
        font-weight: 700;
        outline: none;
        cursor: pointer;
        user-select: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

        &:focus-visible {
            outline: 1px solid $focus-blue;
            outline-offset: -1px;
        }
    }

    .header-tab-underline {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: -1;
        width: 0;
        border-bottom: 2px solid $siemens-petrol;
        transition: left 0.5s ease-in-out, width 0.5s ease-in-out;
    }
}
