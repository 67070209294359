.file-upload {
    align-items: center;
    background-color: $interactive-cyan-8;
    border-bottom: 1px solid $deep-blue-70;
    display: flex;
    flex-direction: column;
    gap: 24px;
    min-width: 205px;
    padding: 67px 14px 41px;
    transition: border 0.3s ease-in-out;

    .file-upload-label {
        align-items: center;
        color: $deep-blue;
        display: flex;
        flex-direction: column;
        font-size: 16px;
        font-weight: 700;
        gap: 8px;
        line-height: 24px;

        svg {
            fill: $deep-blue;
            width: 40px;
        }
    }

    input[type=file] {
        display: none;
    }

    &.highlighted:not(:focus-within):not(:hover) {
        border-color: $interactive-blue;
    }

    &.dark {
        background-color: $interactive-coral-12-db;
        border-color: $deep-blue-50;
        color: $deep-blue-8;

        .file-upload-label {
            color: white;

            svg {
                fill: white;
            }
        }

        &.highlighted:not(:focus-within):not(:hover) {
            border-color: $interactive-coral;
        }
    }

    .error-message {
        color: $feedback-red-dark;
    }
}
