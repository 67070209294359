﻿.loader-container {
    position: relative;
    background-color: inherit;

    .loading-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #A2B2BA60;
        visibility: hidden;

        &.loading-animation {
            &.loading {
                animation: appear 0.3s ease-in-out forwards;

                @keyframes appear {
                    0% {
                        opacity: 0;
                        visibility: hidden;
                    }

                    100% {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }

            &:not(.loading) {
                animation: disappear 0.3s ease-in-out forwards;

                @keyframes disappear {
                    0% {
                        opacity: 1;
                        visibility: visible;
                    }

                    100% {
                        opacity: 0;
                        visibility: hidden;
                    }
                }
            }
        }

        &.transparent {
            background-color: transparent;
        }

        .loader-icon {
            width: 50px;
            height: 50px;
            animation: rotate 1s linear infinite;

            @keyframes rotate {
                100% {
                    transform: rotate(360deg);
                }
            }

            circle {
                fill: none;
                stroke: $deep-blue;
                stroke-width: 4px;
                stroke-dasharray: 50, 42;
                stroke-dashoffset: 8;
            }
        }
    }

    &.dark .loading-overlay {
        background-color: #1F262980;

        .loader-icon circle {
            stroke: white;
        }
    }
}
