﻿.banner {
    .banner-content-container {
        background-color: white;

        .content-container .banner-content {
            display: grid;
            grid-template-columns: auto 1fr auto;
            align-items: center;
            gap: 15px;
            padding: 10px 0;

            svg {
                width: 30px;
            }

            .banner-text {
                padding: 10px 0;
                font-size: 18px;
                color: $deep-blue;
            }

            .banner-close-button {
                display: flex;
                align-items: center;
                outline: none;
                cursor: pointer;

                svg {
                    width: 20px;
                    fill: black;
                }

                &:focus-visible {
                    outline: 1px solid $focus-blue;
                }
            }
        }
    }

    &.opening {
        animation: banner-open 0.5s ease-in-out forwards;

        @keyframes banner-open {
            0% {
                height: 0;
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }
    }

    &.closing {
        animation: banner-close 0.5s ease-in-out forwards;

        @keyframes banner-close {
            0% {
                opacity: 1;
            }

            100% {
                opacity: 0;
                height: 0;
            }
        }
    }

    &.closed {
        height: 0;
        opacity: 0;
    }

    &.error .banner-content-container .content-container .banner-content > svg {
        fill: $feedback-red-light;
    }

    &.info .banner-content-container .content-container .banner-content > svg {
        fill: black;
    }

    &.success .banner-content-container .content-container .banner-content > svg {
        fill: $feedback-green-light;
    }

    &.warning .banner-content-container .content-container .banner-content > svg {
        fill: $feedback-yellow-light;
    }

    &.dark {
        .banner-content-container {
            background-color: $bold-green-12-db;

            .content-container .banner-content {
                .banner-text {
                    color: white;
                }

                .banner-close-button svg {
                    fill: white;
                }
            }
        }

        &.error .banner-content-container .content-container .banner-content > svg {
            fill: $feedback-red-dark;
        }

        &.info .banner-content-container .content-container .banner-content > svg {
            fill: white;
        }

        &.success .banner-content-container .content-container .banner-content > svg {
            fill: $feedback-green-dark;
        }

        &.warning .banner-content-container .content-container .banner-content > svg {
            fill: $feedback-yellow-dark;
        }
    }
}
