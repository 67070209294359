.content-container {
    width: 1350px;
    margin: 0 auto;
    background-color: inherit;

    &.header-content-container {
        width: 1385px;
    }

    @media (max-width: 1450px) {
        @mixin content-container-media($extra-padding: 0) {
            width: calc(100% - (2 * (#{$extra-padding}px + (100% * (30 / 1410)))));
            padding: 0 calc(#{$extra-padding}px + (100% * (30 / 1410)));
        }

        @include content-container-media(17);

        &.header-content-container {
            @include content-container-media();
        }
    }
}
