.header-mega-menu {
    $header-height: 60px;
    $header-height-with-project: 108px;
    $max-radius: calc(max(300vh, 300vw));

    position: absolute;
    top: $header-height;
    left: 0;
    right: 0;
    height: calc(100vh - #{$header-height});

    &.with-project-header {
        top: $header-height-with-project;
        height: calc(100vh - #{$header-height-with-project});
    }

    .header-mega-menu-background {
        position: absolute;
        top: 0;
        right: 0;
        background-color: $light-sand;
        border-bottom-left-radius: 100%;
    }

    .header-mega-menu-navigation {
        position: relative;
        max-width: 400px;
        margin: 40px auto 0;

        .header-mega-menu-navigation-item {
            margin: 0 20px;
            padding: 10px 15px;
            font-weight: 700;
            outline: none;
            user-select: none;
            cursor: pointer;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            transition: background-color 0.2s ease-in-out;

            &:hover {
                background-color: $bright-sand;
            }

            &:focus-visible {
                outline: 1px solid $focus-blue;
            }
        }
    }

    &.opening {
        .header-mega-menu-background {
            animation: header-mega-menu-background-open 0.5s ease-in-out forwards;

            @keyframes header-mega-menu-background-open {
                0% {
                    height: 0px;
                    width: 0px;
                }

                100% {
                    height: $max-radius;
                    width: $max-radius;
                }
            }
        }

        .header-mega-menu-navigation {
            animation: header-mega-menu-navigation-open 0.5s ease-in-out forwards;

            @keyframes header-mega-menu-navigation-open {
                0% {
                    opacity: 0;
                }

                25% {
                    opacity: 0;
                }

                100% {
                    opacity: 1;
                }
            }
        }
    }

    &.closing {
        .header-mega-menu-background {
            animation: header-mega-menu-background-close 0.4s ease-in-out forwards;

            @keyframes header-mega-menu-background-close {
                0% {
                    height: $max-radius;
                    width: $max-radius;
                }

                100% {
                    height: 0px;
                    width: 0px;
                }
            }
        }

        .header-mega-menu-navigation {
            animation: header-mega-menu-navigation-close 0.4s ease-in-out forwards;

            @keyframes header-mega-menu-navigation-close {
                0% {
                    opacity: 1;
                }

                100% {
                    opacity: 0;
                }
            }
        }
    }

    &.closed {
        display: none;
    }

    &.open .header-mega-menu-background {
        height: $max-radius;
        width: $max-radius;
    }
}
