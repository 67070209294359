.icon-button {
    color: white;
    cursor: pointer;
    display: inline-flex;
    outline: none;
    position: relative;
    user-select: none;
    z-index: 1;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &::before {
        border-radius: 4px;
        bottom: -6px;
        content: "";
        left: -6px;
        pointer-events: none;
        position: absolute;
        right: -6px;
        top: -6px;
        transition: background 0.3s ease-in-out;
        z-index: -1;
    }

    svg {
        fill: $deep-blue;
        transition: fill 0.3s ease-in-out;
    }

    &.icon-button-16 svg {
        width: 16px;
    }

    &.icon-button-20 svg {
        width: 20px;
    }

    &.icon-button-24 svg {
        width: 24px;
    }

    &.icon-button-40 {
        &::before {
            bottom: -2px;
            left: -2px;
            right: -2px;
            top: -2px;
        }

        svg {
            width: 40px;
        }
    }

    &.secondary svg {
        fill: $deep-blue-50;
    }

    &.transparent::before {
        opacity: 0.4;
    }

    &.disabled {
        cursor: not-allowed;

        svg {
            fill: $deep-blue-30;
        }
    }

    &:not(.disabled):hover, &:not(.disabled):active, &.open {
        &.icon-button-layer-1::before {
            background: $light-sand;
        }

        &.icon-button-layer-2::before {
            background: $bright-sand;
        }

        &.icon-button-layer-3::before {
            background: $soft-sand;
        }
    }

    &.open svg {
        fill: $teal;
    }

    &:not(.disabled):focus-visible::before {
        outline: 1px solid $focus-blue;
        outline-offset: -1px;
    }

    &.dark {
        svg {
            fill: white;
        }

        &.secondary svg {
            fill: $deep-blue-60;
        }

        &.disabled svg {
            fill: $deep-blue-55;
        }

        &:not(.disabled):hover, &:not(.disabled):active, &.open {
            &.icon-button-layer-1::before {
                background: $deep-blue-90;
            }

            &.icon-button-layer-2::before {
                background: $deep-blue-80;
            }

            &.icon-button-layer-3::before {
                background: $deep-blue-70;
            }
        }

        &.open svg {
            fill: $bold-green;
        }
    }
}
