.rc-slider {
    margin: 0 7px;

    .rc-slider-rail {
        background-color: $deep-blue-8;
    }

    .rc-slider-track {
        background-color: $bold-green;
    }

    .rc-slider-handle {
        border: none;
        background-color: $interactive-cyan;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);

        &:hover, &:active, &.rc-slider-handle-dragging {
            box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
        }

        &:hover, &:active {
            background-color: $teal-90;
        }

        &:focus-visible {
            background-color: $teal-90;
        }
    }

    &.rc-slider-disabled {
        background: none;

        .rc-slider-handle {
            background-color: $light-grey;
        }
    }

    &.dark {
        .rc-slider-rail {
            background-color: $bright-sand;
        }

        .rc-slider-track {
            background-color: $bold-green;
        }

        .rc-slider-handle {
            background-color: white;

            &:hover, &:active {
                background-color: $deep-blue-20;
            }

            &:hover, &:active, &:focus-visible {
                background-color: $deep-blue-20;
            }
        }

        &.rc-slider-disabled {
            background: none;

            .rc-slider-rail {
                background-color: $deep-blue-55;
            }

            .rc-slider-track {
                background-color: $teal-95;
            }

            .rc-slider-handle {
                background-color: $light-grey;
            }
        }
    }
}
