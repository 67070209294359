.date-input {
    .date-input-field {
        position: relative;
        min-width: 205px;
        box-sizing: border-box;
        padding: 16px 14px 6px;
        color: $deep-blue-70;
        background-color: $interactive-cyan-8;
        border-bottom: 1px solid $deep-blue-70;
        outline: none;
        user-select: none;
        cursor: pointer;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        transition: color 0.3s ease-in-out, background 0.3s ease-in-out, border 0.3s ease-in-out;

        .date-input-label {
            position: absolute;
            top: 2px;
            bottom: 2px;
            display: flex;
            align-items: center;
            font-size: 16px;
            user-select: none;
            pointer-events: none;
            transition: color 0.3s ease-in-out, font-size 0.15s ease-in-out, bottom 0.15s ease-in-out;

            &.filled {
                bottom: 24px;
                font-size: 10px;
            }

            &.required::before {
                position: absolute;
                content: "*";
                right: calc(100% + 2px);
            }
        }

        .date-input-value-container .date-input-value {
            display: inline-block;
            border-radius: 1px;
            outline: none;
            user-select: none;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        }

        &:focus-within .date-input-value-container .date-input-value.active {
            color: white;
            background-color: $green;
        }

        &:hover, &:focus-within {
            color: $deep-blue;
            background-color: $bold-green-18;
            border-color: $green;

            .date-input-label {
                color: $deep-blue;
            }
        }
    }

    .date-input-message {
        margin: 3px 14px 0;
        font-size: 10px;
        color: $deep-blue;
        user-select: none;
    }

    &.error:not(.disabled) {
        .date-input-field:not(:focus-within) {
            color: $deep-blue;
            background-color: $light-red;
            border-color: $feedback-red-dark;

            .date-input-label {
                color: $feedback-red-dark !important;
            }

            .date-input-value-container .date-input-value:not(.active):hover {
                color: white;
                background-color: $feedback-red-dark;
            }
        }

        .date-input-message {
            color: $feedback-red-dark;
        }
    }

    &:not(.disabled) .date-input-field .date-input-value-container .date-input-value:not(.active):hover {
        background-color: $light-green;
    }

    &.disabled .date-input-field {
        color: $deep-blue-50;
        background-color: $interactive-cyan-8;
        border-color: transparent;
        cursor: not-allowed;

        .date-input-label {
            color: $deep-blue-50;
        }
    }

    &.dark {
        .date-input-field {
            color: $deep-blue-8;
            background-color: $interactive-coral-12-db;
            border-color: $deep-blue-50;

            .date-input-label {
                color: $deep-blue-50;
            }

            &:focus-within .date-input-value-container .date-input-value.active {
                color: $deep-blue;
                background-color: $bold-green;
            }

            &:hover, &:focus-within {
                color: $deep-blue-8;
                background-color: $bold-green-12-db;
                border-color: $bold-green;

                .date-input-label {
                    color: $bold-green;
                }
            }
        }

        .date-input-message {
            color: $deep-blue-8;
        }

        &.error:not(.disabled) {
            .date-input-field:not(:focus-within) {
                color: $deep-blue-8;
                background-color: $dark-red;
                border-color: $medium-red;

                .date-input-label {
                    color: $medium-red !important;
                }

                .date-input-value-container .date-input-value:not(.active):hover {
                    background-color: $medium-red;
                }
            }

            .date-input-message {
                color: $deep-blue-50;
            }
        }

        &:not(.disabled) .date-input-field .date-input-value-container .date-input-value:not(.active):hover {
            color: white;
            background-color: $teal;
        }

        &.disabled .date-input-field, &.disabled .date-input-field:hover {
            color: $deep-blue-60;
            background-color: $interactive-coral-12-db;
            border-color: transparent;

            .date-input-label {
                color: $deep-blue-60;
            }
        }
    }
}
