.tag {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 8px;
    padding: 6px 16px;
    color: $interactive-blue;
    background-color: transparent;
    border: 1px solid $interactive-blue;
    border-radius: 17px;
    outline: none;
    cursor: pointer;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;

    &:hover, &:active {
        color: $teal;
        background-color: $bold-green-20;
        border-color: $teal;

        svg {
            fill: $teal;
        }
    }

    &.disabled {
        color: $deep-blue-50;
        background-color: transparent;
        border-color: $deep-blue-50;
        cursor: not-allowed;

        svg {
            fill: $deep-blue-50;
        }
    }

    &.display {
        color: $deep-blue;
        background-color: transparent;
        border-color: $deep-blue;
        cursor: default;

        svg {
            fill: $deep-blue;
        }
    }

    &:focus-visible::before {
        position: absolute;
        content: "";
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        outline: 1px solid $focus-blue;
        outline-offset: 2px;
    }

    .tag-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    svg {
        fill: $interactive-blue;
        width: 20px;
        transition: fill 0.3s ease-in-out;
    }

    &.dark {
        color: $interactive-coral;
        border-color: $interactive-coral;

        &:hover, &:active {
            color: $bold-green;
            background-color: $bold-green-12-db;
            border-color: $bold-green;

            svg {
                fill: $bold-green;
            }
        }

        &.disabled {
            color: $deep-blue-60;
            background-color: transparent;
            border-color: $deep-blue-60;

            svg {
                fill: $deep-blue-60;
            }
        }

        &.display {
            color: $deep-blue-30;
            background-color: transparent;
            border-color: $deep-blue-30;

            svg {
                fill: $deep-blue-30;
            }
        }

        svg {
            fill: $interactive-coral;
        }
    }
}
